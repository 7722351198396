import React, { useEffect, useState } from "react";
import userStore from "../store/useUserStore";
import { isGuestEmailFormat } from "../helpers/common";
import Avatar from "./Avatar";

const ConsultsHeader: React.FC = () => {

  const { user } = userStore();
  const [isGuest, setIsGuest] = useState<boolean>(false);

  useEffect(() => {
    setIsGuest(isGuestEmailFormat(user?.email));
  }, []);

  return (
    <>
      <Avatar />
      <div className="flex flex-col overflow-hidden flex-1 w-full">
        <div className="flex flex-row items-center gap-2">
          <label className="text-sm font-normal text-white">
            Hello, Welcome
          </label>
          <img
            src="images/party_icon.png"
            width={15}
            height={15}
            alt="alt text"
          />
        </div>
        <label className="font-bold text-base text-white whitespace-nowrap text-ellipsis overflow-hidden w-3/4">
          {isGuest ? "Guest" : `${user?.firstname} ${user?.lastname}`}
        </label>
      </div>
    </>
  );
};

export default ConsultsHeader;
