import React from "react";
import { bgVariant, textVariant, borderVariant } from "../helpers/constants";
import { Remarkable } from 'remarkable';

export type NotificationsOptions = {
  visible: boolean;
  variant: string;
  message?: string;
  custom?: string;
};

type NotificationProps = {
  notificationOptions: NotificationsOptions;
};

const md = new Remarkable();

const Notification: React.FC<NotificationProps> = ({ notificationOptions }) => {

  const { variant, message, custom } = notificationOptions;  
  const toHTMLMarkdown  = (markdown: string) => {
     const renderedHTML = md.render(markdown);
     return {__html: renderedHTML};
  }

  return (
    <>
      <div
        className={`
        border
        rounded-lg p-4 
        ${borderVariant[variant]}
        ${bgVariant[notificationOptions.variant]}
        ${textVariant[notificationOptions.variant]}
        ${notificationOptions.visible ? "block" : "hidden"}
        text-sm`}
      >
        {
           message ? <span>{message}</span> : <div className={`${textVariant[variant]}`} dangerouslySetInnerHTML={toHTMLMarkdown(custom ?? "")} />
        }
      </div>
    </>
  );
};

export default Notification;
