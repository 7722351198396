import { AxiosError } from "axios";
import { ErrorMessage } from "./enums";

type ErrorResponse = {
  message: string;
};

export const isGuestEmailFormat = (email: string | undefined): boolean => {

    if (email && typeof email === 'string') {
      const regex = /^[0-9a-fA-F]{32}@guest\.com$/;
      return regex.test(email);
    }
      return false;
};

export const getMaxDate = () => {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0];
};

export const axiosError = (error: AxiosError) => {

  if(error.response){
    const { message } = error.response.data as ErrorResponse;
    return message;
  }

  return ErrorMessage.NETWORK_ERROR;
}