import { useState, useEffect } from "react";
import client from "../utils/axios-client";


const useFetchAPI = (url: string) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState<unknown>(null);

    const fetchContents = async () => {
        try {
          setLoading(true);
          const response = await client.get(url);
          setData(response.data);
        } catch (error: unknown) {
          setError(error);
        } finally {
          setLoading(false);
        }
    };

    useEffect(() => {
        fetchContents();
    }, [url])

    return { loading, data, error, refetch: fetchContents }
}

export default useFetchAPI;