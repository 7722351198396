import React from "react";
import { useNavigate } from "react-router-dom";
// import Prompt from "../components/prompt";


const GetStarted: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div 
      style={{
        backgroundImage: "url(images/login-vector-bg.png)",
        backgroundSize: "cover",
        backgroundBlendMode: "multiply",
      }}
      className="w-full sm:max-w-[375px] m-auto bg-[#2C2E83] flex flex-col h-full relative">
      <div className="h-full">
        <img className="mt-6 ml-6 w-[148.496px] h-[24px]" src="images/medsnap_webapp.png" alt="image" />
      </div>
      <div className="flex justify-center items-center">
        <img
          className="w-[327px]"
          src="images/onboarding-doctor.png"
          alt="image"
        />
      </div>
      <div className="bg-white rounded-tr-[20px] rounded-tl-[20px] text-center pt-6 px-4 pb-4 h-[274px]">
        <div className="flex flex-row gap-[10px] items-center justify-center">
          <img src="images/green-pill.png" alt="image" width={30} />
          <span className="text-lg font-bold">Welcome to MedSnap</span>
          <img src="images/blue-pill.png" alt="image" width={30} />
        </div>
        <div className="text-sm pt-[17px] text-center pb-4">
          Your friendly health companion, here to guide you every step of the
          way.{" "}
        </div>
        <div className="text-sm text-center">
          Say goodbye to bandaid solutions; we're committed to providing you
          with real answers for a healthier, happier you.
        </div>
        <div className="pt-6 pb-4">
          <button
            className="btn btn-primary rounded-full text-white bg-[#2F3082] hover:bg-[#2F3082]"
            onClick={() => navigate("/signin")}
          >
            Get Started
          </button>
        </div>
      </div>
      {/* <Prompt /> */}
    </div>
  );
};

export default GetStarted;
