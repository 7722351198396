import React, { useRef, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import client from "../utils/axios-client";
import ScreenHeader from "../components/ScreenHeader";
import ProfileCard from "../components/ProfileCard";
import AppButton from "../components/AppButton";
import ToastWrapper from "../components/ToastWrapper";
import ReactFlagsSelect from "react-flags-select";
import useUserStore from "../store/useUserStore";
import useMessageStore from "../store/useMessageStore";
import useNotificationStore from "../store/useNotificationsStore";
import toast, { Toaster } from "react-hot-toast";
import { isGuestEmailFormat, axiosError } from "../helpers/common";
import { formSchema } from "../validations/schema";
import { ToastOptions } from "../helpers/constants";
import { AxiosError } from "axios";
import { ErrorMessage, ProfileMesage } from "../helpers/enums";
import { trackEvent, resetTrackingEvent, trackIdentity } from "../helpers/analytics";
import NotificationBell from "../components/NotificationBell";
import useFetchAPI from "../hooks/useFetchAPI";
import useHandleGuestEmail from "../hooks/useHandleGuestEmail";

const CORPSITE_URL = import.meta.env.VITE_CORPSITE_URL;
const POLICY_URL = import.meta.env.VITE_POLICY_URL;

const Profile: React.FC = () => {

  const { data = [] } = useFetchAPI('/faqs');

  const { user, setUser, setUserMobileNumber, setToken, setUserAction } =
    useUserStore();
  const {
    pingInterval,
    webSocketConnection,
    setWebSocketURL,
    setChannel,
    setMessages,
    setConversations,
    setActiveConversation,
  } = useMessageStore();

  useHandleGuestEmail(webSocketConnection);
  
  const { setPushNotifications } = useNotificationStore();

  const navigate = useNavigate();
  const [isGuest, setIsGuest] = useState<boolean>(false);
  const [formDirty, setFormDirty] = useState<boolean>(true);
  const [selected, setSelected] = useState<string>("PH");

  const schema = yup.object().shape({ ...formSchema });

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: user?.firstname || "",
      lastname: user?.lastname || "",
      birthdate: user?.birthdate || "",
      gender: user?.gender || "",
      email: user?.email || "",
    },
    mode: "onSubmit",
  });

  const getMaxDate = () => {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0];
  };

  const signOut = (navigatePath: string, action: string | null) => {
    
    setUser(null);
    setToken(null);
    setUserAction({ action });
    webSocketConnection?.close();
    setWebSocketURL("");
    setChannel("");
    setMessages([]);
    setConversations([]);
    setActiveConversation(null);
    setPushNotifications(null);
    window.clearInterval(pingInterval);
    localStorage.removeItem("userStore");
    localStorage.removeItem("messageStore");
    
    trackEvent("User logged out");
    identify("signout");
    resetTrackingEvent();

    navigate(navigatePath, {
      replace: true
    });

  };

  const deactivateAccountModalRef = useRef<HTMLDialogElement>(null);

  const openModal = () => {
    if (deactivateAccountModalRef.current) {
      deactivateAccountModalRef.current.showModal();
    }
  };

  const cancelModal = () => {
    if (deactivateAccountModalRef.current) {
      deactivateAccountModalRef.current.close();
    }
  };

  const deactivateAccount = async () => {
    const response = await client.post(`/users/deactivate`, {
      id: user?.id,
    });
    if (response.data?.message === "User deactivated") {
      trackEvent('Account deactivated');
      identify("deactivate");
      resetTrackingEvent();
      await signOut("/signin", null);
    }
  };

  const changePIN = async () => {
    setUserMobileNumber(user?.username ?? "");
    await client.post(`/otp/send`, {
      mobileNumber: user?.username,
    });
    signOut("/verification", "changePIN");
  };

  const saveProfileChanges = async (form: any) => {
    try {
      await client.put("/profile", {
        id: user?.id,
        ...form,
      });

      toast.success(
        (t) => (
          <ToastWrapper
            message={ProfileMesage.PROFILE_UPDATE_SUCCESS}
            toastId={t.id}
          />
        ),
        {
          id: "profile-update-success",
          style: ToastOptions.SUCCESS,
        }
      );

      setUser({ ...user, ...form });
    } catch (error) {
      
      const message = axiosError(error as AxiosError);

      toast.error(
        (t) => <ToastWrapper message={`${message? message : ErrorMessage.NETWORK_ERROR}`} toastId={t.id} />,
        {
          id: "profile-update-error",
          style: ToastOptions.ERROR,
      });

    } finally {
      setFormDirty(true);
    }
  };

  const identify = (actionType: string) => {
    trackIdentity(actionType, {
      userID: `${user?.id}`,
      phone: `${user?.username}`
    });
  }

  useEffect(() => {
    setIsGuest(isGuestEmailFormat(user?.email));
  }, []);

  useEffect(() => {
    watch(() => {
      setFormDirty(false);
    });
  }, [watch]);

  return (
    <>
      <Toaster />
      <ScreenHeader type="primary">
        <div className="flex justify-between items-center">
         <div className="flex flex-row items-center gap-[10px]">
           <img src="/images/profile-header-icon.svg" alt="alt text" />
           <label className="font-bold text-lg text-white">My Profile</label>
         </div>
         <NotificationBell />
        </div>
      </ScreenHeader>
      <div className="h-full scrollbar-thin scrollbar-thumb-gray-900 bg-[#F9FAFB] px-6 py-[39px]">
        <div className="flex flex-col gap-[30px]">
          {!isGuest ? (
            <>
              <ProfileCard title="Basic Information">
                <Controller
                  control={control}
                  name="firstname"
                  render={({ field: { onChange, value } }) => (
                    <label className="form-control w-full">
                      <div className="label">
                        <span className="label-text text-sm font-medium">
                          First Name
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Your first name"
                        defaultValue={value}
                        onChange={onChange}
                        className={`input h-[48px] input-bordered w-full text-sm ${
                          errors.firstname ? "input-error" : "input-ghost"
                        }`}
                      />
                      {errors.firstname && (
                        <label className="text-[#D92D20] text-xs pt-2">
                          {errors.firstname.message}
                        </label>
                      )}
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  name="lastname"
                  render={({ field: { onChange, value } }) => (
                    <label className="form-control w-full">
                      <div className="label">
                        <span className="label-text text-sm font-medium">
                          Last Name
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Your last name"
                        defaultValue={value}
                        onChange={onChange}
                        className={`input h-[48px] input-bordered w-full text-sm ${
                          errors.lastname ? "input-error" : "input-ghost"
                        }`}
                      />
                      {errors.lastname && (
                        <label className="text-[#D92D20] text-xs pt-2">
                          {errors.lastname.message}
                        </label>
                      )}
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  name="birthdate"
                  render={({ field: { onChange, value } }) => (
                    <label className="form-control w-full">
                      <div className="label">
                        <span className="label-text text-sm font-medium">
                          Date of Birth
                        </span>
                      </div>
                      <input
                        type="date"
                        placeholder="MM/DD/YYYY"
                        defaultValue={value}
                        onChange={onChange}
                        max={getMaxDate()}
                        className={`input h-[48px] input-bordered w-full text-sm ${
                          errors.birthdate ? "input-error" : "input-ghost"
                        }`}
                      />
                      {errors.birthdate && (
                        <label className="text-[#D92D20] text-xs pt-2">
                          {errors.birthdate.message}
                        </label>
                      )}
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  name="gender"
                  render={({ field: { onChange, value } }) => (
                    <label className="form-control w-full">
                      <div className="label">
                        <span className="label-text text-sm font-medium">
                          Gender
                        </span>
                      </div>
                      <select
                        className={`select select-bordered w-full ${
                          errors.gender ? "select-error" : "select-ghost"
                        }`}
                        onChange={onChange}
                      value={value}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {errors.gender && (
                        <label className="text-[#D92D20] text-xs pt-2">
                          {errors.gender.message}
                        </label>
                      )}
                    </label>
                  )}
                />
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <label className="form-control w-full">
                      <div className="label">
                        <span className="label-text text-sm font-medium">
                          Email
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="email@domain.com"
                        defaultValue={value}
                        onChange={onChange}
                        className={`input h-[48px] input-bordered w-full text-sm ${
                          errors.email ? "input-error" : "input-ghost"
                        }`}
                      />
                      {errors.email && (
                        <label className="text-[#D92D20] text-xs pt-2">
                          {errors.email.message}
                        </label>
                      )}
                    </label>
                  )}
                />
                <label className="form-control w-full pb-4">
                  <div className="label">
                    <span className="label-text text-sm font-medium">
                      Mobile Number
                    </span>
                  </div>
                  <div className="flex flex-row border-[#D0D5DD] border-">
                    <ReactFlagsSelect
                      disabled
                      selected={selected}
                      onSelect={(code) => setSelected(code)}
                      countries={["PH"]}
                      showSelectedLabel={false}
                      className="h-[48px]"
                      selectButtonClassName="
                      !bg-[#F2F4F7]
                      !rounded-tl-lg 
                      !rounded-bl-lg 
                      !h-[48px] 
                      !rounded-tr-[0px] 
                      !rounded-br-[0px] 
                      !border-t-[#D0D5DD] 
                      !border-l-[#D0D5DD]
                      !border-b-[#D0D5DD]"
                    />
                    <input
                      readOnly
                      defaultValue={user?.username}
                      type="text"
                      placeholder="09XX XXX XXX"
                      disabled
                      className="
                      input h-[48px]
                      w-full text-sm 
                      rounded-tl-[0px]
                      rounded-bl-[0px] 
                     disabled:bg-[#f2f4f7]
                     disabled:border-t-[#D0D5DD]
                     disabled:border-b-[#D0D5DD]
                     disabled:border-r-[#D0D5DD]
                     disabled:text-[#606471]
                     "
                    />
                  </div>
                </label>
                <AppButton
                  variant="primary"
                  type="button"
                  onClick={handleSubmit(saveProfileChanges)}
                  disabled={formDirty}
                >
                  Save Changes
                </AppButton>
              </ProfileCard>
              <ProfileCard title="Change PIN">
                <div className="flex flex-col pt-4 gap-2">
                  <a
                    href="#"
                    className="font-bold text-sm cursor-pointer"
                    onClick={changePIN}
                  >
                    Change PIN
                  </a>
                  <label className="text-xs text-[#667085]">
                    Update your PIN for added security.
                  </label>
                </div>
              </ProfileCard>
              <ProfileCard title="Deactivate Account">
                <div className="flex flex-col pt-4 gap-2">
                  <a
                    href="#"
                    className="font-bold text-sm text-[#D92D20] cursor-pointer"
                    onClick={openModal}
                  >
                    Deactivate
                  </a>
                  <label className="text-xs text-[#667085]">
                    Deactivating your account will disable your Profile and Chat
                    capabilities.
                  </label>
                </div>
              </ProfileCard>
            </>
          ) : (
            <>
              <ProfileCard title="">
                <div className="flex flex-col pt-4 gap-2">
                  <p className="whitespace-nowrap flex gap-2 font-bold text-sm items-center cursor-pointer">
                    <img src="images/user-circle.svg" alt="alt text" />
                    Guest ID# {user?.username.slice(0, 8)}
                  </p>
                </div>
              </ProfileCard>
              <ProfileCard title="">
                <div className="flex flex-col pt-4 gap-2">
                  <label className="text-xs text-[#667085]">
                    Log in or sign up now to tailor your health journey and edit
                    your information.
                  </label>
                  <AppButton
                    variant="secondary"
                    type="button"
                    onClick={() => signOut("/signup", "guestSignUp")}
                  >
                    Sign Up
                  </AppButton>
                  <AppButton
                    variant="secondary"
                    type="button"
                    onClick={() => signOut("/signin", null)}
                  >
                    Login
                  </AppButton>
                </div>
              </ProfileCard>
            </>
          )}
          {
            data && data.length > 0 && <ProfileCard title="General">
            <div className="flex flex-col pt-4 gap-2">
              <Link to="/faq">
                <p className="whitespace-nowrap flex gap-2 font-bold text-sm items-center cursor-pointer">
                  <img src="images/question-circle.svg" alt="alt text" />
                  FAQ
                </p>
              </Link>
            </div>
          </ProfileCard>
          }
          {!isGuest && (
            <AppButton
              variant="secondary"
              type="button"
              onClick={() => signOut("/signin", null)}
            >
              Logout
            </AppButton>
          )}
          <div className="flex flex-wrap justify-center gap-2 items-center self-stretch text-xs font-semibold text-[#667085] px-[10px]">
            <a href={`${CORPSITE_URL}/terms`} target="_blank">
              Terms and Conditions
            </a>
            <a href={`${POLICY_URL}/privacy`} target="_blank">
              Privacy Policy
            </a>
            <a href={`${CORPSITE_URL}/privacy-notice`} target="_blank">
              Privacy Notice
            </a>
          </div>
        </div>
      </div>

      <dialog
        id="deactivateAccountModal"
        className="modal max-w-[327px] m-auto"
        ref={deactivateAccountModalRef}
      >
        <div className="modal-box flex flex-col items-center">
          <img src="images/modal-danger-icon.svg" />
          <h3 className="font-bold text-base">Deactivate Account?</h3>
          <p className="py-4 text-sm text-center">
            You will no longer have access to your MedSnap account. Do you want
            to proceed?
          </p>
          <p className="text-sm pb-8">This action cannot be undone.</p>
          <div className="flex flex-col w-full gap-3">
            <AppButton
              variant="danger"
              type="button"
              onClick={deactivateAccount}
              className="focus-visible:outline-0"
            >
              Yes
            </AppButton>
            <AppButton
              type="button"
              onClick={cancelModal}
              className="focus-visible:outline-0"
            >
              Cancel
            </AppButton>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default Profile;
