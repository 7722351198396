import React, { ReactNode } from "react";

type AppButtonProps = {
  children: ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary' | 'danger';
  type: 'button' | 'submit';
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}


const AppButton: React.FC<AppButtonProps> = ({ children, className, variant, type, onClick, disabled }) => {

  let variantClass = "";
  switch(variant){
    case 'primary':
      variantClass = "rounded-full text-white bg-[#2F3082] hover:bg-[#2F3082]";
      break;
    case 'secondary':
      variantClass = "rounded-full text-[#2F3082] bg-[#EAEAF3] hover:bg-[#EAEAF3]";
      break;
    case 'danger':
      variantClass = "rounded-full text-white bg-[#D92D20] hover:bg-[#D92D20]";
      break;
    default:
      break;
  }

  return(
    <button onClick={onClick} disabled={disabled} type={type} className={`btn w-full rounded-full ${className}  ${disabled ? 'bg-[#9797c0]' : variantClass }`}>{ children }</button>
  )
}

AppButton.defaultProps = {
  disabled: false
}

export default AppButton;