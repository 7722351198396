import useMessageStore from "../store/useMessageStore";
import useUserStore from "../store/useUserStore";
import client from "../utils/axios-client";

import {
  Message,
  Conversation,
  MessageAttachment
} from "../helpers/message";

const useMessagesAPI = () => {
  const {
    channel,
    activeConversation,
    setConversations,
    setActiveConversation,
    setMessages,
    setAttachments,
    setSending,
    setTypeable,
    setResponds
  } = useMessageStore();
  const { token } = useUserStore();

  type Headers = Record<string, string>;

  const addBearerToken = (headers: Headers): Headers => ({
    ...headers,
    Authorization: `Bearer ${token}`,
  });

  const handleError = (error: any): void => {
    console.error("API request error:", error);
    // You can add more sophisticated error handling here
  };

  const fetchConversations = async (): Promise<void> => {
    try {
      const response = await client.get<{ conversations: Conversation[] }>(
        "/messages/conversations",
        {
          headers: addBearerToken({}),
        }
      );

      const { conversations } = response.data;
      setConversations(conversations);
      if (activeConversation === null) {
        setActiveConversation(
          conversations.length ? conversations[0].conversation : null
        );
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchMessages = async (conversationId: string): Promise<void> => {
    try {
      const response = await client.get<{ messages: Message[] }>(
        `/messages/${conversationId}`,
        {
          headers: addBearerToken({}),
        }
      );

      const { messages } = response.data;
      setMessages(messages);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchMessageAttachments = async (): Promise<void> => {
    try {
      const response = await client.get<{ messages: MessageAttachment[] }>(
        "/messages/attachments",
        {
          headers: addBearerToken({}),
        }
      );

      const { messages } = response.data;
      setAttachments(messages);
    } catch (error) {
      handleError(error);
    }
  };

  const searchMessages = async (
    channel: string | null,
    term: string
  ): Promise<any> => {
    try {
      const response = await client.get(`/messages/search/${channel}/${term}`, {
        headers: addBearerToken({}),
      });
      return response.data;
    } catch (error) {
      handleError(error);
      return null; // or handle the error in a way that makes sense for your application
    }
  };

  const deleteConversation = async (conversationId: string): Promise<void> => {
    try {
      await client.delete(`/messages/${conversationId}`, {
        headers: addBearerToken({}),
      });
    } catch (error) {
      handleError(error);
    }
  };

  const deleteAttachment = async (messageId: string): Promise<void> => {
    try {
      await client.delete(`/messages/attachments/${messageId}`, {
        headers: addBearerToken({}),
      });
    } catch (error) {
      handleError(error);
    }
  };

  const downloadAttachmentFile = async (fileName: string) => {

    return await client.get(`/download/${fileName}`, {
      headers: addBearerToken({}),
      responseType: 'blob'
    });

  }


  const stopGeneratingResponse = async() => {
    try{

      setSending(false);
      setTypeable(true);
      setResponds(true);

      await client.post(
        `/messages/stop`,
        {
          content: "",
          channel,
          conversation: activeConversation,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
    }catch(error){
      handleError(error);
    }
  }

  return {
    fetchConversations,
    fetchMessages,
    fetchMessageAttachments,
    searchMessages,
    deleteConversation,
    deleteAttachment,
    downloadAttachmentFile,
    stopGeneratingResponse
  };
};

export default useMessagesAPI;
