import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import useToast from "../hooks/useToast";
import useUserStore from "../store/useUserStore";
import client from "../utils/axios-client";
import AppButton from "../components/AppButton";
import Notification from "../components/Notifications";
import { trackEventPayload, trackIdentity } from "../helpers/analytics";

let currentIndex: number = 0;
const UserPin: React.FC = () => {
  const pageTitle = [
    {
      title: "Create a PIN",
      label: "Add a PIN to your account to make it more secure.",
    },
    {
      title: "Re-type your PIN",
      label: "Re-type your PIN to verify",
    },
    {
      title: "Reset PIN",
      label: "Enter your new PIN.",
    },
    {
      title: "Re-type your new PIN",
      label: "Re-type your new PIN to verify",
    },
  ];
  
  const { notify, showToast } = useToast();
  const navigate = useNavigate();
  const { newUser, userAction, userMobileNumber, OTPToken, setUserAction } =
    useUserStore();
  const [pinLabel, setPinLabel] = useState<string>("create");
  const [initialPin, setInitialPin] = useState<string>("");
  const [matchPin, setMatchPin] = useState<string>("");
  const [pin, setPin] = useState<string[]>(new Array(4).fill(""));
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [settingPIN, setSettingPIN] = useState<boolean>(false);
  const [guest, setGuest, removeGuest] = useCookies(["medsnapGuest"]);
  const [nextButtonStatus, setNextButtonStatus] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newPins: string[] = [...pin];

    if (/^\d+$/.test(value)) {
      newPins[currentIndex] = value.substring(value.length - 1);

      if (!value) {
        setActiveIndex(currentIndex - 1);
      } else {
        setActiveIndex(currentIndex + 1);
      }
      setPin(newPins);
    }
  };

  const handleKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    currentIndex = index;
    if (key === "Backspace"){

      setActiveIndex(currentIndex - 1);
      setPin((previousValue) => {
        const updatedPin = [...previousValue];
        updatedPin[index] = "";
        return updatedPin;
      });
    }
    setNextButtonStatus(false);
    setErrorMessage("");
  };

  const submit = async () => {

    try {
         setMatchPin(pin.join(""));
         setSettingPIN(true);

        if (initialPin === matchPin) {
            if (
              userAction?.action === "signUp" ||
              userAction?.action === "guestSignUp"
            ) {
              const signUpBody: any = {
                ...newUser,
                PIN: pin.join(""),
                intent: userAction?.action,
              };

              signUpBody["credentials"] = guest["medsnapGuest"] ?? "";
              const response = await client.post(`/signup`, signUpBody);

              setSettingPIN(false);

              if (response.data?.message === "Account successfully created") {
                setUserAction({ action: null });
                navigate("/confirmation");
              } else if (
                response.data?.message === "Guest account successfully updated"
              ) {
                removeGuest("medsnapGuest");
                setUserAction({ action: null });
                navigate("/confirmation");
              } else {
                throw new Error();
              }

              trackEventPayload('Account created', {
                promotion_consent: signUpBody.promotionConsent
              });

              trackIdentity("signup", signUpBody);

            } else if (
              userAction?.action === "changePIN" ||
              userAction?.action === "resetPIN"
            ) {
                const response = await client.post(`/users/change-pin`, {
                  PIN: initialPin,
                  confirmPIN: matchPin,
                  mobileNumber: userMobileNumber,
                  token: OTPToken,
                });

                setSettingPIN(false);
                if (response.data?.message === "User PIN changed successfully") {
                  navigate("/updated-pin");
                } else {
                  throw new Error();
                }
            }
          } else {
            setSettingPIN(false);
            setErrorMessage("PIN does not match");
          }
    } catch(error){
        showToast({
          visible: true,
          variant: 'danger',
          message: 'Sorry, we encountered an issue. Please try again.'
        })
    }
  };

  useEffect(() => {
    inputRef.current?.focus();

    if (pin.join("").length === 4 && pinLabel === "create") {

      setInitialPin(pin.join(""));
      setPin(new Array(4).fill(""));
      setActiveIndex(0);
      setPinLabel("retype");

    }

    if (pin.join("").length === 4 && pinLabel === "retype") {
      setMatchPin(pin.join(""));
      setNextButtonStatus(true);
    }
  }, [activeIndex, pin, pinLabel]);

  return (
    <>
      <div className="w-full sm:max-w-[375px] m-auto p-6 h-full flex flex-col justify-between">
        <div>
          <label className="font-bold">
            {pinLabel === "create"
              ? userAction?.action === "signUp"
                ? pageTitle[0].title
                : pageTitle[2].title
              : userAction?.action === "signUp"
              ? pageTitle[1].title
              : pageTitle[3].title}
          </label>
          <div className="mt-6">
              <Notification notificationOptions={notify} />
          </div> 
        </div>
        {settingPIN ? (
          <div className="flex flex-col justify-center items-center pt-6 text-center">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        ) : (
          <div>
            <div className="text-center pb-4">
              <span className="pb-4 font-normal text-sm">
                {pinLabel === "create"
                  ? userAction?.action === "signUp"
                    ? pageTitle[0].label
                    : pageTitle[2].label
                  : userAction?.action === "signUp"
                  ? pageTitle[1].label
                  : pageTitle[3].label}
              </span>
            </div>
            <div className="flex flex-row gap-2 justify-center items-center">
              {/* for opt input */}
              {pin.map((_, index) => {
                return (
                  <div key={index}>
                    <input
                      ref={index === activeIndex ? inputRef : null}
                      type="password"
                      inputMode="numeric"
                      className={`input input-bordered w-[47px] h-[67px] text-[20px] font-bold text-center ${errorMessage ? 'input-error' : 'input-ghost'}`}
                      onChange={handleOnChange}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      value={pin[index]}
                    />
                  </div>
                );
              })}
            </div>
            {errorMessage && 
              <div className="text-center">
                <span className="text-[#D92D20] text-xs font-normal">{errorMessage}</span>
              </div>
            }
          </div>
        )}
        <AppButton
          type="button"
          variant="primary"
          onClick={submit}
          disabled={!nextButtonStatus || settingPIN}
        >
          Submit
        </AppButton>
      </div>
    </>
  );
};

export default UserPin;
