import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import useMessageStore from "../store/useMessageStore";
import ScreenHeader from "../components/ScreenHeader";
import ChatAttachmentCard from "../components/ChatAttachmentCard";
import userStore from "../store/useUserStore";
import { trackEvent } from "../helpers/analytics";
import NotificationBell from "../components/NotificationBell";
import useHandleGuestEmail from "../hooks/useHandleGuestEmail";

const Results: React.FC = () => {
  const { token } = userStore();
  const { attachments, webSocketConnection, setAttachments, fetchFileAttachments } =
    useMessageStore();

  useHandleGuestEmail(webSocketConnection);
  const [selectedSort, setSelectedSort] = useState<string>("Date");
  const [fileAttachments, setChatFileAttachments] = useState(attachments);

  const sortAttachment = (sortType: string) => {
    const chatAttachments = [...attachments];

    if (sortType === "DATE") {
      setSelectedSort("Date");
      const sortedByDate = chatAttachments.sort(
        (fileA, fileB) =>
          dayjs(fileA.createdAt).valueOf() - dayjs(fileB.createdAt).valueOf()
      );
      setChatFileAttachments(sortedByDate);
    }

    if (sortType === "FILE") {
      setSelectedSort("File");
      const sortedByFilename = chatAttachments.sort((fileA, fileB) => {
        const nameA = fileA.attachment.name.toLowerCase();
        const nameB = fileB.attachment.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setChatFileAttachments(sortedByFilename);
    }
  };

  const chatAttachments = async(attachmentId: string) => {
      const filtered = attachments.filter((item) => item._id !== attachmentId);
      setAttachments(filtered);
  }

  useEffect(() => {
    setChatFileAttachments(attachments);
  }, [attachments]);

  useEffect(() => {
    if(token){
      fetchFileAttachments(token);
    }

    trackEvent("Files viewed");
  }, []);

  return (
    <>
      <ScreenHeader type="primary">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center gap-[10px]">
            <img src="images/result-header-icon.svg" alt="alt text" />
            <label className="font-bold text-lg text-white">Files</label>
          </div>
          <div className="flex flex-row items-center gap-4">
            <div className="dropdown dropdown-bottom dropdown-end mt-2">
              <button
                className="btn-ghost"
                tabIndex={0}
                role="button"
                title="files"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5M14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5M14 5H20M10 5L4 5M16 12C16 13.1046 16.8954 14 18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12ZM16 12H4M8 19C8 17.8954 7.10457 17 6 17C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21C7.10457 21 8 20.1046 8 19ZM8 19H20"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <ul
                tabIndex={0}
                className="rounded-[4px] dropdown-content z-[1] menu p-0 shadow bg-base-100 w-52 text-sm font-medium"
              >
                <li className="py-[6px] px-[16px] font-bold">Sort by</li>
                <li
                  onClick={() => sortAttachment("FILE")}
                  className={`cursor-pointer ${
                    selectedSort === "File" ? "bg-[#E9EDFB]" : null
                  }`}
                >
                  <span className="py-[6px] px-[16px] flex justify-between active:!bg-transparent">
                    File Name (A to Z)
                    {selectedSort === "File" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="13"
                        viewBox="0 0 18 13"
                        fill="none"
                      >
                        <path
                          d="M17.0001 1L6.0001 12L1 7"
                          stroke="#323643"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : null}
                  </span>
                </li>
                <li
                  onClick={() => sortAttachment("DATE")}
                  className={`cursor-pointer ${
                    selectedSort === "Date" ? "bg-[#E9EDFB]" : null
                  }`}
                >
                  <span className="py-[6px] px-[16px] flex justify-between active:!bg-transparent">
                    Date Added
                    {selectedSort === "Date" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="13"
                        viewBox="0 0 18 13"
                        fill="none"
                      >
                        <path
                          d="M17.0001 1L6.0001 12L1 7"
                          stroke="#323643"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : null}
                  </span>
                </li>
              </ul>
            </div>
            <NotificationBell />
          </div>
        </div>
      </ScreenHeader>
        {
          attachments.length ? (
          <div
            className="h-full scrollbar-thin scrollbar-thumb-gray-900"
            id="boundary"
          >
            <div className="w-full flex flex-wrap gap-[20px] p-4">
             <ChatAttachmentCard chatAttachments={fileAttachments} refetchChatAttachment={chatAttachments}/>
            </div>
          </div>
          ): (
            <>
              <div className="h-full scrollbar-thin scrollbar-thumb-gray-900 flex items-center  p-4 ">
                <img src="images/no-files-uploaded.png" alt="coming soon" />
              </div>
            </>
          )
        }
    </>
  );
};

export default Results;
