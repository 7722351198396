import React, { useRef, useEffect, useMemo } from "react";
import { getCalendarTime } from "../helpers/dateFormatter";
import { AppNotification } from "../helpers/notifications";

type NotificationCardProps = {
    props : AppNotification;
    isNew?: boolean;
    isViewed?: (notif: AppNotification) => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({ props, isNew, isViewed }) => {

    const cardRef = useRef<HTMLDivElement>(null);

    const observer = useMemo(() =>
        new IntersectionObserver(([entry]) =>{

            if(entry.isIntersecting && isViewed){
                isViewed(props);
            }
        },
        { threshold: 0.7 }),    
    []);
    
      useEffect(() => {
        if(cardRef.current){
            observer.observe(cardRef.current);
        }
    
        return () => {
          observer.disconnect();
        };
      }, [cardRef, observer]);

    
    return (
     <div ref={cardRef} className="rounded-[10px] overflow-hidden bg-white">
        {
            props.image && <img className="w-full" src={props.image} alt="Card Image" />
        }
        <div className="w-full flex justify-between items-center px-6 py-4">
            {
                props.category && <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-[10px] font-bold text-gray-700 mr-2">
                {
                    props.category
                }
                </span>
            }
            <span className="text-[10px] ml-auto">{ getCalendarTime(new Date(props.timestamp || 0)) } </span>
        </div>
        <div className="px-6 pb-4">
            <div className="flex items-center gap-2">
            {
                isNew && <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#9984D4"/>
                </svg>
            }
            <div className="font-bold text-base">
               { props.title }
            </div>
            </div>
            <p className="font-medium text-gray-700 text-sm mt-2">
               { props.body }
            </p>
        </div>
        {
            props.click_action && <div className="px-6 py-4 border-t border-[#D2D6E4]">
            <div className="flex flex-row justify-between items-center">
                <a href={props.click_action} target="_blank" rel="noopener noreferrer" className="font-semibold text-base cursor-pointer">Learn More</a>
                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.33398 9.16659L5.50065 4.99992L1.33399 0.833252" stroke="#8D919F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                 </svg>
            </div>
        </div>
        }
      </div>
    )
}

NotificationCard.defaultProps = {
    isNew: false
}

export default NotificationCard;