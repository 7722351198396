import React, { useEffect, useState } from "react";
import { onMessage } from "firebase/messaging";
import { firebaseMessage } from "../utils/firebaseInit";
import { useNavigate } from "react-router-dom";
import useNotificationStore from "../store/useNotificationsStore";
import useNotificationsAPI from "../hooks/useNotificationsAPI";

const NotificationBell: React.FC = () => {

 const { activeNotification, setActiveNotification } = useNotificationStore();   
 const { postCampaign, fetchNotifications } = useNotificationsAPI();
 const navigate = useNavigate();
 const [newNotification, setNewNotification] = useState(false);

 const requestPermission = async () => {
    //need to add logic here to check browser permission
    navigate("/notifications");
 }

 const fetchCampaigns = async() => {

    try{
      
        const response = await fetchNotifications();
        const unreadCampaigns = response.filter(campaign => !campaign.is_read);
        setActiveNotification(unreadCampaigns.length > 0);

    }catch(error){
        console.log(error);
    }       
 }

 useEffect(() => {
    setNewNotification(activeNotification);
 }, [activeNotification])


 useEffect(() => {
   
  const timeoutId = setTimeout(() => {
    fetchCampaigns();
  }, 2000);

  (async() => {

    if ("serviceWorker" in navigator){

        const registration = await navigator.serviceWorker.ready;

        onMessage(firebaseMessage, async(payload) => {

            console.log(payload);
            if (!(self.Notification && self.Notification.permission === 'granted')) {
              return;
            }

            const permission = await Notification.requestPermission();

            if(permission === "granted"){
                try {
                  registration.showNotification(payload!.notification!.title!, {
                    body: payload!.notification!.body!,
                    image: payload!.notification!.image!,
                    tag: payload.collapseKey,
                    data: payload.data
                  } as NotificationOptions);

                        await postCampaign({
                                userId: 0,
                                title: payload?.notification?.title,
                                body: payload?.notification?.body,
                                image: payload?.notification?.image,
                                campaignId: payload?.collapseKey,
                                click_action:  payload?.data?.click_action,
                                category:  payload?.data?.category
                            });
                            
                        setActiveNotification(true);
                        fetchCampaigns();

                }catch(error){
                    console.log(error);
                }
             } 
          });
    }else{
      console.log("Service Worker Not Supported")
    }
  })();

  return () => {
    clearTimeout(timeoutId);
    fetchCampaigns();
  }

}, [])

    return (
        <div onClick={() => requestPermission()} className="cursor-pointer">
            {
                newNotification ?
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512" version="1.1"><path d="M 240.500 127.030 C 217.995 130.505, 195.940 142.030, 179.966 158.663 C 166.259 172.935, 158.150 187.289, 153.339 205.794 C 151.145 214.236, 150.704 218.756, 149.960 240.500 C 148.642 278.993, 145.616 295.555, 136.349 315 C 128.676 331.099, 129.449 341.544, 139.021 351.116 C 146.184 358.279, 149.667 359, 177.111 359 L 199.858 359 201.009 364.544 C 201.642 367.593, 203.992 373.749, 206.231 378.223 C 218.658
                 403.049, 247.812 414.742, 274.310 405.527 C 292.610 399.163, 306.029 384.484, 310.614 365.812 L 312.257 359.125 336.878 358.812 L 361.500 358.500 366.964 355.500 C 369.985 353.842, 373.886 350.581, 375.687 348.208 C 382.660 339.023, 382.643 329.580, 375.629 315 C 367.641 298.396, 363.933 281.024, 362.228 252.207 L 361.500 239.913 357.444 242.900 C 351.405 247.348, 343.903 250.857, 336.551 252.671 L 330 254.287 330 260.052 C 330 275.337, 335.783 305.123, 341.466 319.109 C 342.860 322.539, 344 325.492, 344 325.672 C 344 325.853, 304.352 326, 255.893 326 C 172.364 326, 167.825 325.909, 168.533 324.250 C 177.633 302.937, 181.503 280.593, 182.863 241.500 C 183.638 219.251, 183.896 216.916, 186.407 209.500 C 193.957 187.199, 211.315 169.854, 233.500 162.443 C 243.144 159.221, 258.171 158.128,
                 268.379 159.906 L 275.123 161.080 279.607 156.092 C 284.952 150.147, 294.350 143.668, 301.112 141.267 C 303.800 140.312, 306 139.246, 306 138.899 C 306 137.603, 291.170 131.685, 282 129.322 C 271.272 126.556, 250.866 125.430, 240.500 127.030 M 234.888 362.750 C 240.191 373.060, 252.089 378.151, 263.142 374.839 C 269.451 372.949, 278 364.440, 278 360.050 C 278 359.348, 270.537 359, 255.480 359 L 232.959 359 234.888 362.750" stroke="none" fill="#f4f3f3" fillRule="evenodd"/><path d="M 311.500 137.684 C 310.400 137.932, 306.445 139.276, 302.710 140.670 C 284.368 147.518, 269.680 163.535, 265.506 181.240 C 263.587 189.376, 263.598 203.036, 265.528 210.448 C 269.181 224.472, 280.051 239.110, 292.133 246.276 C 312.201 258.180, 335.093 257.469, 355.173 244.319 C 372.429 233.018, 381.401 216.313,
                 381.395 195.500 C 381.392 186.951, 380.955 184.260, 378.386 176.956 C 376.733 172.257, 373.685 165.857, 371.612 162.735 C 364.113 151.436, 350.805 142.055, 337.356 138.585 C 331.642 137.111, 316.390 136.579, 311.500 137.684" stroke="none" fill="#ec844c" fillRule="evenodd"/></svg>
                 :
                 <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512" version="1.1"><path d="M 254.250 126.689 C 255.213 126.941, 256.788 126.941, 257.750 126.689 C 258.712 126.438, 257.925 126.232, 256 126.232 C 254.075 126.232, 253.287 126.438, 254.250 126.689 M 211.750 326.750 C 236.088 326.899, 275.913 326.899, 300.250 326.750 C 324.588 326.601, 304.675 326.479, 256 326.479 C 207.325 326.479, 187.413 326.601, 211.750 326.750 M 213 389.313 C 213 389.485, 214.463 390.948, 216.250 392.563 L 219.500 395.500 216.563 392.250 C 213.825 389.221, 213 388.540, 213 389.313 M 295.437 392.250 L 292.500 395.500 295.750 392.563 C 298.779 389.825, 299.460 389, 298.687 389 C 298.515 389, 297.052 390.462, 295.437 392.250" stroke="none" fill="#f8f4f4" fillRule="evenodd"/><path d="M 246 126.563 C 245.175 126.704, 241.125 127.364, 237 128.028 C 232.875 128.693, 229.050 129.508, 228.500 129.840 C 227.950 130.172, 226.964 130.525, 226.310 130.625 C 224.211 130.944, 217.534 133.236, 216.500 133.992
                  C 215.950 134.395, 214.361 134.995, 212.970 135.326 C 210.464 135.922, 195.967 144.261, 192.585 147.052 C 185.611 152.806, 174.930 163.556, 170.776 169 C 163.953 177.942, 156.510 193.589, 153.402 205.523 C 151.101 214.356, 150.708 218.423, 149.967 241 C 148.663 280.751, 145.450 297.299, 135.022 317.957 C 127.335 333.184, 131.288 347.859, 145.144 355.534 L 150.500 358.500 175.195 358.826 L 199.890 359.153 200.989 364.446 C 204.789 382.751, 220.121 399.541, 239 406.073 C 248.691 409.426, 263.834 409.293, 273.764 405.769 C 277.759 404.351, 283.252 401.763, 285.971 400.019 C 297.961 392.325, 308.720 376.440, 311.500 362.326 L 312.125 359.153 336.813 358.826 L 361.500 358.500 366.939 355.500 C 370.148 353.730, 373.605 350.765, 375.368 348.269 C 382.441 338.260, 382.677 329.281, 376.207 316.413 C 371.841 307.729, 366.690 292.035, 365.077 282.500 C 363.588 273.706, 362.116 252.888, 361.611 233.500 C 361.244 219.443, 360.724 214.804, 358.605 206.704 C 355.482 194.767, 347.849 178.542, 341.294 169.907 C 336.462 163.542, 325.565 152.329, 320.490 148.500 C 319.032 147.400, 317.233 145.938, 316.491 145.250 C 315.750 144.563, 314.661 144, 314.071 144 C 313.482 144, 313 143.550, 313 143 C 313 142.450, 312.357 142, 311.571 142 C 310.786 142, 309.516 141.373, 308.750 140.608 C 307.112 138.969, 297 133.718, 297 134.506 C 297 134.803, 295.875 134.309, 294.500 133.408 C 293.125 132.507, 292 132.102, 292 132.507 C 292 132.913, 291.325 132.685, 290.500 132 C 289.675 131.315, 289 131.090, 289 131.500 C 289 131.910, 288.325 131.685, 287.500 131 C 286.675 130.315, 286 130.051, 286 130.413 C 286 130.774, 285.212 130.650,
                   284.250 130.135 C 283.288 129.621, 280.760 128.924, 278.633 128.587 C 276.507 128.250, 274.366 127.726, 273.875 127.423 C 272.956 126.855, 248.671 126.106, 246 126.563 M 238.500 160.888 C 214.223 167.495, 194.801 184.790, 187.292 206.490 C 183.338 217.917, 183.318 218.077, 182.490 244.931 C 182.160 255.618, 181.711 265.968, 181.492 267.931 C 180.526 276.574, 179.784 282.611, 179.516 284 C 179.357 284.825, 178.956 287.075, 178.626 289 C 177.248 297.022, 176.876 298.754, 175.401 304 C 173.634 310.288, 170.303 319.675, 168.931 322.236 C 166.232 327.271, 161.268 327, 256.117 327 C 339.581 327, 344.204 326.908, 343.682 325.250 C 343.379 324.288, 341.712 319.513, 339.979 314.641 C 333.296 295.857, 330.574 277.128, 329.058 239.500 C 328.317 221.115, 327.813 216.437, 325.990 211.033 C 317.857 186.915, 301.594 170.271, 278.500 162.431 C 267.598 158.730, 249.056 158.015, 238.500 160.888 M 234.974 362.949 C 237.108 367.132, 241.449 372, 243.045 372 C 243.570 372, 244 372.450, 244 373 C 244 373.550, 244.338 373.907, 244.750 373.793 C 245.162 373.680, 247.854 374.388, 250.730 375.367 C 253.697 376.377, 256.888 376.853, 258.104 376.467 C 259.283 376.093, 260.979 375.644, 261.874 375.470 C 262.768 375.296, 263.500 375.084, 263.500 375 C 263.500 374.916, 264.260 374.700, 265.188 374.521 C 268.934 373.798, 278 363.558, 278 360.050 C 278 359.348, 270.537 359, 255.480 359 L 232.959 359 234.974 362.949" stroke="none" fill="#f4f4f4" fillRule="evenodd"/></svg>
            }
        </div>
    )
}

export default NotificationBell;