import { count } from "console";
import { create, StateCreator } from "zustand";
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

interface Prompt {
    tries: number,
    max_tries: number
    
}

type Store = {
    prompt: Prompt
    setTries: (count:number) => void
};

type Persist = (
    config: StateCreator<Store>,
    options: PersistOptions<Store>
) => StateCreator<Store>

const userPrompt = create<Store, []>(
    (persist as Persist)(
        (set): Store => ({
            prompt: {
                tries:0,
                max_tries:3,
            },
            setTries: (count) => set((state) => ({ prompt: { ...state.prompt, tries: count } })),
        }),
        {
            name: 'promptStore',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

export default userPrompt;
