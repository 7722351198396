import { useEffect, useState, useRef } from "react";
import prevButton from "../../../assets/icons/chevron_prev.svg";
import nextButton from "../../../assets/icons/chevron_right.svg";
type slide = "#item1" | "#item2" | "#item3" | "#item4";
const IOSPromptSlide: React.FC = () => {
    const [activeSlide, setActiveSlide] = useState<slide>('#item1');
    const [activeSlideOnNumbering, setactiveSlideOnNumbering] = useState<number>(0);
    const carousel = useRef<HTMLDivElement>(null);
    const onAnchorClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
    }
    useEffect(() => {
        if (carousel.current) {
            const itemWidth = carousel.current.clientWidth;
            carousel.current.scrollTo({
                left: itemWidth * activeSlideOnNumbering,
                behavior: 'smooth',
            });
        }
    }, [activeSlideOnNumbering])
    useEffect(() => {

        const handleHashChange = () => {
            const hashValue = window.location.hash;
            if (!hashValue) { setActiveSlide('#item1') }
            else {
                setActiveSlide(hashValue as slide)
            }
        }

        const handleScrollChnage = () => {
            if (carousel.current) {
                const scrollPosition = carousel.current.scrollLeft;
                const slideWidth = carousel.current.clientWidth;
                const newActiveSlide = Math.round(scrollPosition / slideWidth);
                setActiveSlide(`#item${newActiveSlide + 1}` as slide)
            }
        }


        window.addEventListener('hashchange', handleHashChange);
        if (carousel.current) {
            carousel.current.addEventListener('scroll', handleScrollChnage);
        }


        // handleHashChange();


        return () => {
            window.removeEventListener('hashchange', handleHashChange);
            if (carousel.current) {
                carousel.current.removeEventListener('scroll', handleScrollChnage);
            }
        };
    }, []);

    return (
        <div className="">
            <div className="carousel" ref={carousel}>
                <div id="item1" className="carousel-item w-full  flex flex-col relative">
                    <h2 className="font-semibold text-center text-[14px] mb-[10px]">Step 1</h2>
                    <p className="font-semibold text-center mx-auto text-[12px] mb-[5px] mt-[-7px] flex flex-row items-center">
                        Click <img src="/images/ios/share_ico.png" /> to open the share panel.
                    </p>
                    <div className="w-[80%] mx-auto relative overflow-visible flex align-center">
                        <img src="/images/aos/instructions_aos_pwa_1.png" className="w-full" />
                        <div className="absolute flex justify-between left-[-45px] right-[-45px] top-1/2 transform -translate-y-1/2">
                            <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(3); }} className="btn btn-circle bg-opacity-0 border-0 hover:bg-transparent text-[#2F3082] border-none shadow-none">
                                <img src={prevButton} className="w-[10px]" />
                            </a>
                            <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(1); }} className="btn btn-circle bg-opacity-0 border-0 hover:bg-transparent text-[#2F3082] border-none shadow-none">
                                <img src={nextButton} className="w-[10px]" />
                            </a>
                        </div>
                    </div>
                </div>
                <div id="item2" className="carousel-item w-full  flex flex-col relative">
                    <h2 className="font-semibold text-center text-[14px] mb-[10px]">Step 2</h2>
                    <p className="font-semibold text-center text-[12px] mb-[10px]">Click <span style={{ color: '#696ABF' }}>Add to Home Screen</span> menu.</p>
                    <div className="w-[80%] mx-auto relative overflow-visible flex align-center">
                        <img src="/images/aos/instructions_aos_pwa_2.png" className="w-full" />
                        <div className="absolute flex justify-between left-[-45px] right-[-45px] top-1/2 transform -translate-y-1/2">
                            <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(0); }} className="btn btn-circle bg-opacity-0 border-0 hover:bg-transparent text-[#2F3082] border-none shadow-none">
                                <img src={prevButton} className="w-[10px]" />
                            </a>
                            <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(2); }} className="btn btn-circle bg-opacity-0 border-0 hover:bg-transparent text-[#2F3082] border-none shadow-none">
                                <img src={nextButton} className="w-[10px]" />
                            </a>
                        </div>
                    </div>
                </div>
                <div id="item3" className="carousel-item w-full  flex flex-col relative">
                    <h2 className="font-semibold text-center text-[14px] mb-[10px]">Step 3</h2>
                    <p className="font-semibold text-center text-[12px] mb-[10px]">Click <span style={{ color: '#696ABF' }}>Add</span> to add MedSnap to your Home Screen.</p>
                    <div className="w-[80%] mx-auto relative overflow-visible flex align-center">
                        <img src="/images/ios/instruction_pwa_3.png" className="w-full" />
                        <div className="absolute flex justify-between left-[-45px] right-[-45px] top-1/2 transform -translate-y-1/2">
                            <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(1); }} className="btn btn-circle bg-opacity-0 border-0 hover:bg-transparent text-[#2F3082] border-none shadow-none">
                                <img src={prevButton} className="w-[10px]" />
                            </a>
                            <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(3); }} className="btn btn-circle bg-opacity-0 border-0 hover:bg-transparent text-[#2F3082] border-none shadow-none">
                                <img src={nextButton} className="w-[10px]" />
                            </a>
                        </div>
                    </div>
                </div>
                <div id="item4" className="carousel-item w-full flex flex-col relative">
                    <h2 className="font-semibold text-center text-[14px] mb-[10px]">MedSnap Now on Your Home Screen! 🎉</h2>
                    {/* <p className="font-semibold text-center w-[90%] mx-auto text-[12px] mb-[10px]">You have now successfully added MedSnap to your Home Screen!</p> */}
                    <p className="font-semibold text-center w-[90%] mx-auto text-[12px] mb-[10px]">Start your health journey now with MedSnap!</p>
                    <div className="w-[80%] mx-auto relative overflow-visible flex align-center">
                        <img src="/images/ios/instruction_pwa_4.png" className="w-full" />
                        <div className="absolute flex justify-between left-[-45px] right-[-45px] top-1/2 transform -translate-y-1/2">
                            <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(2); }} className="btn btn-circle bg-opacity-0 border-0 hover:bg-transparent text-[#2F3082] border-none shadow-none">
                                <img src={prevButton} className="w-[10px]" />
                            </a>
                            <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(0); }} className="btn btn-circle bg-opacity-0 border-0 hover:bg-transparent text-[#2F3082] border-none shadow-none">
                                <img src={nextButton} className="w-[10px]" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <div className="flex justify-center w-full py-2 mt-2 gap-2">


                <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(0); }} className={`btn h-[2px] min-h-[0px] p-0 pb-[8px] pl-[8px] rounded-full`} style={{
                    backgroundColor: activeSlide === '#item1' ? '#696ABF' : '#D9D9D9'
                }}>

                </a>
                <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(1); }} className={`btn h-[2px] min-h-[0px] p-0 pb-[8px] pl-[8px] rounded-full`} style={{
                    backgroundColor: activeSlide === '#item2' ? '#696ABF' : '#D9D9D9'
                }}>

                </a>
                <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(2); }} className={`btn h-[2px] min-h-[0px] p-0 pb-[8px] pl-[8px] rounded-full`} style={{
                    backgroundColor: activeSlide === '#item3' ? '#696ABF' : '#D9D9D9'
                }}>

                </a>
                <a onClick={(e) => { onAnchorClick(e); setactiveSlideOnNumbering(3); }} className={`btn h-[2px] min-h-[0px] p-0 pb-[8px] pl-[8px] rounded-full`} style={{
                    backgroundColor: activeSlide === '#item4' ? '#696ABF' : '#D9D9D9'
                }}>
                </a>
            </div>
        </div>
    )
}

export default IOSPromptSlide;