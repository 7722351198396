import React, { CSSProperties } from 'react';
import DefaultLogo from "../assets/Logo_icon.png";

interface LogoProps {
    type?: "black" | "white" | "text" | "colored_text" | "icon_only";
    alt?: string;
    link?: boolean
    style?: CSSProperties;
    className? : string
}

const Logo: React.FC<LogoProps> = ({ type, alt = "Medsnap", style, className }) => {
    return (
            !type && (
                <div className={`flex items-center justify-center h-screen px-[5px] ${className}`} style={style}>
                    <img src={DefaultLogo} alt={alt}  />
                </div>
            )
            
            
    );
};

export default Logo;
