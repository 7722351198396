import useUserStore from "../store/useUserStore";
import client from "../utils/axios-client";
import { AppNotification } from "../helpers/notifications"

const useNotificationsAPI = () => {

  const { token, user } = useUserStore();

  type Headers = Record<string, string>;

  const addBearerToken = (headers: Headers): Headers => ({
    ...headers,
    Authorization: `Bearer ${token}`,
  });
  

  const postNotifications = async (notification: any): Promise<void> => {

    try{

        const userNotification = notification.map((notification: any) => ({
            userId: user?.id,
            title: notification.title,
            body: notification.body,
            image: notification?.image,
            campaignId: notification.campaignId,
            click_action: notification?.click_action,
            category: notification?.category,
            timestamp: notification?.timestamp
        }));

        await client.post("/notifications", userNotification, {
            headers: addBearerToken({}),
        });

    } catch(error){
        console.log(error);
    }
  }

  const fetchNotifications = async (): Promise<AppNotification[]> => {
    try {
        const response = await client.get("/notifications", {
            headers: addBearerToken({}),
        });

        if (response.data && response.data.notificationList) {
            return response.data.notificationList;
        } else {
            throw new Error('Invalid response format');
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
  }

  const postCampaign = async (notification: any): Promise<void> => {

    try{

        await client.post("/campaign", {
          userId: 0,
          title: notification.title,
          body: notification.body,
          image: notification?.image,
          campaignId: notification.campaignId.replace(/\D/g, ''),
          click_action: notification?.click_action,
          category: notification?.category
          }, {
            headers: addBearerToken({}),
        });

    } catch(error){
        console.log(error);
    }
  }

  return {
    postNotifications,
    fetchNotifications,
    postCampaign
  };
};

export default useNotificationsAPI;
