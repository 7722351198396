import React from "react";

type HistoryIconOutlineProp = {
    isActive? : boolean
}
const HistoryIconOutline: React.FC<HistoryIconOutlineProp> = ({isActive}) => {
    return (
        <>
            {
             isActive ? 
             <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M22.25 4.375C23.0094 4.375 23.625 4.99061 23.625 5.75V16.75C23.625 17.5094 23.0094 18.125 22.25 18.125H9.06954C8.3402 18.125 7.64073 18.4147 7.125 18.9305L4.375 21.6805V5.75C4.375 4.99061 4.99061 4.375 5.75 4.375H22.25ZM5.75 3C4.23122 3 3 4.23122 3 5.75V23.3402C3 23.9527 3.74053 24.2595 4.17363 23.8264L8.09727 19.9027C8.35513 19.6449 8.70487 19.5 9.06954 19.5H22.25C23.7688 19.5 25 18.2688 25 16.75V5.75C25 4.23122 23.7688 3 22.25 3H5.75Z" fill="white"/>
             <path d="M7.125 7.8125C7.125 7.4328 7.4328 7.125 7.8125 7.125H20.1875C20.5672 7.125 20.875 7.4328 20.875 7.8125C20.875 8.1922 20.5672 8.5 20.1875 8.5H7.8125C7.4328 8.5 7.125 8.1922 7.125 7.8125ZM7.125 11.25C7.125 10.8703 7.4328 10.5625 7.8125 10.5625H20.1875C20.5672 10.5625 20.875 10.8703 20.875 11.25C20.875 11.6297 20.5672 11.9375 20.1875 11.9375H7.8125C7.4328 11.9375 7.125 11.6297 7.125 11.25ZM7.125 14.6875C7.125 14.3078 7.4328 14 7.8125 14H14.6875C15.0672 14 15.375 14.3078 15.375 14.6875C15.375 15.0672 15.0672 15.375 14.6875 15.375H7.8125C7.4328 15.375 7.125 15.0672 7.125 14.6875Z" fill="white"/>
             </svg>
              : 
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 5.75C3 4.23122 4.23122 3 5.75 3H22.25C23.7688 3 25 4.23122 25 5.75V16.75C25 18.2688 23.7688 19.5 22.25 19.5H9.06954C8.70487 19.5 8.35513 19.6449 8.09727 19.9027L4.17363 23.8264C3.74053 24.2595 3 23.9527 3 23.3402V5.75ZM7.8125 7.125C7.4328 7.125 7.125 7.4328 7.125 7.8125C7.125 8.1922 7.4328 8.5 7.8125 8.5H20.1875C20.5672 8.5 20.875 8.1922 20.875 7.8125C20.875 7.4328 20.5672 7.125 20.1875 7.125H7.8125ZM7.8125 10.5625C7.4328 10.5625 7.125 10.8703 7.125 11.25C7.125 11.6297 7.4328 11.9375 7.8125 11.9375H20.1875C20.5672 11.9375 20.875 11.6297 20.875 11.25C20.875 10.8703 20.5672 10.5625 20.1875 10.5625H7.8125ZM7.8125 14C7.4328 14 7.125 14.3078 7.125 14.6875C7.125 15.0672 7.4328 15.375 7.8125 15.375H14.6875C15.0672 15.375 15.375 15.0672 15.375 14.6875C15.375 14.3078 15.0672 14 14.6875 14H7.8125Z" fill="white"/>
              </svg>              
            }
        </>
    )
}

HistoryIconOutline.defaultProps = {
    isActive: false
}

export default HistoryIconOutline;