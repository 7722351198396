import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "../store/useUserStore";
import useMessageStore from "../store/useMessageStore";
import client from "../utils/axios-client";
import AppButton from "../components/AppButton";
import { trackEvent, trackIdentity } from "../helpers/analytics";

let currentIndex: number = 0;

const UserPin: React.FC = () => {
  const pageTitle = {
    title: "Enter your PIN",
    label: "Enter your pin to access your account.",
  };
  const navigate = useNavigate();

  const { userMobileNumber, userAction, setUser, setToken, setUserAction } =
    useUserStore();
  const { webSocketURL, setWebSocketURL, setChannel } = useMessageStore();
  const [pin, setPin] = useState<string[]>(new Array(4).fill(""));
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);
  const [nextButtonStatus, setNextButtonStatus] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const inputPins: string[] = [...pin];
    setShowError(false);
    
    if (/^\d+$/.test(value)) {
      inputPins[currentIndex] = value.substring(value.length - 1);

      if (!value) {
        setActiveIndex(currentIndex - 1);
      } else {
        setActiveIndex(currentIndex + 1);
      }

      setPin(inputPins);

    }
  };

  const handleKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    currentIndex = index;

    if(key === "Enter" && activeIndex === 4){
      submit();
    }
   
    if (key === "Backspace") {
      setActiveIndex(currentIndex - 1);
      setPin((previousValue) => {
        const updatedPin = [...previousValue];
        updatedPin[index] = "";
        return updatedPin;
      });
    }
    setNextButtonStatus(false);
  };

  const submit = async () => {
    const enteredPin = pin.join("");

    if (userAction?.action === "signIn") {
      try {
        const signInResponse = await client.post(`/signin`, {
          mobileNumber: userMobileNumber,
          PIN: enteredPin,
        });

        const { user, token } = signInResponse.data;

        if (!user.active) {
          const activateUserResponse = await client.post(`/users/activate`, {
            mobileNumber: userMobileNumber,
          });
        }

        setUser(user);
        setToken(token);
        setUserAction({ action: null });
        setWebSocketURL(`${import.meta.env.VITE_WS_URL}/chat/?token=${token}`);
        setChannel(`channel-${user.id}`);

        trackEvent("User Logged In");
        trackIdentity("signin", {
          userID: user.id,
          phone: userMobileNumber
        });

        //window.location.href = "/chat";
      } catch (error) {
        setShowError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    inputRef.current?.focus();

    if (pin.join("").length === 4) {
      setNextButtonStatus(true);
    }
  }, [activeIndex, pin]);

  const resetPIN = async () => {
    await client.post(`/otp/send`, {
      mobileNumber: userMobileNumber,
    });
    setUserAction({ action: "resetPIN" });
    navigate("/verification");
  };

  return (
    <>
      <div className="w-full sm:max-w-[375px] m-auto p-6 h-full flex flex-col justify-between">
        <label className="font-bold">{pageTitle.title}</label>
        <div>
          <div className="text-center pb-4">
            <span className="pb-4 font-normal text-sm">{pageTitle.label}</span>
          </div>
          <div className="flex flex-row gap-2 justify-center items-center">
            {/* for opt input */}
            {pin.map((_, index) => {
              return (
                <div key={index}>
                  <input
                    ref={index === activeIndex ? inputRef : null}
                    inputMode="numeric"
                    type="password"
                    className={`input input-bordered w-[47px] h-[67px] text-[20px] font-bold text-center ${
                      showError ? "input-error" : null
                    }`}
                    onChange={handleOnChange}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    value={pin[index]}
                  />
                </div>
              );
            })}
          </div>
          <>
            {showError && (
              <div className="flex flex-col justify-center items-center pt-3 text-center">
                <label className="text-[#D92D20] font-normal text-sm">
                  Incorrect PIN. Please try again.
                </label>
              </div>
            )}
          </>
          <div className="flex flex-col justify-center items-center pt-6 text-center">
            <label className="font-semibold text-sm">Forgot your PIN?</label>
            <a
              href="#"
              className="text-[#FE9693] font-bold text-sm"
              onClick={resetPIN}
            >
              Reset PIN
            </a>
          </div>
        </div>
        <AppButton
          type="button"
          variant="primary"
          onClick={submit}
          disabled={!nextButtonStatus}
        >
          {userAction?.action === "signIn" ? "Login" : "Submit"}
        </AppButton>
      </div>
    </>
  );
};

export default UserPin;
