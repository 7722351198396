import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import ConsultIconOutline from "../assets/icons/ConsultIconOutline";
import HistoryIconOutline from "../assets/icons/HistoryIconOutline";
import ProfileIconOutline from "../assets/icons/ProfileIconOutline";
import ResultsIconOutline from "../assets/icons/ResultsIconOutline";
import ChatIconOutline from "../assets/icons/ChatIconOutline";
import { trackEvent } from "../helpers/analytics";

const AppNavigation: React.FC = () => {
    
    const isKeyboardOpen = useDetectKeyboardOpen();
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState<string>("chat");

    const appMenus = [{
        title: "Consult",
        shortLabel: "consult",
        icon: ConsultIconOutline,
        link: "consult"
    },{
        title: "History",
        shortLabel: "history",
        icon: HistoryIconOutline,
        link: "history"
    },{
        title: "Chat",
        shortLabel: "chat",
        icon: ChatIconOutline,
        link: "chat"
    },{
        title: "Files",
        shortLabel: "files",
        icon: ResultsIconOutline,
        link: "files"
    },{
        title: "Profile",
        shortLabel: "profile",
        icon: ProfileIconOutline,
        link: "profile"
    }];

    const handleNavigation = (menu: string) => {
        setActiveMenu(menu);
        if(menu === "chat"){
            trackEvent("Chat Started");
        }
    }

    useEffect(() => {
        setActiveMenu(location.pathname.replace("/", ""));
    }, [location]);

    return (
            <>
            <div className="w-full sm:max-w-[375px] m-auto h-full flex flex-col overflow-hidden">
              <Outlet />
                <div className={`btm-nav bg-[#293056] pt-2 w-full sm:max-w-[375px] m-auto pb-[34px] h-[80px] relative ${isKeyboardOpen ? 'hidden' : 'flex flex-row'}`}>
                    {
                        appMenus.map((menu, index) => {

                            const NavIcon = menu.icon;
                            return (
                                <Link to={`/${menu.link}`} key={index}>.
                                    <button
                                      className={`flex flex-col items-center h-[56px] w-[56px] p-1   
                                      ${activeMenu === menu.shortLabel ? 'bg-[#363F72]' : 'bg-none'} 
                                      ${(activeMenu === "chat" && menu.shortLabel === "chat") && location.pathname === "/chat" ? 'bg-[#FE9693]' : 'bg-none' }
                                      ${menu.shortLabel === "chat" ? 'rounded-full p-[9px] bg-[#9984D4]' : 'rounded-[4px] bg-none'}
                                    `}
                                     onClick={() => handleNavigation(menu.shortLabel)}
                                    >
                                      {/* <img src={`${menu?.icon}`} /> */}
                                      <NavIcon isActive={activeMenu !== menu.shortLabel} />
                                      <span className="btm-nav-label text-[10px] text-white">{ menu.title }</span>
                                    </button>                
                                </Link>
                            )
                        })
                    }
                </div>
            </div>

        </>
    )
}

export default AppNavigation;