import React from "react";
import userStore from "../store/useUserStore";
import { isGuestEmailFormat } from "../helpers/common";

const ChatEmpty: React.FC = () => {

    const { user } = userStore();

    return (
      <div className="h-full flex flex-col scrollbar-thin scrollbar-thumb-gray-900 justify-center items-center">
          <img src="images/chat_history.svg" alt="Chat empty" className="pb-6" />
          <div className="flex flex-col gap-2 items-center">
            <label className="text-sm font-bold flex flex-row gap-1">Hello, { isGuestEmailFormat(user?.email) ? "Guest!" : user?.firstname } <img src="images/wave-icon.png" alt="wave-icon" width={18} height={18} /></label>
            <label className="text-base font-bold">I'm your MedSnap Health Assistant.</label>
            <label className="text-xs font-bold">Upload a medical test result so we can get started!</label>
          </div>
          <div className="text-[#667085] text-center text-[10px] pt-6 w-full px-[35px]">
           <span className="font-bold">Disclaimer: </span>
           <span className="font-normal">
           MedSnap is not programmed to provide or serve as a substitute of professional advice.
           </span>
          </div>
      </div>
    ) 
}

export default ChatEmpty;