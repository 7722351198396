import React, { useRef } from "react";
import client from "../utils/axios-client";
import toast, { Toaster } from "react-hot-toast";
import useUserStore from "../store/useUserStore";
import useMessageStore from "../store/useMessageStore";
import ToastWrapper from "../components/ToastWrapper";
import { ChatMessage } from "../helpers/enums";
import { ToastOptions } from "../helpers/constants";

type FileUploadFormProps = {
  showPreview: (imageSrc: string) => void;
};

const FileUploadForm: React.FC<FileUploadFormProps> = ({ showPreview }) => {

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setAttachment, setAttachmentPreview } = useMessageStore();
  const { token } = useUserStore();

  const blobToDataUrl = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  const handleFileUpload = async(file: File) => {

    try {

      if(!(file.size <= (14 * 1024 * 1024))){
        notify(ChatMessage.ATTACHMET_FILE_LIMIT_EXCEED, ToastOptions.ERROR);
        return;
      }

      const base64String = await blobToDataUrl(file);
      setAttachmentPreview({
        imgSrc: "",
        filename: file.name,
      });
      showPreview(base64String);
      const response = await client.post(
        `/upload`,
        {
          file: base64String,
          filename: file.name,
          mimetype: file.type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAttachment({
        ...response.data,
        createdAt: new Date(),
        deletedAt: null,
      });

      // Clear the file input
      resetFileInput();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }  

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {

    if ((event.target.files && event.target.files.length > 0) && isValidFileExtension(event.target.files[0].name)) {
      const file = event.target.files[0];
      handleFileUpload(file);
      return;
    }
      
    resetFileInput();
    notify(ChatMessage.ATTACHMENT_INVALID_FILE_TYPE, ToastOptions.ERROR)
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const isValidFileExtension = (filename: string): boolean => {
    const fileParts = filename.split('.');
    const extension = fileParts[fileParts.length - 1].toLowerCase();

    const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    return allowedExtensions.includes(extension);
  }

  const notify = (message: string, option: any) => {
    toast.error(
      (t) => <ToastWrapper message={message} toastId={t.id} />,
        {
          id: "file-upload-error",
          style: option,
          duration: 3000
      }); 
  }


  return (
    <>
    <Toaster />
    <label
      htmlFor="fileUpload"
      style={{ cursor: "pointer" }}
      className="absolute right-[10px] top-1/2 transform -translate-y-1/2 text-[#FAF5F4]"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M3.21768 9.64931C3.06989 9.49894 2.98707 9.29654 2.98707 9.0857C2.98707 8.87487 3.06989 8.67247 3.21768 8.52211L3.21793 8.52185L9.24893 2.42985L9.25815 2.42055L9.25867 2.42122C10.632 1.35485 12.0229 0.835807 13.4151 0.906333L13.4151 0.906336C15.1571 0.995669 16.5151 1.6608 17.6647 2.74857L17.6647 2.74857C18.8404 3.86115 19.6 5.44827 19.6 7.36821C19.6 8.84153 19.1711 10.1415 18.2656 11.294L18.2591 11.3022L18.2588 11.3019L9.75576 20.0619L9.74781 20.07L9.7474 20.0695C8.87042 20.7885 7.8909 21.1392 6.84 21.0971L6.83985 21.0971C5.51866 21.0422 4.52684 20.6142 3.78769 19.8833C2.92876 19.0356 2.4 17.9897 2.4 16.5872C2.4 15.4697 2.78663 14.4397 3.57776 13.4777L3.58329 13.471L3.58356 13.4712L11.0668 5.831C11.6736 5.18391 12.2794 4.78625 12.9106 4.68939C13.3272 4.62382 13.7534 4.66109 14.1522 4.79796C14.5511 4.93485 14.9104 5.16721 15.1989 5.47479C15.7518 6.06004 15.9882 6.79112 15.9055 7.61717L15.9055 7.61725C15.8482 8.18498 15.6156 8.70564 15.1941 9.20008L15.1897 9.20522L15.1896 9.20506L8.30973 16.2529C8.3097 16.2529 8.30967 16.2529 8.30964 16.253C8.23725 16.3275 8.15075 16.3868 8.05518 16.4276C7.95957 16.4684 7.85681 16.4897 7.75287 16.4903C7.64894 16.491 7.54592 16.4709 7.44982 16.4313C7.35371 16.3917 7.26646 16.3334 7.19313
         16.2598L7.19311 16.2597C7.0444 16.1103 6.96035 15.9084 6.95907 15.6975C6.95778 15.4867 7.03937 15.2838 7.18626 15.1325L7.18645 15.1323L14.031 8.12282C14.2185 7.90167 14.3129 7.6841 14.3355 7.45633L14.3355 7.45625C14.3729 7.08225 14.2845 6.81684 14.0574 6.57696L14.0569 6.57645C13.9424 6.4538 13.7994 6.36136 13.6405 6.30735C13.4816 6.25333 13.3119 6.23943 13.1463 6.26686L13.1451 6.26707C13.0443 6.2824 12.913 6.33801 12.7518 6.44917C12.5915 6.55962 12.4071 6.72115 12.2009 6.94068L12.1994 6.9422L12.1994 6.94219L4.74955 14.546C4.22753 15.184 3.978 15.856 3.978 16.5882C3.978 17.5126 4.31012 18.1707 4.89023 18.743L4.89034 18.7431C5.34405 19.1921 5.96346 19.4651 6.90397 19.5043C7.55576 19.5302 8.16075 19.3158 8.69043 18.8882L17.0795 10.2465C17.7074 9.43938 18.022 8.48985 18.022 7.3692C18.022 5.91493 17.4565 4.7391 16.5852 3.9138C15.6908 3.06659 14.6729 2.56974 13.3359 2.50107L13.3358 2.50107C12.3601 2.45074 11.3278 2.8289 10.2903 3.62241L4.33514 9.65048L4.164 9.82372V9.78123C4.13677 9.79674 4.10857 9.81063 4.07956 9.82281C3.9836 9.86309 3.88057 9.88384 3.7765 9.88384C3.67243 9.88384 3.5694 9.86309 3.47344 9.82281C3.37751 9.78254 3.29056 9.72356 3.21768 9.64931ZM3.21768 9.64931C3.21766 9.64929 3.21764 9.64926
         3.21762 9.64924L3.289 9.57921L3.21768 9.64931Z"
          fill="#8D919F"
          stroke="#8D919F"
          strokeWidth="0.2"
        />
      </svg>
      <input
        id="fileUpload"
        type="file"
        name="file"
        accept=".jpg, .jpeg, .png, .pdf"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </label>
    </>
  );
};

export default FileUploadForm;
