import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import client from "../utils/axios-client";
import useUserStore from "../store/useUserStore";
import useMessageStore from "../store/useMessageStore";
import AppButton from "../components/AppButton";
import ReactFlagsSelect from "react-flags-select";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    userMobileNumber,
    setUserMobileNumber,
    setUserAction,
    setUser,
    setToken,
  } = useUserStore();
  const { setWebSocketURL, setChannel } = useMessageStore();
  const [showError, setShowError] = useState<boolean>(false);
  const [guest, setGuest, removeGuest] = useCookies(["medsnapGuest"]);
  const [nextButtonStatus, setNextButtonStatus] = useState<boolean>(false);
  const [selected, setSelected] = useState("PH");

  const schema = yup.object().shape({
    mobileNumber: yup
      .number()
      .typeError("Invalid mobile number")
      .required("This field is required")
      .test("startsWith09", "Invalid mobile number", (value) => {
        if (value) {
          const stringValue = `0${value.toString()}`;
          return stringValue.startsWith("09");
        }
        return false;
      })
      .default(0),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    watch((value, { name }) => {
      if (name === "mobileNumber") {
        setNextButtonStatus(String(value?.mobileNumber).length === 11);
      }

      if (showError) {
        setShowError(false);
      }
    });
  }, [watch]);

  const submitForm = async () => {

    setShowError(false);
    isAccountExists();
  };

  const isAccountExists = async () => {
    const mobileNumber = getValues("mobileNumber");
    const accountResult = await client.post(`/account-exists`, {
      mobileNumber,
    });
    setUserMobileNumber(String(getValues("mobileNumber")));

    if (accountResult.data.message === "Account does not exist") {
      setShowError(true);
      // navigate("pin");
    } else if (accountResult.data.message === "Account is not active") {
      await client.post(`/otp/send`, {
        mobileNumber,
      });
      setUserAction({ action: "reactivate" });
      navigate("/verification");
    } else {
      if (
        accountResult.data.lastSignIn === null ||
        accountResult.data.lastSignIn === undefined
      ) {
        await client.post(`/otp/send`, {
          mobileNumber,
        });
        setUserAction({ action: "firstSignIn" });
        navigate("/verification");
      } else {
        setUserAction({ action: "signIn" });
        navigate("/pin");
      }
    }
  };

  const signInAsGuest = async (credentials: string) => {
    const response = await client.post(`/users/guest/signin`, {
      credentials,
    });


    if (response.status === 200) {
      setWebSocketURL(
        `${import.meta.env.VITE_WS_URL}/chat/?token=${response.data.token}`
      );
      setChannel(`channel-${response.data.user.id}`);

      setUser(response.data.user);
      setToken(response.data.token);

      setTimeout(() => {
        window.location.href = "/chat";
      }, 1500);
    } else {
      removeGuest("medsnapGuest");
    }
  };

  const handleGuestSignIn = async () => {
    if (guest?.medsnapGuest !== undefined) {
      signInAsGuest(guest.medsnapGuest);
    } else {

      await navigate("/guest-signin");
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: "url(images/login-vector-bg.png)",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
        }}
        className="w-full sm:max-w-[375px] m-auto h-full flex flex-col justify-between bg-[#2C2E83]"
      >
        <div className=" h-full flex flex-col items-center justify-center">
          <img
            src="images/medsnap_webapp.png"
            alt="image"
            height={24}
            width={148}
          />
        </div>
        <div className="flex flex-col rounded-tl-[20px] rounded-tr-[20px] bg-white p-6">
          <label className="font-bold text-2xl text-center pb-4">
            Login to your account
          </label>
          <Controller
            control={control}
            name="mobileNumber"
            render={({ field: { onChange, value } }) => (
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text text-sm font-medium">
                    Mobile Number
                  </span>
                </div>
                <div className="flex flex-row">
                  <ReactFlagsSelect
                    disabled
                    selected={selected}
                    onSelect={(code) => setSelected(code)}
                    countries={["PH"]}
                    showSelectedLabel={false}
                    className="h-[48px]"
                    selectButtonClassName={`!rounded-tl-lg !rounded-bl-lg h-[48px] !rounded-tr-[0px] !rounded-br-[0px] !input-bordered ${
                      errors.mobileNumber || showError
                        ? "!input-error"
                        : "!input-ghost"
                    }`}
                  />
                  <input
                    type="text"
                    placeholder="0917 XXX XXX"
                    value={value}
                    onChange={onChange}
                    className={`text-sm w-full input input-bordered rounded-tl-[0px] rounded-bl-[0px] ${
                      errors.mobileNumber || showError
                        ? "input-error"
                        : "input-ghost"
                    }`}
                  />
                </div>
                {errors.mobileNumber && (
                  <label className="text-[#D92D20] text-xs pt-2">
                    {errors.mobileNumber.message}
                  </label>
                )}
                {showError &&
                  String(getValues("mobileNumber")).length === 11 && (
                    <div className="flex flex-col pt-3">
                      <label className="text-[#D92D20] font-normal text-xs">
                        No account exists using this mobile number
                      </label>
                    </div>
                  )}
              </label>
            )}
          />

          <div className="flex flex-col gap-4 pt-[15px]">
            <AppButton
              type="button"
              variant="primary"
              onClick={handleSubmit(submitForm)}
              disabled={!nextButtonStatus}
            >
              Next
            </AppButton>
            <hr />
            {/* <AppButton
              type="button"
              variant="secondary"
              onClick={handleGuestSignIn}
            >
              Login as Guest
            </AppButton> */}
            <AppButton
              type="button"
              variant="secondary"
              onClick={() => navigate("/signup")}
            >
              Create Account
            </AppButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
