import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { getMaxDate } from "../helpers/common";
import useToast from "../hooks/useToast";
import client from "../utils/axios-client";
import useUserStore from "../store/useUserStore";
import AppButton from "../components/AppButton";
import ScreenLoader from "../components/ScreenLoader";
import Notification from "../components/Notifications";
import ReactFlagsSelect from "react-flags-select";
import { formSchema } from "../validations/schema";


const CORPSITE_URL = import.meta.env.VITE_CORPSITE_URL;
const POLICY_URL = import.meta.env.VITE_POLICY_URL;

const SignUp: React.FC = () => {

  const navigate = useNavigate();
  const { userAction, setNewUser, setUserMobileNumber, setUserAction } =
    useUserStore();
  const { notify, showToast } = useToast();
  const [nextButtonStatus, setNextButtonStatus] = useState<boolean>(false);
  const [screenLoading, setScreenLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("PH");

  const schema = yup.object().shape({
    ...formSchema,
    mobileNumber: yup
    .string()
    .matches(/^[0-9]{11}$/, 'Invalid mobile number')
    .required("This field is required")
    .test('startsWith09', 'Invalid mobile number', (value) => {
      if (value) {
        return value.startsWith('09');
      }
      return false;
    }),
    privacyPolicy: yup.boolean().oneOf([true]).required(),
    promotionConsent: yup.boolean()
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      privacyPolicy: false,
      promotionConsent: false,
      gender: ""
    }
  });

  // useEffect(() => {
  //   watch((value, {}) => {
  
  //     setNextButtonStatus(
  //       value.privacyPolicy !== undefined &&
  //         value.privacyPolicy &&
  //         value.promotionConsent !== undefined &&
  //         value.promotionConsent
  //     );
  //   });
  // }, [watch]);

  const submitForm = async () => { 
      isAccountExists();
  };

  const isAccountExists = async () => {
    
    try{

        setScreenLoading(true);
        const accountResult = await client.post(`/account-exists`, {
          mobileNumber: getValues("mobileNumber"),
          email: getValues("email")
        });

        setUserMobileNumber(getValues("mobileNumber"));
  
        if (accountResult.data.message === "Account does not exist") {
          confirmAccount();
        }else{
          setScreenLoading(false);
          showToast({
            visible: true,
            variant: 'error',
            custom: "Account already exist. Please **Login** or use **Reset PIN** to continue."
          });
        }

    }catch(error){
        setScreenLoading(false);
        showToast({
          visible: true,
          variant: 'error',
          message: "Sorry, we encountered an issue. Please try again."
        });
    }
  };

  const confirmAccount = async () => {
    const response = await client.post(`/otp/send`, {
      mobileNumber: getValues("mobileNumber"),
    });

    setNewUser({
      id: null,
      firstname: getValues("firstname"),
      lastname: getValues("lastname"),
      username: getValues("mobileNumber"),
      gender: getValues("gender"),
      birthdate: getValues("birthdate"),
      privacyPolicy: getValues("privacyPolicy"),
      promotionConsent: getValues("promotionConsent"),
      email: getValues("email"),
    });

    if (userAction?.action !== "guestSignUp") {
      setUserAction({ action: "signUp" });
    }
    navigate("/verification", { replace: true });
  };

  return (
    <>
      <div className="w-full sm:max-w-[375px] m-auto p-6">
        <div className="flex justify-center items-center pb-6">
          <label className="font-bold text-2xl">Create Account</label>
        </div>
        <Notification notificationOptions={notify} />
        <Controller
          control={control}
          name="firstname"
          render={({ field: { onChange, value } }) => (
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text text-sm font-medium">
                  First Name
                </span>
              </div>
              <input
                type="text"
                placeholder="Your first name"
                value={value}
                onChange={onChange}
                className={`input h-[48px] input-bordered w-full text-sm ${
                  errors.firstname ? "input-error" : "input-ghost"
                }`}
              />
              {errors.firstname && (
                <label className="text-[#D92D20] text-xs pt-2">
                  {errors.firstname.message}
                </label>
              )}
            </label>
          )}
        />
        <Controller
          control={control}
          name="lastname"
          render={({ field: { onChange, value } }) => (
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text text-sm font-medium">
                  Last Name
                </span>
              </div>
              <input
                type="text"
                placeholder="Your last name"
                value={value}
                onChange={onChange}
                className={`input h-[48px] input-bordered w-full text-sm ${
                  errors.lastname ? "input-error" : "input-ghost"
                }`}
              />
              {errors.lastname && (
                <label className="text-[#D92D20] text-xs pt-2">
                  {errors.lastname.message}
                </label>
              )}
            </label>
          )}
        />
        <Controller
          control={control}
          name="birthdate"
          render={({ field: { onChange, value } }) => (
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text text-sm font-medium">
                  Date of Birth
                </span>
              </div>
              <input
                type="date"
                placeholder="MM/DD/YYYY"
                value={value}
                max={getMaxDate()}
                onChange={onChange}
                className={`input h-[48px] input-bordered w-full text-sm ${
                  errors.birthdate ? "input-error" : "input-ghost"
                }`}
              />
              {errors.birthdate && (
                <label className="text-[#D92D20] text-xs pt-2">
                  {errors.birthdate.message}
                </label>
              )}
            </label>
          )}
        />
        <Controller
          control={control}
          name="gender"
          render={({ field: { onChange, value } }) => (
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text text-sm font-medium">Gender</span>
              </div>
              <select
                className={`select select-bordered w-full ${
                  errors.gender ? "select-error" : "select-ghost"
                }`}
                onChange={onChange}
                defaultValue={value}
              >
                <option disabled value="">
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              {errors.gender && (
                <label className="text-[#D92D20] text-xs pt-2">
                  {errors.gender.message}
                </label>
              )}
            </label>
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text text-sm font-medium">Email</span>
              </div>
              <input
                type="text"
                placeholder="email@domain.com"
                value={value}
                onChange={onChange}
                className={`input h-[48px] input-bordered w-full text-sm ${
                  errors.email ? "input-error" : "input-ghost"
                }`}
              />
              {errors.email && (
                <label className="text-[#D92D20] text-xs pt-2">
                  {errors.email.message}
                </label>
              )}
            </label>
          )}
        />
        <Controller
          control={control}
          name="mobileNumber"
          render={({ field: { onChange, value } }) => (
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text text-sm font-medium">
                  Mobile Number
                </span>
              </div>
              <div className="flex flex-row">
                  <ReactFlagsSelect
                    disabled
                    selected={selected}
                    onSelect={(code) => setSelected(code)}
                    countries={["PH"]}
                    showSelectedLabel={false}
                    className="h-[48px]"
                    selectButtonClassName={`!rounded-tl-lg !rounded-bl-lg h-[48px] !rounded-tr-[0px] !rounded-br-[0px] !input-bordered ${errors.mobileNumber ? "!input-error" : "!input-ghost"}`}
                  />
                <input
                    type="text"
                    placeholder="0917 XXX XXX"
                    value={value}
                    onChange={onChange}
                    className={`text-sm w-full input input-bordered rounded-tl-[0px] rounded-bl-[0px] ${errors.mobileNumber ? "input-error" : "input-ghost"}`}
                  />
                </div>
              {errors.mobileNumber && (
                <label className="text-[#D92D20] text-xs pt-2">
                  {errors.mobileNumber.message}
                </label>
              )}
            </label>
          )}
        />
        <div className="flex flex-col gap-3 py-6">
          <Controller
            control={control}
            name="privacyPolicy"
            render={({ field: { onChange, value } }) => (
              <div className="flex flex-row gap-[10px]">
                <input
                  type="checkbox"
                  checked={value}
                  className={`checkbox [--chkbg:theme(colors.indigo.900)] [--chkbg:theme(colors.)] [--chkfg:white] ${
                    errors.privacyPolicy ? "checkbox-error" : ""
                  }`}
                  onChange={onChange}
                />   
                <p className="text-xs text-[#606471]">
                  By providing my affirmation, I signify that I have read and
                  understood {" "}
                  <a
                    href={`${POLICY_URL}/privacy`}
                    className="text-[#FE9693] font-normal"
                  >
                    917Ventures' Privacy Policy
                  </a>{" "}
                  which explains to me how long 917Ventures retains my data,
                  how 917Ventures protects it, and my rights as a data subject,
                  including how to exercise them, and agree to {" "}
                  <a
                    href={`${CORPSITE_URL}/privacy-notice`}
                    className="text-[#FE9693] font-normal"
                  >
                    Medsnap's Privacy Notice
                  </a>,
                  including the sharing of my personal data to its partners for the fulfillment
                  of the service I am applying for.
                </p>
              </div>
            )}
          />
          <Controller
            control={control}
            name="promotionConsent"
            render={({ field: { onChange, value } }) => (
              <div className="flex flex-row gap-[10px]">
                <input
                  type="checkbox"
                  checked={value}
                  className={`checkbox [--chkbg:theme(colors.indigo.900)] [--chkbg:theme(colors.)] [--chkfg:white] ${
                    errors.promotionConsent ? "checkbox-error" : ""
                  }`}
                  onChange={onChange}
                />
                <p className="text-xs text-[#606471]">
                I agree to receive, through my given contact information,
                communication of relevant services and/or advisories,
                commercial and promotional alerts, advertisements,
                and surveys from MedSnap, and at times, in partnership with third-parties.
                </p>
              </div>
            )}
          />
        </div>
        <div className="flex flex-col gap-4">
          <AppButton
            type="button"
            variant="primary"
            disabled={screenLoading || notify.visible}
            onClick={handleSubmit(submitForm)}
          >
            Create Account 
          </AppButton>
          <AppButton
            type="button"
            variant="secondary"
            onClick={() => {
              navigate("/signin");
            }}
          >
            Cancel
          </AppButton>
        </div>
        <div className="flex flex-row items-center justify-center pt-6 gap-3">
          <label className="text-sm text-[#747474] font-medium">
            Already have an account?
          </label>
          <label
            className="text-[#FE9693] text-sm font-bold"
            onClick={() => navigate("/signin")}
          >
            Sign In
          </label>
        </div>
      </div>
    </>
  );
};

export default SignUp;
