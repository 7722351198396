import React, { useState, useRef } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import useMessagesAPI from "../hooks/useMessagesAPI";
import useMessageStore from "../store/useMessageStore";
import AppButton from "./AppButton";
import  toast, { Toaster } from "react-hot-toast";
import { ToastOptions } from "../helpers/constants";
import ToastWrapper from "./ToastWrapper";
import { ChatMessage, ErrorMessage } from "../helpers/enums";
import { trackEventPayload } from "../helpers/analytics";
import { getCalendarTime } from "../helpers/dateFormatter";


type AttachmentFile = {
 id: number;
 name: string;
 mimetype: string;
 url: string;
 createdAt: Date;
 deletedAt: Date | null;
}
  
export type AttachmentsData = {
 channel: string;
 conversation: string;
 attachment: AttachmentFile;
 _id: string;
}

type AttachmentDataProps = {
  chatAttachments: AttachmentsData[],
  refetchChatAttachment: (id: string) => void
}

const ChatAttachmentCard: React.FC<AttachmentDataProps> = ({chatAttachments, refetchChatAttachment}) => {

  const [chatThreadId, setChatThreadId] = useState<string>("");
  const [filename, setFilename] = useState<string>("");
  const deleteModalRef = useRef<HTMLDialogElement>(null); 
  const navigate = useNavigate(); 
  const { setActiveConversation } = useMessageStore();
  const { fetchMessages, deleteAttachment, downloadAttachmentFile } = useMessagesAPI();
    
  const showChatThread = (event: React.MouseEvent, conversation: string, timestamp: Date) => {

    event.stopPropagation();
    setActiveConversation(conversation);
    fetchMessages(conversation);

    trackEventPayload('Chat viewed', {
      chat_id: conversation,
      chat_timestamp: getCalendarTime(timestamp, 'YYYY/MM/DD HH:mm:ss')
    });
    navigate("/chat");
 };

 const viewImage = (event: React.MouseEvent, imageURL: string, messageId: string, mimetype: string, file: string) => {

    event.stopPropagation();
    mimetype === "application/pdf"
    ? window.open(imageURL, '_blank')
    : navigate('/attachment-image-view', { state: { url: imageURL, messageId } });

    trackEventPayload("File viewed", {
      filename: file
    });
};

 const openModal = (event: React.MouseEvent, messageId: string, file: string) => {
    event.stopPropagation();
    if (deleteModalRef.current) {
         deleteModalRef.current.showModal();
         setChatThreadId(messageId);
         setFilename(file);
     }
  };
 
  const closeModal = () => {
     if (deleteModalRef.current) {
       deleteModalRef.current.close();
     }
  };

  const deleteFile = async () => {

    closeModal();
    try{

      await deleteAttachment(chatThreadId);
      toast.success((t) => (
        <ToastWrapper message={ChatMessage.ATTACHMENT_DELETE_SUCCESS} toastId={t.id} />
      ),{
         id: 'attachment-delete-success',
         style : ToastOptions.SUCCESS
      });
      refetchChatAttachment(chatThreadId);

      trackEventPayload("File deleted", {
        filename: filename
      });

    }catch(error){

        toast.error((t) => (
         <ToastWrapper message={ErrorMessage.NETWORK_ERROR} toastId={t.id} />
        ),{
          id: 'attachment-delete-error',
          style : ToastOptions.ERROR
        });

    }finally{
      setChatThreadId("");
      setFilename("");
    }
  }

  const getAssetFileName = (attachmentUrl: string) => {

    if (attachmentUrl) {
      const filename = attachmentUrl.substring(attachmentUrl.lastIndexOf('/') + 1);
      return filename;
    }
    return "";
  }

  const downloadFile = async (e: React.MouseEvent, attachment: string, fileName: string) => {

    e.stopPropagation();

    try {
      const response = await downloadAttachmentFile(attachment);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      trackEventPayload("File downloaded", {
        filename: fileName
      });
      
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

    return (
      <>
        <Toaster />
        {
          chatAttachments.map((item, index) => (
            <div className="w-[46%] cursor-pointer" key={index} onClick={(e) => showChatThread(e, item.conversation, item.attachment.createdAt)}>
                <div className="card card-compact bg-base-100 rounded-none">
                {
                  item.attachment.mimetype === "application/pdf" ?
                  <div className="flex items-center justify-center h-[100px] border border-[#D2D6E4] rounded bg-[#F5F3FB]">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.6659 2.66699H7.99881C7.29154 2.66699 6.61323 2.94796 6.11311 3.44807C5.61299 3.94819 5.33203 4.6265 5.33203 5.33377V26.668C5.33203 27.3753 5.61299 28.0536 6.11311 28.5537C6.61323 29.0538
                       7.29154 29.3348 7.99881 29.3348H23.9995C24.7068 29.3348 25.3851 29.0538 25.8852
                       28.5537C26.3853 28.0536 26.6663 27.3753 26.6663 26.668V10.6673M18.6659 2.66699L26.6663 10.6673M18.6659 2.66699V10.6673H26.6663M21.3327 17.3343H10.6656M21.3327 22.6678H10.6656M13.3324 12.0007H10.6656"
                      stroke="#2F3082" strokeOpacity="0.2" strokeWidth="2.2501" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                  </div>:
                  <figure className="h-[100px] rounded-[4px]">
                    <img
                      className="rounded-[10px]"
                      src={item.attachment.url}
                      alt="medical_attachment"
                    />
                  </figure> 
                }
                <div className="pt-2">
                    <div className="flex flex-row items-center">
                    <img src={item.attachment.mimetype === "application/pdf" ? 'images/file-icon.svg' : 'images/result-img-icon.svg'} alt="alt text" height={100}/>
                    <div className="flex flex-col overflow-hidden flex-1">
                        <label className="text-sm font-normal whitespace-nowrap text-ellipsis overflow-hidden">
                        {item.attachment.name}
                        </label>
                        <label className="text-[10px] font-normal">
                        {dayjs(item.attachment.createdAt).format("DD MMM YYYY, h:mm A")}
                        </label>
                    </div>
        
                    <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn btn-ghost" onClick={(e) => e.stopPropagation()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18" fill="none">
                        <path d="M3.875 15.25C3.875 16.2855 3.03553 17.125 2 17.125C0.964466 17.125 0.125 16.2855 0.125 15.25C0.125 14.2145 0.964466 13.375 2 13.375C3.03553 13.375 3.875 14.2145 3.875 15.25ZM3.875 9C3.875 10.0355 3.03553 10.875 2 10.875C0.964466 10.875 0.125 10.0355 0.125 9C0.125 7.96447 0.964466 7.125 2 7.125C3.03553 7.125 3.875 7.96447 3.875 9ZM3.875 2.75C3.875 3.78553 3.03553 4.625 2 4.625C0.964466 4.625 0.125 3.78553 0.125 2.75C0.125 1.71447 0.964466 0.875 2 0.875C3.03553 0.875 3.875 1.71447 3.875 2.75Z" fill="#606471"/>
                    </svg>
                    </div>
                    <ul tabIndex={0} className="text-xs dropdown-content z-[1] menu shadow w-[170px] p-0 rounded-[4px] bg-white">
                        <li onClick={(e) => viewImage(e, item.attachment.url, item._id, item.attachment.mimetype, item.attachment.name)}>
                        <a className="font-medium active:!bg-transparent rounded-none flex flex-row justify-between">
                            View File
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M2 12C2 12 5.63636 5 12 5C18.3636 5 22 12 22 12C22 12 18.3636 19 12 19C5.63636 19 2 12 2 12Z" stroke="#323643" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#323643" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                        </li>
                        <li onClick={(e) => showChatThread(e, item.conversation, item.attachment.createdAt)}>
                        <a className="font-medium active:!bg-transparent flex flex-row justify-between rounded-none border border-t-slate-300">
                            Show Chat Thread
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.0723 1.74986C17.7232 1.74986 18.2508 2.27752 18.2508 2.92843V12.357C18.2508 13.0079 17.7232 13.5356 17.0723 13.5356H5.77473C5.14958 13.5356 4.55003 13.7839 4.10798 14.226L1.75084 16.5831V2.92843C1.75084 2.27752 2.2785 1.74986 2.92941 1.74986H17.0723ZM2.92941 0.571289C1.62759 0.571289 0.572266 1.62662 0.572266 2.92843V18.0058C0.572266 18.5308 1.20701 18.7937 1.57824 18.4225L4.94136 15.0593C5.16238 14.8383 5.46215 14.7141 5.77473 14.7141H17.0723C18.3741 14.7141 19.4294 13.6588 19.4294 12.357V2.92843C19.4294 1.62662 18.3741 0.571289 17.0723 0.571289H2.92941Z" fill="#323643"/>
                            </svg>
                        </a>
                        </li>
                        <li>
                        <a href='#'
                          className="font-medium active:!bg-transparent flex flex-row justify-between rounded-none border border-t-slate-300"
                          onClick={(e) => downloadFile(e, getAssetFileName(item.attachment.url), item.attachment.name)}
                        >
                          Download
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M20 14V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14M12 15L12 3M12 15L8 11M12 15L16 11" stroke="#323643" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                        </li>
                        <li onClick={(e) => openModal(e, item._id, item.attachment.name)}>
                        <a className="active:!bg-transparent flex flex-row justify-between rounded-none border border-t-slate-300 text-[#D92D20] font-medium">
                            Delete
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 6V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6M4 6H20M10 10V16M14 10V16" stroke="#D92D20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                        </li>
                    </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            ))
        }
        <dialog
          id="deactivateAccountModal"
          className="modal max-w-[327px] m-auto"
          ref={deleteModalRef}
        >
        <div className="modal-box flex flex-col items-center">
            <img src="images/modal-danger-icon.svg" />
            <h3 className="font-bold text-base pt-[20px]">Delete File?</h3>
            <p className="py-4 text-sm text-center">
              Are you sure you want to delete this file?
            </p>
            <div className="flex flex-col w-full gap-3">
              <AppButton
                variant="danger"
                type="button"
                onClick={() => deleteFile()}
              >
                Yes, Delete File
              </AppButton>
              <AppButton type="button" onClick={() => closeModal()}>
                Cancel
              </AppButton>
            </div>
         </div>
        </dialog>
      </>
    )
}

export default ChatAttachmentCard;