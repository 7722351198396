import React from "react";
import ScreenHeader from "../components/ScreenHeader";
import useFetchAPI from "../hooks/useFetchAPI";

const Faq: React.FC = () => {

    const { data = [] } = useFetchAPI('/faqs');

    return (
        <div className="w-full sm:max-w-[375px] m-auto h-full flex flex-col overflow-hidden">
            <ScreenHeader type="secondary">
              <label className="font-semibold text-base">FAQ</label> 
            </ScreenHeader>
            <div 
                className="text-center text-white"
                style={{
                    backgroundImage: 'linear-gradient(270deg, rgba(153, 132, 212, 0.70), rgba(44, 46, 131, 1)), url("images/faq-bg-image.png")',
                    backgroundSize: 'cover',
                    padding: '30px'
                  }}>
                <label className="font-bold text-[20px]">Frequent Asked Questions</label>
            </div>
            <div className="px-[24px] py-[30px] overflow-y-auto overflow-x-hidden">
                {
                 data?.map((faq:any , index: number) => (
                     <div key={ index} className="join join-vertical w-full pb-[40px]">
                        <label className="ml-[17px] font-bold text-base">{ faq?.attributes.category} </label>
                        {
                            faq.attributes.faqs.data.map((details: any, index: number) => (
                                <div key={index} className="collapse collapse-arrow join-item border-b-[#EAECF0] border-b">
                                    <input type="checkbox" name="my-accordion-4"  /> 
                                    <div className="collapse-title font-bold text-sm">
                                      { details.attributes.question}
                                    </div>
                                    <div className="collapse-content"> 
                                    <p className="font-bold text-sm">
                                      { details.attributes.answer }
                                    </p>
                                    </div>
                                </div>
                            ))
                        }
                     </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Faq;