import React from "react";
import AppButton from "../components/AppButton";

const Welcome: React.FC = () => {
  return (
    <>
      <div className="w-full sm:max-w-[375px] h-full m-auto bg-[#2C2E83] flex flex-col justify-between">
        <div className="flex flex-col gap-4 p-6 justify-center items-center m-auto">
          <span className="text-2xl text-white font-bold">Welcome to</span>
          <img
            className="ml-6 w-[185.619px] h-[30px]"
            src="images/medsnap_white_logo.png"
            alt="logo"
          />
        </div>
        <div className="bg-white rounded-tr-[20px] rounded-tl-[20px] pt-[24px] px-4 pb-4 text-center flex flex-col">
          <label className="font-bold text-xl pb-4">
            Login to your account
          </label>
          <label className="form-control w-full pb-[30px]">
            <div className="label">
              <span className="label-text text-sm font-medium">
                Mobile Number
              </span>
            </div>
            <input
              type="text"
              placeholder="0917 XXX XXX"
              className="input h-[48px] input-bordered w-full"
            />
          </label>
          <AppButton type="button" variant="primary">
            Next
          </AppButton>
          <div className="divider"></div>
          <div className="flex flex-col gap-4">
            <AppButton type="button" variant="secondary">
              Login as Guest
            </AppButton>
            <AppButton type="button" variant="secondary">
              Create Account
            </AppButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
