import React from "react";
import client from "../utils/axios-client";
import useMessageStore from "../store/useMessageStore";
import useUserStore from "../store/useUserStore";

const ChatStop: React.FC = () => {
  const {
    activeConversation,
    channel,
    typeable,
    setSending,
    setTypeable,
    setResponds,
  } = useMessageStore();
  const { token } = useUserStore();

  const handleStopChat = async () => {
    const response = await client.post(
      `/messages/stop`,
      {
        content: "",
        channel,
        conversation: activeConversation,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setSending(false);
    setTypeable(true);
    setResponds(true);
  };

  return (
    <div
      className="btn border border-[#A4A8B6] my-3 py-[10px] px-8 rounded-full bg-white absolute bottom-[185px]"
      onClick={handleStopChat}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          d="M10.4993 18.3332C15.1017 18.3332 18.8327 14.6022 18.8327 9.99984C18.8327 5.39746 15.1017 1.6665 10.4993 1.6665C5.89698 1.6665 2.16602 5.39746 2.16602 9.99984C2.16602 14.6022 5.89698 18.3332 10.4993 18.3332Z"
          stroke="#475467"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9993 7.49984H7.99935V12.4998H12.9993V7.49984Z"
          stroke="#475467"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Stop Generating
    </div>
  );
};

export default ChatStop;
