import { useEffect } from 'react';
import { isGuestEmailFormat } from "../helpers/common";
import useUserStore from "../store/useUserStore";
import useMessageStore from "../store/useMessageStore";
import useNotificationStore from "../store/useNotificationsStore";

const useHandleGuestEmail = (webSocketConnection: WebSocket | null ) => {

    const { user, setUser, setToken, setUserAction } = useUserStore();
    const { 
        pingInterval,
        setWebSocketURL,
        setChannel,
        setMessages,
        setConversations,
        setActiveConversation
    } = useMessageStore();

    const { setPushNotifications } = useNotificationStore();

  useEffect(() => {
 
    if (isGuestEmailFormat(user?.email)) {

      setUser(null);
      setToken(null);
      setUserAction({ action: null });
      webSocketConnection?.close();
      setWebSocketURL("");
      setChannel("");
      setMessages([]);
      setConversations([]);
      setActiveConversation(null);
      setPushNotifications(null);
      window.clearInterval(pingInterval);
      localStorage.removeItem("userStore");
      localStorage.removeItem("messageStore");

    }
  }, []);
};

export default useHandleGuestEmail;
