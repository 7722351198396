import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Markdown from "react-markdown";
import useMessageStore from "../store/useMessageStore";
import useUserStore from "../store/useUserStore";
import useMessagesAPI from "../hooks/useMessagesAPI";
import useToast from "../hooks/useToast";
import ScreenHeader from "../components/ScreenHeader";
import FileUploadForm from "../components/FileUploadForm";
import ChatStop from "../components/ChatStop";
import ChatAttachments from "../components/ChatAttachments";
import ChatTypingIndicator from "../components/ChatTypingIndicator";
import { useLocation } from "react-router-dom";
import { scrollToElement } from "../helpers/dom";
import ChatEmpty from "../components/ChatEmpty";
import { trackEventPayload } from "../helpers/analytics";
import NotificationBell from "../components/NotificationBell";
import useHandleGuestEmail from "../hooks/useHandleGuestEmail";

dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(timezone);

const Chat: React.FC = () => {

  const location = useLocation();
  const [imagePreviewSource, setImagePreviewSource] = useState<string>("");

  const {
    webSocketConnection,
    channel,
    activeConversation,
    typeable,
    messages,
    attachment,
    sending,
    responds,
    generatingResponse,
    setActiveConversation,
    setSending,
    setTypeable,
    setResponds,
    addMessage,
    setResponseTimeout,
    setAttachment,
    setAttachmentPreview,
    setGeneratingResponse,
  } = useMessageStore();


  useHandleGuestEmail(webSocketConnection);

  const { token } = useUserStore();
  const { fetchConversations, fetchMessages, stopGeneratingResponse } = useMessagesAPI();
  const { notify, showToast } = useToast();
  const [showChatStop, setShowChatStop] = useState(false);


  const schema = yup.object().shape({
    content: yup
      .string()
      .default("")
      .when("attachment", {
        is: (attachment: any) => attachment === null,
        then: (schema) => schema.required("This field is required."),
        otherwise: (schema) =>
          schema.max(2000, "You can only send up to 2000 characters"),
      }),
  });

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: "",
    },
  });

  const contentLength = watch("content")?.length || 0;

  const sendMessage = async () => {
    try {
      if (!typeable) {
        return;
      }

      const content = String(getValues("content"));

      if ((!content && attachment === null) || content.length > 2000) {
        setSending(false);
        setTypeable(true);
        setResponds(true);
        return;
      }

      setTypeable(false);
      setSending(true);
      setGeneratingResponse(true);

      const payload = {
        channel,
        conversation: activeConversation,
        role: "User",
        content,
        attachment: attachment ?? null,
        createdAt: new Date(),
      };

      webSocketConnection?.send(
        JSON.stringify({
          type: "question",
          payload,
        })
      );

      addMessage([payload]);

      trackEventPayload("Chat Sent", {
        chat_id: payload.conversation,
        message: payload.content
      });

      if(attachment){
        trackEventPayload("File uploaded", {
          file_type: attachment.mimetype.split('/')[1],
          filename: attachment.name
        });
      }

      setValue("content", "", { shouldValidate: false });
      setResponds(false);
      setAttachment(null);
      setAttachmentPreview(null);
      setTimeout(() => {
        scrollToElement(chatboxAnchor, "smooth");

        if (messages.length === 1) {
          // fetchConversations(user?.id);
        }
      }, 500);

      setResponseTimeout(
        window.setTimeout(() => {
          if (!responds) {
            setResponds(true);
            setTypeable(true);
            setSending(false);
          }
        }, 60000)
      );
    } catch (error) {
      showToast({
        visible: true,
        variant: "error",
        message: "Unable to send your message",
      });
    }
  };

  useEffect(() => {
    if (!typeable) {
      const delayTimeout = setTimeout(() => {
        setShowChatStop(true);
        scrollToElement("chatStopAnchor", "instant");
      }, 1000);
  
      return () => clearTimeout(delayTimeout);
    } else {
      setShowChatStop(false);
    }

    if(showChatStop){
      trackChatResponse();
    }

  }, [typeable]);


  useEffect(() => {
    return () => {
      if(generatingResponse){
        stopGeneratingResponse();
      }
    }    
  }, [generatingResponse])
  

  const trackChatResponse = () => {

    const userConversation = messages[messages.length - 1];

    if(userConversation?.role === "Bot"){
      trackEventPayload('Chat Response Received', {
        chat_id: activeConversation,
        response: userConversation.content
      });
    }

}

  const displayCalendarTime = (datetime: Date) => {
    const parsedDate = dayjs(datetime);
    const localDate = parsedDate.utc().utcOffset("+08:00");

    const calendarFormat = {
      sameDay: "[Today at] h:mm A",
      nextDay: "[Tomorrow at] h:mm A",
      nextWeek: "dddd [at] h:mm A",
      lastDay: "[Yesterday at] h:mm A",
      lastWeek: "[Last] dddd [at] h:mm A",
      sameElse: "DD/MM/YYYY",
    };

    return localDate.calendar(null, calendarFormat);
  };

  const renderMessages = () => {
    return messages.map((message, index) => (
      <div key={index} id={message.id}>
        {message.content && (
          <div
            className={`chat ${
              message.role === "Bot" ? "chat-start whitespace-pre" : "chat-end"
            }`}
          >
            <div
              className={`chat-bubble whitespace-pre-wrap ${
                message.role === "Bot"
                  ? "chat-bubble bg-chat-bot !rounded-tl-none rounded-br-[24px] !rounded-bl-[24px] rounded-tr-[24px] before:!content-none p-4 font-medium"
                  : "chat-bubble bg-chat-user !rounded-tr-none !rounded-br-[24px] rounded-bl-[24px] rounded-tl-[24px] before:!content-none p-4 font-medium"
              } before:!content-none`}
            >
              <p
                className={`chat-header text-pretty ${
                  message.role === "Bot" ? "text-bot" : "text-white"
                }`}
              >
                {message.role === "Bot" ? (
                  <>
                    <Markdown>{message.content}</Markdown>
                  </>
                ) : (
                  message.content
                )}
              </p>
              <p
                className={`chat-footer mt-3 text-right text-xs ${
                  message.role === "Bot"
                    ? "text-bubble-bot-footer"
                    : "text-white"
                }`}
              >
                {displayCalendarTime(message.createdAt)}
              </p>
            </div>
          </div>
        )}
        {/* Attachment */}
        {message.attachment && (
          <>
            {message.attachment.deletedAt === null ? (
              <div className="my-2">
                <div className="flex justify-end mr-3">
                  {message.attachment.mimetype === "application/pdf" ? (
                    <div className="rounded-[10px] w-[151px] h-[148px] bg-[#F5F5F5] p-[10px] flex flex-col gap-2">
                      <div className="flex justify-center items-center bg-[#F5F3FB] border border-[#D2D6E4] rounded-[4px] w-[131px] h-[95px]">
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.6659 2.66699H7.99881C7.29154 2.66699 6.61323 2.94796 6.11311 3.44807C5.61299 3.94819 5.33203 4.6265 5.33203 5.33377V26.668C5.33203 27.3753 5.61299 28.0536 6.11311 28.5537C6.61323 29.0538
                       7.29154 29.3348 7.99881 29.3348H23.9995C24.7068 29.3348 25.3851 29.0538 25.8852
                       28.5537C26.3853 28.0536 26.6663 27.3753 26.6663 26.668V10.6673M18.6659 2.66699L26.6663 10.6673M18.6659 2.66699V10.6673H26.6663M21.3327 17.3343H10.6656M21.3327 22.6678H10.6656M13.3324 12.0007H10.6656"
                      stroke="#2F3082" strokeOpacity="0.2" strokeWidth="2.2501" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img
                          src="images/file-icon.svg"
                          alt="file_attachment_icon"
                        />
                        <span className="w-[90px] overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                          {message.attachment.name}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="rounded">
                      <img
                        src={message.attachment.url}
                        alt={message.attachment.name}
                        className="w-[131px] h-[131px] object-cover"
                      />
                    </div>
                  )}
                </div>
                <div className="w-full text-right justify-end px-3">
                  <span className="text-xs text-bubble-bot-footer sa">
                    {displayCalendarTime(message.attachment.createdAt)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex justify-end my-2">
                <div className="py-2 px-[10px] flex flex-row justify-center items-center border border-[#98A2B3] gap-1 mr-4 pt-2 rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.0007 5V15C15.0007 15.9205 14.2545 16.6667 13.334 16.6667H6.66732C5.74684 16.6667 5.00065 15.9205 5.00065 15V5M12.5007 5V4.16667C12.5007 3.24619 11.7545 2.5 10.834 2.5H9.16732C8.24684 2.5 7.50065 3.24619 7.50065 4.16667V5M3.33398 5H16.6673M8.33398 8.33333V13.3333M11.6673 8.33333V13.3333"
                      stroke="#98A2B3"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-xs text-[#72777A] font-medium">
                    This file was deleted.
                  </span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    ));
  };

  const showPreview = (imageSrc: string) => {
    setImagePreviewSource(imageSrc);
  };

  const chatboxAnchor = "chatboxAnchor";

  useEffect(() => {

    if(location.state && location.state.anchorId){
      setTimeout(() => {
        scrollToElement(`${location.state.anchorId}`, "smooth");
      }, 500);

      return;
    }

    setTimeout(() => {
      scrollToElement(chatboxAnchor, "smooth");
    }, 500);

    setSending(false);
    // setTypeable(true);
    // setResponds(true);

    const uuid = uuidv4();
    if (activeConversation === null) {
      setActiveConversation(uuid);
    } else {
      fetchMessages(activeConversation);
    }

    return () => {
      setAttachment(null);
      setAttachmentPreview(null);
    };
  }, [activeConversation]);

  return (
    <>
      <ScreenHeader type="primary">

        <div className="flex flex-row items-center gap-[10px] h-[32px]">
          <>
            <p className="font-semibold text-base text-white whitespace-nowrap text-ellipsis overflow-hidden w-full">
              {messages[0]?.content
                ? messages[0]?.content
                : messages[0]?.attachment?.name}
            </p>
          </>

        <div className="flex flex-row justify-between">
          <NotificationBell />
        </div>
        </div>
      </ScreenHeader>
      {messages.length ? (
        <>
          {messages.length > 0 && (
            <div className="w-full text-center my-1">
              <span className="text-xs text-bubble-bot-footer">
                {/* {initialMessage &&
                  displayCalendarTime(initialMessage.createdAt)} */}
                {displayCalendarTime(messages[0].createdAt)}
              </span>
            </div>
          )}
          <div className="h-full scrollbar-thin scrollbar-thumb-gray-900">
            <div className="w-full" id="messages">
              {renderMessages()}
              <ChatTypingIndicator />
              <div className="h-4" id="chatboxAnchor"></div>
            </div>
          </div>
        </>
      ) : (
        <ChatEmpty />
      )}
      {showChatStop && (
        <div
          id="chatStopAnchor"
          className="bg-transparent w-full flex justify-center items-center"
        >
          <ChatStop />
        </div>
      )}
      <div className="flex flex-row gap-2 px-4 pt-4">
        <div className="relative flex flex-row w-full">
          <Controller
            control={control}
            name="content"
            render={({ field: { onChange, value } }) => (
              <div className="form-control w-full flex">
                <input
                  type="text"
                  id="chat-input-field"
                  placeholder="Send a message"
                  maxLength={2000}
                  value={value}
                  disabled={!typeable}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                  className={`input w-full border border-[#98A2B3] pr-8 rounded-[14px] text-base focus-visible:outline-0 ${
                    errors.content ? "input-error" : "input-ghost"
                  }`}
                />
                {errors.content && (
                  <label className="text-[#D92D20] text-xs pt-2">
                    {errors.content.message}
                  </label>
                )}
              </div>
            )}
          />
          <FileUploadForm showPreview={showPreview} />
        </div>
        <div
          className="btn btn-square bg-primary-100 hover:bg-primary-100 rounded-2xl"
          onClick={handleSubmit(sendMessage)}
        >
          {responds ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M24.2471 3.75385C24.129 3.63632 23.9798 3.55494 23.8171 3.51929C23.6543 3.48364 23.4848 3.4952 23.3284 3.5526L4.07836 10.5526C3.91234 10.6156 3.76941 10.7276 3.66855 10.8737C3.56769 11.0198 3.51367 11.1932 3.51367 11.3707C3.51367 11.5483 3.56769 11.7216 3.66855 11.8678C3.76941 12.0139 3.91234 12.1259 4.07836 12.1889L10.9816 14.9453C11.3534 15.0938 11.778 15.0062 12.0606 14.7226L16.5257 10.2434C16.866 9.9021 17.4187 9.90166 17.7595 10.2425C18.0999 10.5829 18.0999 11.1348 17.7595 11.4752L13.2686 15.9661C12.9856 16.2491 12.8986 16.6735 13.0474 17.045L15.8121 23.9489C15.8769 24.1117 15.9892 24.2512 16.1343 24.3495C16.2794 24.4478 16.4506 24.5003 16.6259 24.5001C16.8027 24.4965 16.9743 24.4394 17.118 24.3363C17.2617 24.2332 17.3708 24.0889 17.4309 23.9226L24.4309 4.6726C24.4905 4.5178 24.5049 4.34923 24.4723 4.18656C24.4398 4.02389 24.3617 3.87382 24.2471 3.75385Z"
                  fill="white"
                />
              </svg>
            </>
          ) : (
            <span className="loading loading-spinner text-white"></span>
          )}
        </div>
      </div>
      <div className="w-full text-left px-4 pb-4 pt-[6px] relative">
        <span
          className={`text-[10px] ${
            contentLength <= 2000 ? "text-bubble-bot-footer" : "text-red-500"
          }`}
        >
          {contentLength}/2000
        </span>
      </div>
      <ChatAttachments imageSrc={imagePreviewSource} />
          
    </>
  );
};

export default Chat;
