import { useEffect, useState } from "react";
import Logo from "../../Logo";
import CloseButton from "../../../assets/icons/cross-small.svg";
import SafariSlide from "./SafariSlide";
import ChromeSlide from "./ChromeSlide";

// import { useNavigate } from 'react-router-dom';


const styleDiV = {
    boxShadow: "0 24px 48px -12px rgba(16, 24, 40, 0.18)",
}

const logoStyle = {
    width: "40px",
    height: "40px",
    boxShadow: "0 1px 3px 0px rgba(10, 18, 28, 0.1), 0 1px 2px 0px rgba(16, 24, 40, 0.06)",
}
interface IOSPrompt {
    browser: string
    prompt_trigger: boolean
}
const AddPrompt: React.FC<IOSPrompt> = ({ browser, prompt_trigger }) => {
    const [showPrompt, setShowPrompt] = useState<boolean>(false);
    const [showSlide, setShowSlide] = useState<boolean>(false);
  
    useEffect(() => {
        if (prompt_trigger) {
            setShowPrompt(true)
        }
    }, [prompt_trigger])
    return (
        <div className={`w-full sm:max-w-[375px] m-auto justify-center items-center relative ${showPrompt ? '' : 'overflow-y-hidden'}`}>
            <div className={`shadow-xl py-6 px-3  ${showSlide? 'pt-4 pb-3':'pt-3'} bg-white rounded-lg absolute z-[999] ${showPrompt ? 'bottom-[20px]' : 'top-[100%]'} w-[95%] left-[2.5%] flex flex-col`} style={styleDiV}>
                <div className={`z-10 ${showSlide? 'absolute right-[15px] top-[17px]':'flex justify-end'}`} onClick={() => setShowPrompt(false)}>
                    <img src={CloseButton} />
                </div>
                {!showSlide &&
                    <div className="flex flex-col w-full">
                        <div className="flex ">
                            <div className="w-1/5">
                                <Logo className="rounded-[5px]" style={logoStyle} alt="MedSnap | Empower your health conversations with MedSnap" />
                            </div>
                            <div className="flex-1">
                                <p className="font-bold text-[14px] m-0 p-0 leading-[16px]">Add MedSnap to your Home Screen</p>
                                <p className=" text-[10px] m-0 p-0 leading-[14px]">Empower your health conversations with MedSnap on your home screen.</p>

                            </div>
                        </div>
                        <button className="btn mt-[10px] min-h-[30px] h-[30px] text-[12px] btn-primary rounded-full text-white bg-[#2F3082] hover:bg-[#2F3082]" onClick={() => { setShowSlide(true) }} >
                            Add to Home Screen
                        </button>
                    </div>
                }
                {
                    showSlide && (browser === 'isSafari' || browser === 'isMobileSafari') && <SafariSlide />

                }
                {
                    showSlide && (browser === 'isChrome') && <ChromeSlide />

                }
            </div>
        </div >
    )
}

export default AddPrompt