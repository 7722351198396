import React from "react";

type ChatIconOutlineProps = {
    isActive?: boolean
}

const ChatIconOutline: React.FC<ChatIconOutlineProps> = ({ isActive}) => {
    return (
        <>
         {
            isActive ? <svg width="22" height="21" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.25 1.375C20.0094 1.375 20.625 1.99061 20.625 2.75V13.75C20.625 14.5094 20.0094 15.125 19.25 15.125H6.06954C5.3402 15.125 4.64072 15.4147 4.125 15.9305L1.375 18.6805V2.75C1.375 1.99061 1.99061 1.375 2.75 1.375H19.25ZM2.75 0C1.23122 0 0 1.23122 0 2.75V20.3402C0 20.9527 0.740534 21.2595 1.17363 20.8264L5.09727 16.9027C5.35513 16.6449 5.70487 16.5 6.06954 16.5H19.25C20.7688 16.5 22 15.2688 22 13.75V2.75C22 1.23122 20.7688 0 19.25 0H2.75Z" fill="white"/>
            </svg> :
            <svg width="22" height="21" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.75 0C1.23122 0 0 1.23122 0 2.75V20.3402C0 20.9527 0.740534 21.2595 1.17363 20.8264L5.09727 16.9027C5.35513 16.6449 5.70487 16.5 6.06954 16.5H19.25C20.7688 16.5 22 15.2688 22 13.75V2.75C22 1.23122 20.7688 0 19.25 0H2.75Z" fill="white"/>
            </svg>                        
         }
        </>
    )
}

ChatIconOutline.defaultProps = {
    isActive: true
}

export default ChatIconOutline;