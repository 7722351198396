import React from "react";
import { ReactNode } from "react";

type ProfileCardProps = {
    title: string;
    children: ReactNode
}

const ProfileCard: React.FC<ProfileCardProps> = ({ title, children }) => {
    return (
        <>
            <div 
            style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10'}}
            className="p-6 bg-white shadow-[] rounded-[10px]">
                <label className="font-semibold text-sm">{ title} </label>
                {children}
            </div>
        </>
    )
}

export default ProfileCard;