import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import GetStarted from "../pages/GetStarted";
import Welcome from "../pages/Welcome";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import Pin from "../pages/Pin";
import Verification from "../pages/Verification";
import SetPin from "../pages/SetPin";
import UpdatedPin from "../pages/UpdatedPin";
import useUserStore from "../store/useUserStore";
import GuestSignIn from "../pages/GuestSignIn";
import Confirmation from "../pages/Confirmation";

const AuthLayout: React.FC = () => {
  const { user } = useUserStore();

  if (user) {
    return <Navigate to="/profile" replace />;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<GetStarted />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/signin" element={<SignIn />} />
          {/* <Route path="/guest-signin" element={<GuestSignIn />} /> */}
          <Route path="/pin" element={<Pin />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/set-pin" element={<SetPin />} />
          <Route path="/updated-pin" element={<UpdatedPin />} />
          <Route path="/confirmation" element={<Confirmation />} />
          {/* Catch-all route for non-matching paths */}
          <Route path="/*" element={<Navigate to="/signin" replace />} />
        </Routes>
      </Router>
    </>
  );
};

export default AuthLayout;
