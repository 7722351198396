export const borderVariant: Record<string, string> = {
    success: "border-[#027A48]",
    danger : "border-[#B42318]",
    error  : "border-[#B42318]",
    default: "",
  };
  
export const bgVariant: Record<string, string> = {
    success: "bg-[#F6FEF9]",
    danger : "bg-[#FFFBFA]",
    error  : "bg-[#FFFBFA]",
    default: "",
  };
  
export const textVariant: Record<string, string> = {
    success: "text-[#039855]",
    danger : "text-[#B42318]",
    error  : "text-[#B42318]",
    default: "",
  };

export const ToastOptions = {
  'SUCCESS' : {
    borderRadius: '8px',
    border: '#027A48',
    background: '#F6FEF9',
    color: '#039855',
    fontWeight: '500',
    fontSize: '14px',
    width: '100%'
  },
  'ERROR' : {
    borderRadius: '8px',
    border: '#B42318',
    background: '#FFFBFA',
    color: '#D92D20',
    fontWeight: '500',
    fontSize: '14px',
    width: '100%'
  }
};