import React from "react";
import useMessageStore from "../store/useMessageStore";

const ChatTypingIndicator: React.FC = () => {
  const { responds } = useMessageStore();

  return (
    <>
      {!responds && (
        <div className="ml-4">
          <div className="chat chat-start" id="typing-indicator">
            <div className="bg-chat-bot !rounded-tl-none rounded-br-[24px] !rounded-bl-[24px] rounded-tr-[24px] before:!content-none p-4 font-medium">
              <p className="chat-header text-pretty">
                <img
                  src="/typing-indicator.gif"
                  alt="typing"
                  className="w-8 h-8"
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatTypingIndicator;
