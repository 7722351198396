import React, { useRef, useState, useEffect } from "react";
import { 
TransformWrapper, 
TransformComponent, 
ReactZoomPanPinchRef 
} from "react-zoom-pan-pinch";
import { useLocation, useNavigate } from "react-router-dom";
import useMessagesAPI from "../hooks/useMessagesAPI";
import AppButton from "../components/AppButton"
import  toast, { Toaster } from "react-hot-toast";
import { ToastOptions } from "../helpers/constants";
import ToastWrapper from "../components/ToastWrapper"
import { ChatMessage, ErrorMessage } from "../helpers/enums";


const ChatImageView: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { deleteAttachment, fetchMessageAttachments } = useMessagesAPI();
    const deleteModalRef = useRef<HTMLDialogElement>(null); 
    const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
    const [filename, setFilename] = useState<string>("");
    const [scale, setScale] = useState<string>('0%');

    const handleZoom = (event: ReactZoomPanPinchRef) => {
        const { scale } = event.state;
        const increasePercentage = ((scale - 1) / 1) * 100;
        setScale(`${parseFloat(increasePercentage.toFixed(2))}%`);
    }

    const zoomIn = () => {
        if (transformComponentRef.current) {
          transformComponentRef.current.zoomIn();
        }
    };

    const zoomOut = () => {
        if(transformComponentRef.current){
            transformComponentRef.current.zoomOut();
        }
    }

    const extractFilenameFromUrl = (url: string) => {
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      const parts = pathname.split("/");
      const filenameWithExtension = parts[parts.length - 1];
    
      return filenameWithExtension;
    };

    const openModal = () => {
      if (deleteModalRef.current) {
           deleteModalRef.current.showModal();
       }
    };
   
    const closeModal = () => {
       if (deleteModalRef.current) {
         deleteModalRef.current.close();
       }
    };

    const deleteFile = async () => {
      closeModal();
      try{
        await deleteAttachment(location.state.messageId);
        toast.success((t) => (
            <ToastWrapper message={ChatMessage.ATTACHMENT_DELETE_SUCCESS} toastId={t.id} />
          ),{
             id: 'attachment-delete-success',
             style : ToastOptions.SUCCESS
          });
      }catch(error){
        toast.error((t) => (
            <ToastWrapper message={ErrorMessage.NETWORK_ERROR} toastId={t.id} />
          ),{
             id: 'attachment-delete-error',
             style : ToastOptions.ERROR
          });
      }finally{
        fetchMessageAttachments();
      }
    }

    useEffect(() => {
        setFilename(extractFilenameFromUrl(location.state.url));
    }, [])
          
    return (
      <>
      <Toaster />
        <div className="p-4 bg-black flex flex-row justify-between  text-white relative z-[2]">
              <div className="flex flex-row">
                 <button onClick={() => navigate('/files')} className="flex flex-row justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M4 12L20 12M4 12L10 6M4 12L10 18"
                      stroke="white" strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
                  </svg>
                 </button>
                 <div className="font-bold text-base  whitespace-nowrap text-ellipsis overflow-hidden w-[240px]">
                    <label>{ filename }</label>
                 </div>
              </div>
              <div className="dropdown dropdown-end">
                <div tabIndex={0} className="pb-[25px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355
                    14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875
                    3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z" fill="white"/>
                    </svg>
                </div>
                <ul tabIndex={0} className="dropdown-content z-[1] menu p-0 bg-base-100 rounded-md mr-2 w-[199px]">
                  <li className="px-4 py-2">
                    <div className="text-sm flex flex-row p-0 justify-between text-black" >
                      Download
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M20 14V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14M12 15L12 3M12 15L8 11M12 15L16 11" 
                        stroke="#323643" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                  </li>
                  <hr/>
                  <li className="px-4 py-2">
                    <div 
                    onClick={() => openModal()}
                    className="text-sm flex flex-row justify-between p-0 hover:bg-transparent text-danger active:bg-none">
                    Delete
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M18 6V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6M4 6H20M10 10V16M14 10V16" stroke="#D92D20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
        <div className="w-full sm:w-[375px] z-[1] absolute h-full bg-black flex flex-col justify-center">
            <div className="flex flex-col justify-center">
                <TransformWrapper 
                  ref={transformComponentRef}
                  onTransformed={(state) => handleZoom(state)}
                  maxScale={2}
                >
                  <div >
                     <TransformComponent >
                        <img src={location.state.url} alt="result_image" />
                     </TransformComponent>
                   </div>
                </TransformWrapper>
            <div className="h-full flex flex-col justify-center items-center pt-8">
                <div className="bg-[#323643] p-[10px] text-white w-32 rounded-[5px] flex flex-row gap-[10px] justify-center">
                    <button onClick={() => zoomOut()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.4997 17.4997L13.8748 13.8748M6.66659 9.16654H11.6665M15.8331
                            9.16654C15.8331 12.8484 12.8484 15.8331 9.16654 15.8331C5.48471 15.8331 2.5 12.8484 2.5 9.16654C2.5 5.48471 5.48471 2.5 
                            9.16654 2.5C12.8484 2.5 15.8331 5.48471 15.8331 9.16654Z" 
                            stroke="white" strokeWidth="1.66664" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <span>{ scale }</span>
                    <button onClick={() => zoomIn()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.4997 17.4997L13.8748 13.8748M9.16654 6.66659V11.6665M6.66659
                            9.16654H11.6665M15.8331 9.16654C15.8331 12.8484
                            12.8484 15.8331 9.16654 15.8331C5.48471 15.8331
                            2.5 12.8484 2.5 9.16654C2.5 5.48471 5.48471 2.5 9.16654 2.5C12.8484
                            2.5 15.8331 5.48471 15.8331 9.16654Z"
                            stroke="white" strokeWidth="1.66664" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
      </div>
        <dialog
          id="deactivateAccountModal"
          className="modal max-w-[327px] m-auto"
          ref={deleteModalRef}
        >
            <div className="modal-box flex flex-col items-center">
              <img src="images/modal-danger-icon.svg" />
              <h3 className="font-bold text-base pt-[20px]">Delete File?</h3>
              <p className="py-4 text-sm text-center">
                Are you sure you want to delete this file?
              </p>
                <div className="flex flex-col w-full gap-3">
                <AppButton
                  variant="danger"
                  type="button"
                  onClick={() => deleteFile()}
                  className="focus-visible:outline-0"
                >
                  Yes, Delete File
                </AppButton>
                <AppButton 
                  type="button"
                  onClick={() => closeModal()}
                  className="focus-visible:outline-0"
                >
                  Cancel
                </AppButton>
                </div>
            </div>
        </dialog>
    </>
  )
}

export default ChatImageView;