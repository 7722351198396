import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from 'dayjs/plugin/customParseFormat';


dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat)

export const getCalendarTime = (datetime: Date, dateFormat?: string) => {

    const parsedDate = dayjs(datetime);
    const localDate = parsedDate.utc().utcOffset("+08:00");

    const calendarFormat = {
      sameDay: "[Today at] h:mm A",
      nextDay: "[Tomorrow at] h:mm A",
      nextWeek: "dddd [at] h:mm A",
      lastDay: "[Yesterday at] h:mm A",
      lastWeek: "[Last] dddd [at] h:mm A",
      sameElse: "DD MMM YYYY, h:mm A",
    };

    if (!dateFormat) return localDate.calendar(null, calendarFormat);

    return dayjs(datetime).format(dateFormat);
};

