import { useState } from "react";

type ToastOptions = {
  visible: boolean;
  variant?: string;
  message?: string;
  custom?: string;
};

const useToast = () => {
  const [notify, setNotify] = useState({
    variant: "default",
    visible: false,
    message: "",
    custom: ""
  });

  const showToast = (options: ToastOptions) => {
    // If the toast is already visible or the new options have visible set to false, hide immediately
    if (notify.visible || !options.visible) {
      setNotify((previousValue) => ({
        ...previousValue,
        visible: false,
        message: "",
        custom: ""
      }));
      return;
    }

    setNotify((previousValue) => ({
        ...previousValue,
        visible: true,
        message: options.message || "",
        variant: options.variant || "default",
        custom: options.custom || ""
      }));

    const timeout = setTimeout(() => {
      setNotify((previousValue) => ({
        ...previousValue,
        visible: false,
        message: "",
        custom: ""
      }));
    }, 5000);

    // Clear the timeout immediately if visible becomes false
    if (!options.visible) {
      clearTimeout(timeout);
    }

    return () => clearTimeout(timeout);
  };

  return { notify, showToast };
};

export default useToast;
