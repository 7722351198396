import * as yup from "yup";

export const formSchema = {
    firstname: yup
    .string()
    .required("This field is required")
    .min(2, "First Name must be at least 2 characters")
    .max(20, "First Name cannot exceed 20 characters")
    .test("valid-firstname", "Invalid First Name", function (value) {

        const hasSpecialCharacters = /[^a-zA-Z\s]/.test(value);
        const hasNumbers = /\d/.test(value);

        if (hasSpecialCharacters || hasNumbers) {
          if (hasNumbers) {

            throw new yup.ValidationError(
              "No numbers in your First Name",
              value,
              "firstname"
            );
          }
          throw new yup.ValidationError(
            "No special characters in your First Name",
            value,
            "firstname"
          );
        }
        return true;
      }),
    lastname: yup
    .string()
    .required("This field is required")
    .min(2, "Last Name must be at least 2 characters")
    .max(20, "Last Name cannot exceed 20 characters")
    .test("valid-lastname", "Invalid Last Name", function (value) {
        const hasSpecialCharacters = /[^a-zA-Z\s]/.test(value);
        const hasNumbers = /\d/.test(value);

        if (hasSpecialCharacters || hasNumbers) {
          if (hasNumbers) {

            throw new yup.ValidationError(
              "No numbers in your Last Name",
              value,
              "lastname"
            );
          }
          throw new yup.ValidationError(
            "No special characters in your Last Name",
            value,
            "lastname"
          );
        }
        return true;
      }),
    birthdate: yup
    .string()
    .required("This field is required")
    .test(
        "is-at-least-18",
        "Must be at least 18 years old",
        function (value) {
          const currentDate = new Date();
          const birthdate = new Date(value);
          let age = currentDate.getFullYear() - birthdate.getFullYear();

          if (
            currentDate.getMonth() < birthdate.getMonth() ||
            (currentDate.getMonth() === birthdate.getMonth() &&
              currentDate.getDate() < birthdate.getDate())
          ) {
            age--;
          }

          return age >= 18;
        }
      ),
    gender: yup.string().required("This field is required"),
    email: yup
    .string()
    .required("This field is required")
    .email("Please enter a valid email address")
}