import { create, StateCreator } from "zustand";
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';
import { FCMNotification, AppNotification } from "../helpers/notifications"


type Store = {
    unReadNotifications: FCMNotification[] | null;
    readNotifications: AppNotification[] | null;
    activeNotification: boolean;
    setPushNotifications: (notif: Notification[] | null) => void;
    setReadNotifications: (notif: AppNotification[] | null) => void;
    setActiveNotification: (isActive: boolean) => void;
};

type Persist = (
    config: StateCreator<Store>,
    options: PersistOptions<Store>
) => StateCreator<Store>


const notificationStore = create<Store, []>(
    (persist as Persist)(
        (set): Store => ({
            unReadNotifications: null,
            readNotifications: null,
            activeNotification: false,
            setPushNotifications: (unReadNotif: Notification[] | null) => {

                if(unReadNotif !== null || unReadNotif){

                    const unReadNotifs = unReadNotif.map((item: Notification) => {

                        return {
                            title: item.title,
                            body: item.body
                        }   
                    });

                    set({ unReadNotifications: unReadNotifs });

                    return;
                }

                set({ unReadNotifications: null });
            },
            setReadNotifications: (unreadNotif) => {

                set({ readNotifications: unreadNotif });
                set({ unReadNotifications: null });
            },
            setActiveNotification: (isActive: boolean) => {
                set({ activeNotification: isActive});
            }
        }),
        {
            name: 'notificationStore',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

export default notificationStore;