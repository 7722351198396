import axios from "axios";

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('userStore')
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
