import React, { useEffect } from "react";
import useMessageStore from "../store/useMessageStore";

type ChatAttachmentsProps = {
  imageSrc: string
}

const ChatAttachments: React.FC<ChatAttachmentsProps> = ({imageSrc}) => {
  const { 
    attachment, 
    attachmentPreview, 
    setAttachmentPreview, 
    setAttachment} = useMessageStore();

  const handleRemoveChatAttachment = async () => {
    setAttachmentPreview(null);
    setAttachment(null);
  };

  return (
    <>

      {attachmentPreview && (
     <div className="p-4">
            <div className="indicator border border-[#E9EDFB]">
             {
              attachment && (
                <div className="indicator-item indicator-top">
                  <button className="btn btn-circle btn-outline btn-xs bg-black" onClick={() => handleRemoveChatAttachment()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                  </button>
                </div>
              )
             }  
              <div className="w-[152px] h-[148px] bg-[#F5F5F5]">
              { !attachment && (
                  <div className="bg-slate-300 flex justify-center items-center w-[152px] h-[148px] absolute opacity-[0.8]">
                    <span className="loading loading-spinner loading-xs"></span>
                  </div>
                )
              }
              <div className="bg-[#F5F5F5] p-[10px] m-auto">
                {
                  attachment?.mimetype === "application/pdf" ? 
                  <div className="h-[95px] w-32 flex justify-center items-center bg-[#F5F3FB]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                    <path d="M19.5507 3.16504H8.88358C8.1763 3.16504 7.498 3.446 6.99788 3.94612C6.49776 4.44624 6.2168 5.12455 6.2168 5.83182V27.1661C6.2168 27.8733 6.49776 28.5516 6.99788
                    29.0518C7.498 29.5519 8.1763 29.8328 8.88358 29.8328H24.8843C25.5915 29.8328 26.2698 29.5519 26.77 29.0518C27.2701 28.5516 27.551 27.8733 27.551 27.1661V11.1654M19.5507
                      3.16504L27.551 11.1654M19.5507 3.16504V11.1654H27.551M22.2175 17.8323H11.5504M22.2175 23.1659H11.5504M14.2171 12.4988H11.5504" stroke="#2F3082" strokeWidth="2.2501" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  </div> :
                  <div className="w-32 h-[95px] overflow-hidden m-auto">
                     <img src={imageSrc} />
                  </div>
                }
                <div className="flex flex-row items-center gap-2">
                  <img src={attachment?.mimetype === "application/pdf" ? 'images/file-icon.svg' : '/images/result-img-icon.svg'} />
                  <label className="text-sm overflow-hidden text-ellipsis whitespace-nowrap">{attachmentPreview.filename}</label>
                </div>
              </div>
              </div>
            </div>
            </div>
      )}

    </>
  );
};

export default ChatAttachments;
