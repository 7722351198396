import React from "react";

type ConsultIconOutlineProp = {
    isActive?: boolean 
}

const ConsultIconOutline: React.FC<ConsultIconOutlineProp> = ({ isActive}) => {
    return (
    <>
        {
          isActive ? <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.125 14C18.125 16.2782 16.2782 18.125 14 18.125C11.7218 18.125 9.875 16.2782 9.875 14C9.875 11.7218 11.7218 9.875 14 9.875C16.2782 9.875 18.125 11.7218 18.125 14Z" fill="white"/>
          <path d="M22.25 22.25V9.1875L16.0625 3H8.5C6.98122 3 5.75 4.23122 5.75 5.75V22.25C5.75 23.7688 6.98122 25 8.5 25H19.5C21.0188 25 22.25 23.7688 22.25 22.25ZM16.0625 7.125C16.0625 8.26409 16.9859 9.1875 18.125 9.1875H20.875V21.9135C20.875 21.9135 19.5 19.5 14 19.5C8.5 19.5 7.125 21.9137 7.125 21.9137V5.75C7.125 4.99061 7.74061 4.375 8.5 4.375H16.0625V7.125Z" fill="white"/>
          </svg>
          : 
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.7777 3H8.5C6.98122 3 5.75 4.23122 5.75 5.75V22.25C5.75 23.7688 6.98122 25 8.5 25H19.5C21.0188 25 22.25 23.7688 22.25 22.25V9.47227C22.25 9.1076 22.1051 8.75786 21.8473 8.5L16.75 3.40273C16.4921 3.14487 16.1424 3 15.7777 3ZM16.0625 7.8125V5.0625L20.1875 9.1875H17.4375C16.6781 9.1875 16.0625 8.57189 16.0625 7.8125ZM18.125 14C18.125 16.2782 16.2782 18.125 14 18.125C11.7218 18.125 9.875 16.2782 9.875 14C9.875 11.7218 11.7218 9.875 14 9.875C16.2782 9.875 18.125 11.7218 18.125 14ZM20.875 21.9135V22.25C20.875 23.0094 20.2594 23.625 19.5 23.625H8.5C7.74061 23.625 7.125 23.0094 7.125 22.25V21.9137C7.125 21.9137 8.5 19.5 14 19.5C19.5 19.5 20.875 21.9135 20.875 21.9135Z" fill="white"/>
          </svg>   
        }
    </>
    )
}

ConsultIconOutline.defaultProps = {
    isActive: true
}

export default ConsultIconOutline;