import React from "react";
import ConsultsHeader from "../components/ConsultsHeader";
import NotificationBell from "../components/NotificationBell";
import useMessageStore from "../store/useMessageStore";
import useHandleGuestEmail from "../hooks/useHandleGuestEmail";

const Consults: React.FC = () => {

  const { webSocketConnection } = useMessageStore();
  useHandleGuestEmail(webSocketConnection);

  return (
    <>
      <div
        style={{
          backgroundImage: 'url("images/consult_background_header.png")',
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
        }}
        className="p-4 bg-[#324692] flex flex-row justify-between"
      >
        <div className="flex flex-row gap-4 w-full items-center">
          <ConsultsHeader />
          <NotificationBell />
        </div>
      </div>
      <div className="h-full px-6 py-6 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400">
        <div className="flex flex-row items-center gap-[10px]">
          <img src="images/consult-circle-icon.svg" alt="consult" />
          <label className="font-bold text-lg">Consult</label>
        </div>

        <img src="images/consult_background_empty.png" alt="consult empty" />
      </div>
    </>
  );
};

export default Consults;
