import React from "react";
import { AppNotification } from "../helpers/notifications";
import { getCalendarTime } from "../helpers/dateFormatter";

type ReadCampaignProps = {
    campaign: AppNotification
}

const ReadCampaigns: React.FC<ReadCampaignProps> = ({ campaign }) => {
    return (
        <div className="rounded-[10px] overflow-hidden bg-white">
        {
            campaign.image && <img className="w-full" src={campaign.image} alt="Card Image" />
        }
        <div className="flex justify-between items-center px-6 py-4">
            {
               campaign.category &&  <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-[10px] font-bold text-gray-700 mr-2">
                    {
                        campaign.category
                    }
                </span>
            }
            <span className="ml-auto text-[10px]">{ getCalendarTime(new Date(campaign.timestamp || 0)) } </span>
        </div>
        <div className="px-6 pb-4">
            <div className="flex items-center gap-2">
            <div className="font-bold text-base">
               { campaign.title}
            </div>
            </div>
            <p className="font-medium text-gray-700 text-sm mt-2">
               { campaign.body }
            </p>
        </div>
        {
            campaign.click_action && <div className="px-6 py-4 border-t border-[#D2D6E4]">
            <div className="flex flex-row justify-between items-center">
                <a href={campaign.click_action} target="_blank" rel="noopener noreferrer" className="font-semibold text-base cursor-pointer">Learn More</a>
                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.33398 9.16659L5.50065 4.99992L1.33399 0.833252" stroke="#8D919F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                 </svg>
            </div>
        </div>
        }
      </div>
    )
}

export default ReadCampaigns;