import { useState } from "react";
import dayjs from "dayjs";
import useMessageStore from "../store/useMessageStore";
import useUserStore from "../store/useUserStore";
import { scrollToElement } from "../helpers/dom";

const useWebSocket = () => {
  const {
    channel,
    activeConversation,
    pingInterval,
    generatingResponse,
    setGeneratingResponse,
    setWebSocketConnection,
    addMessage,
    updateLastBotMessage,
    setTypeable,
    setResponds,
    setSending,
    setChunk,
    setPingInterval,
  } = useMessageStore();
  const { user } = useUserStore();

  const connect = async (URL: string) => {
    const connection = new WebSocket(URL);
    setWebSocketConnection(connection);

    setPingInterval(
      window.setInterval(() => {
        if (connection.OPEN) {
          connection.send(
            JSON.stringify({
              type: "ping",
              payload: dayjs().toISOString(),
            })
          );
        }
      }, import.meta.env.VITE_WS_PING_INTERVAL)
    );

    connection.onopen = () => {
      console.log("Connection to the server established");
    };

    connection.onmessage = (message) => {
     scrollToElement("typing-indicator", "smooth");
      const result = JSON.parse(message.data);
      if (result.type === "answer") {
        const payload = JSON.parse(result.payload);
        if (payload["channel"] === channel) {
          if (payload["state"] === "start") {
            addMessage([
              {
                role: "Bot",
                content: "",
                channel: payload.channel,
                conversation: activeConversation,
                attachment: null,
                createdAt: new Date(),
              },
            ]);
          } else if (payload["state"] === "end") {
            setTypeable(true);
            setGeneratingResponse(false);
            //scrollToElement("chatboxAnchor", "instant");
          } else {
            

              setResponds(true);
              setSending(false);

              setChunk(payload["content"]);
              updateLastBotMessage();
              // scrollToElement("chatboxAnchor", "instant");
            
          }
        } else {
          console.log(
            `Wrong channel: Payload is ${payload["channel"]} Active is ${channel}`
          );
        }
      } else if (result.type === "pong") {
        // console.log(result.payload);
      }
    };

    connection.onclose = () => {
      console.log("Connection to the server closed");
      window.clearInterval(pingInterval);
      if (user) {
        connect(URL);
      }
    };
  };

  return { connect };
};

export default useWebSocket;
