import { useEffect, useState } from "react";
import AosPrompt from "./aos";
import IosPrompt from "./ios";
import { useDeviceSelectors } from 'react-device-detect';
import usePromptStore from "../../store/usePromptStore";

const Prompt: React.FC = () => {
    const { prompt, setTries } = usePromptStore();

    const [selectors] = useDeviceSelectors(window.navigator.userAgent)
    const { isChrome, isFirefox, isSafari, isOpera, isEdge, isMobileSafari } = selectors
    const [platform, setPlatform] = useState('');
    const [browser, setBrowser] = useState('');
    const [promptTrigger, setPromptTrigger] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

        if (isStandalone || isFirefox || isEdge || isOpera) {
            setPlatform('')
        }
        else if (/iPad|iPhone|iPod/.test(userAgent)) {
            if (isChrome) { setBrowser('isChrome') }
            else if (isFirefox) { setBrowser('isFirefox') }
            else if (isSafari) { setBrowser('isSafari') }
            else if (isOpera) { setBrowser('isOpera') }
            else if (isEdge) { setBrowser('isEdge') }
            else if (isMobileSafari) { setBrowser('isMobileSafari') }
            else {
                setBrowser('unknown')
            }
            setPlatform('ios');
        } else if (/Android/.test(userAgent)) {
            setPlatform('aos');
        } else {

            setBrowser(userAgent)
            setPlatform('web');
            // setBrowser('isChrome');
            // setPlatform('ios');
        }
    }, []);

    useEffect(() => {
        // console.log(prompt);
        if ((prompt?.tries === 0) && (promptTrigger === false)) {
            triggerStart()
        }
    }, []);

    const timeInterval = 60 * 60 * 1000; // 1hr minutes
    useEffect(() => {
        const startCounter = setInterval(() => {
            setPromptTrigger(false);
            if (prompt?.tries < 3) {
                triggerStart();
            } else {
                clearInterval(startCounter);
            }
        }, timeInterval);

        return () => {
            clearInterval(startCounter); // Cleanup the interval on component unmount
        };
    }, [prompt?.tries]);

    useEffect(() => {
        if (promptTrigger) {
            setTries(prompt.tries + 1);
            setPromptTrigger(false);
        }
    }, [promptTrigger]);

    const triggerStart = () => {
        
        setPromptTrigger(true);
    };


    return (<>
        {(
            () => {
                switch (platform) {
                    case 'aos': return <AosPrompt />
                    case 'ios': return <IosPrompt prompt_trigger={promptTrigger} browser={browser} />
                    default: return <></>
                    // default: return <IosPrompt prompt_trigger={promptTrigger} browser={browser} />
                }
            }
        )()}
    </>)
}

export default Prompt