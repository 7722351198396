import React from "react";
import Consults from "../pages/Consults";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AppNavigation from "../components/AppNavigation";
import Chat from "../pages/Chat";
import History from "../pages/History";
import Results from "../pages/Results";
import Profile from "../pages/Profile";
import Faq from "../pages/Faq";
import ChatImageView from "../pages/ChatImageView";
import ChatFileView from "../pages/ChatFileView";
import NotificationsPage from "../pages/NotificationsView";

const UserLayout: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppNavigation />}>
            <Route path="/consult" element={<Consults />} />
            <Route path="/files" element={<Results />} />
            <Route index path="/chat" element={<Chat />} />
            <Route path="/history" element={<History />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/attachment-image-view" element={<ChatImageView />} />
            <Route path="/attachment-file-view" element={<ChatFileView />} />
            <Route path="/notifications" element={<NotificationsPage />} />

            <Route index element={<Navigate to="/chat" replace />} />
            <Route path="*" element={<Navigate to="/chat" replace />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default UserLayout;
