import React, { useEffect, useState } from "react";
// import { PwaPrompt } from 'react-ios-pwa-prompt-ts'
import AuthLayout from "./layouts/AuthLayout";
import UserLayout from "./layouts/UserLayout";
import useUserStore from "./store/useUserStore";
import useMessageStore from "./store/useMessageStore";
import "./App.css";
import useWebSocket from "./hooks/useWebSocket";
import useMessagesAPI from "./hooks/useMessagesAPI";
import SplashLogo from "../src/assets/Logo_icon.png";
import DisableZoom from "./components/DisableZoom";
import Prompt from "./components/prompt";
import { registerFirebaseMessaging } from "./serviceWorker/service_worker";
import { firebaseMessage } from "./utils/firebaseInit";
import useHandleGuestEmail from "./hooks/useHandleGuestEmail";

const App = () => {
  const { user } = useUserStore();
  const { webSocketURL, pingInterval, webSocketConnection, setPingInterval } = useMessageStore();
  useHandleGuestEmail(webSocketConnection);
  const { connect } = useWebSocket();
  const { fetchConversations } = useMessagesAPI();
  const [loading, setLoading] = useState(true);

  const getDisplayMode = (): boolean => {
    if ("matchMedia" in window) {
      return window.matchMedia("(display-mode: standalone)").matches;
    }

    return false;
  };

  useEffect(() => {
    const timeoutRef: number | null = null;
    if (user && webSocketURL) {
      // Clear ping interval
      if (pingInterval !== 0) {
        setPingInterval(0);
        window.clearInterval(pingInterval);
      }
      connect(webSocketURL);
      fetchConversations();
      // fetchMessageAttachments();
    }

    setLoading(false);

    return () => {
      if (timeoutRef !== null) {
        clearTimeout(timeoutRef);
      }
    };
  }, [user, webSocketURL]);


  registerFirebaseMessaging(firebaseMessage);

  return loading && !getDisplayMode() ? (
    <div className="w-full sm:max-w-[375px] m-auto h-full justify-center items-center flex flex-col">
      <img src={SplashLogo} alt="splash_screen" />
    </div>
  ) : (
    <>
      {/* <PwaPrompt /> */}
      <DisableZoom />
      {user ? <UserLayout /> : <AuthLayout />}
      <Prompt />
    </>
  );
};

export default App;
