import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import useMessageStore from "../store/useMessageStore";
import useMessagesAPI from "../hooks/useMessagesAPI";
import ScreenHeader from "../components/ScreenHeader";
import AppButton from "../components/AppButton";
import ChatHistories from "../components/ChatHistories";
import Avatar from "../components/Avatar";
import Markdown from "react-markdown";
import { getCalendarTime } from "../helpers/dateFormatter";
import HistoryIconHeader from "../assets/icons/HistoryIconHeader";
import NotificationBell from "../components/NotificationBell";
import { trackEventPayload, trackEvent } from "../helpers/analytics";
import useHandleGuestEmail from "../hooks/useHandleGuestEmail";

type Message = {
  _id: string;
  channel: string;
  conversation: string;
  role: string;
  content: string;
  attachment: {
    name: string;
  } | null;
  createdAt: Date;
};

const Chat: React.FC = () => {

  const { 
    conversations,
    setActiveConversation,
    channel,
    webSocketConnection
  } = useMessageStore();


  useHandleGuestEmail(webSocketConnection);

  const { 
    fetchConversations,
    searchMessages,
    fetchMessages,
   } = useMessagesAPI();

  const navigate = useNavigate();

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isResultsEmpty, setIsResultsEmpty] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Message[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const inputValue = searchInputRef.current?.value;

    if (inputValue && inputValue.length >= 3 && inputValue.length <= 255) {
      const queryResult = await searchMessages(channel, inputValue);
      setSearchResults(queryResult);

      trackEventPayload("Chat searched", {
        search_query: inputValue
      });

      if (queryResult.length) {
        setIsResultsEmpty(false);
        return;
      }
      setIsResultsEmpty(true);
    }
  };

  const handleNewConversation = () => {
    const uuid = uuidv4();
    setActiveConversation(uuid);
    fetchMessages(uuid);

    trackEvent("Chat Started");

    navigate("/chat");
  };

  const cancelSearch = () => {
    if (searchInputRef.current?.value) {
      searchInputRef.current.value = "";
    }
    setSearchResults([]);
    setIsResultsEmpty(false);
    setIsSearching(false);
  };

  const selectConversation = (conversation: string, id: string, timestamp: Date) => {
    return () => {
      setActiveConversation(conversation);
      fetchMessages(conversation);

      trackEventPayload("Chat viewed", {
        chat_id: conversation,
        chat_timestamp: getCalendarTime(timestamp, 'YYYY/MM/DD HH:mm:ss')
      });

      navigate("/chat", {
        state: {
          anchorId: id
        }
      });
    };
  };

  useEffect(() => {
    fetchConversations();
    trackEvent("Chat History Viewed");
  }, []);

  return (
    <>
      <ScreenHeader type="primary">
        <div className="flex flex-row justify-between items-center">
          {!isSearching ? (
            <>
              <div className="flex flex-row items-center gap-[10px]">
                <HistoryIconHeader />
                <label className="font-bold text-lg text-white">History</label>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <span className="mt-[2px]" onClick={() => setIsSearching(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L19.2929 20.7071ZM17 10.5C17 14.0899 14.0899 17 10.5 17V19C15.1944 19 19 15.1944 19 10.5H17ZM10.5 17C6.91015 17 4 14.0899 4 10.5H2C2 15.1944 5.80558 19 10.5 19V17ZM4 10.5C4 6.91015 6.91015 4 10.5 4V2C5.80558 2 2 5.80558 2 10.5H4ZM10.5 4C14.0899 4 17 6.91015 17 10.5H19C19 5.80558 15.1944 2 10.5 2V4ZM20.7071 19.2929L16.5195 15.1053L15.1053 16.5195L19.2929 20.7071L20.7071 19.2929Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <NotificationBell />
              </div>
            </>
          ) : (
            <div className="flex flex-row gap-4 w-full">
              <form onSubmit={(e) => handleSubmit(e)} className="w-full">
                <div className="relative">
                  <input
                    ref={searchInputRef}
                    type="search"
                    maxLength={255}
                    className="input w-full input-ghost focus:bg-[#363F72] focus:border focus:border-[#5e658e] !text-white pl-8 text-sm"
                    placeholder="Search MedSnap"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#FAF5F4]"
                  >
                    <path
                      d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
                      stroke="#FAF5F4"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </form>
              <button
                className="btn btn-ghost text-primary-200"
                onClick={cancelSearch}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </ScreenHeader>
      {!isSearching ? (
        <>
          <div className="py-3 px-4 bg-[#293056] border border-l-0 border-r-0 border-slate-400">
            <AppButton
              variant="secondary"
              type="button"
              onClick={handleNewConversation}
            >
              New Chat
            </AppButton>
          </div>
          <div className="scrollbar-thin scrollbar-thumb-gray-900 bg-[#293056] h-full">
            {conversations.length ? (
              <div id="messages">
                <ChatHistories conversation={conversations} />
              </div>
            ) : (
              <div className="text-white flex flex-col justify-center items-center h-full">
                <img
                  src="images/chat_history.svg"
                  alt="Chat history empty"
                  width={160}
                />
                <label className="font-bold text-lg pt-6">
                  Let&apos;s start chatting!
                </label>
                <label className="text-xs font-medium">
                  Your friendly health companion is here!
                </label>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {searchResults.length || isResultsEmpty ? (
            <div className="p-4 bg-white pb-3 shadow-sm">
              <label className="text-ms-gray-200 font-bold text-sm">
                Search results for <span>&#8220;</span>
                {searchInputRef.current?.value}
                <span>&#8221;</span>
              </label>
            </div>
          ) : null}
          <div className="scrollbar-thin scrollbar-thumb-gray-900 bg-[#F2F4F7] flex flex-col gap-[14px] h-screen">
            {searchResults.length && !isResultsEmpty ? (
              <div className="px-3 py-4 flex flex-col gap-[14px]">
                {searchResults.map((result: Message) => (
                  <div
                    className="bg-white rounded-[10px] p-[10px] cursor-pointer"
                    onClick={selectConversation(result.conversation, result._id, result.createdAt)}
                    id={result._id}
                  >
                    <div
                      className="gap-[10px] flex flex-row items-start"
                      key={result._id}
                    >
                      {result.role === "Bot" ? (
                        <img src="images/avatar_bot.svg" alt="avatar" />
                      ) : (
                        <Avatar size="w-8" />
                      )}

                      {
                        result.role === "Bot" ? (
                          <div className="text-sm text-ms-gray-400 font-medium whitespace-pre-wrap">
                          <Markdown>{result.content}</Markdown>
                          </div>
                        ) : (
                          <p className="text-sm text-ms-gray-400 font-medium">
                            { result.content }
                          </p>
                        )
                      }
                    
                    </div>
                    <label className="float-right text-[10px] text-ms-gray-100 pt-1">
                      {getCalendarTime(result?.createdAt)}
                    </label>
                  </div>
                ))}
              </div>
            ) : null}
            {isResultsEmpty ? (
              <>
                <div className="flex flex-col items-center justify-center h-full text-center">
                  <img
                    src="images/search_result.svg"
                    alt="Search Result"
                    width={56}
                    height={56}
                  />
                  <label className="font-bold text-xl py-4 ">
                    No Results Found
                  </label>
                  <p className="font-medium text-ms-gray-300 text-sm">
                    Sorry, we didn’t find any results matching this search.
                    Please try a different keyword.
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default Chat;
