import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMessagesAPI from "../hooks/useMessagesAPI";
import useMessageStore from "../store/useMessageStore";
import AppButton from "./AppButton";
import { getCalendarTime } from "../helpers/dateFormatter";
import toast, { Toaster } from "react-hot-toast";
import { ToastOptions } from "../helpers/constants";
import ToastWrapper from "./ToastWrapper";
import { ChatMessage, ErrorMessage } from "../helpers/enums";
import { trackEventPayload } from "../helpers/analytics";


type Chat = {
  conversation: string;
  content: string;
  id: string;
  createdAt: Date;
  attachment: {
    name: string;
  } | null;
};

type ChatHistoryProps = {
  conversation: Chat[];
};

const ChatHistories: React.FC<ChatHistoryProps> = ({ conversation }) => {
  const deleteChatModalRef = useRef<HTMLDialogElement>(null);
  const navigate = useNavigate();
  const { fetchConversations, fetchMessages, deleteConversation } =
    useMessagesAPI();
  const { setActiveConversation } = useMessageStore();
  const [threadId, setThreadId] = useState<string>("");
  const [timestamp, setTimestamp] = useState<Date | null>();

  const selectConversation = (conversation: string, chatTimeStamp: Date) => {
    setActiveConversation(conversation);
    fetchMessages(conversation);

    trackEventPayload("Chat viewed", {
      chat_id: conversation,
      chat_timestamp: getCalendarTime(chatTimeStamp, 'YYYY/MM/DD HH:mm:ss')
    });

    navigate("/chat");
  };

  const openModal = (event: React.MouseEvent, conversationId: string, chatTimeStamp: Date) => {
    event.stopPropagation();
    if (deleteChatModalRef.current) {
      setThreadId(conversationId);
      setTimestamp(chatTimeStamp);
      deleteChatModalRef.current.showModal();
    }
  };

  const closeModal = () => {
    if (deleteChatModalRef.current) {
      deleteChatModalRef.current.close();
      setThreadId("");
      setTimestamp(null);
    }
  };

  const handleShowThreadClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const deleteThread = async () => {
    try {
      await deleteConversation(threadId);
      fetchConversations();

      toast.success(
        (t) => (
          <ToastWrapper
            message={ChatMessage.CHAT_DELETE_SUCCESS}
            toastId={t.id}
          />
        ),
        {
          id: "chat-delete-success",
          style: ToastOptions.SUCCESS,
        }
      );

      if (timestamp) {
        trackEventPayload("Chat Deleted", {
          chat_id: threadId,
          chat_timestamp: getCalendarTime(timestamp, "YYYY/MM/DD HH:mm:ss")
        });
      }

      closeModal();
    } catch (error) {
      closeModal();
      toast.error(
        (t) => (
          <ToastWrapper message={ErrorMessage.NETWORK_ERROR} toastId={t.id} />
        ),
        {
          id: "chat-delete-error",
          style: ToastOptions.ERROR,
        }
      );
    }
  };

  return (
    <>
      <Toaster />
      {conversation.map((item: Chat) => (
        <div
          onClick={() => selectConversation(item?.conversation, item?.createdAt)}
          key={item.id}
          id={item.id}
          className={`w-full selection:message-card py-4 px-[22px] rounded-0 bg-chatHistory flex flex-row justify-between items-center active:bg-[#363F72] cursor-pointer`}
        >
          <div className="flex flex-row items-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M19.25 1.375C20.0094 1.375 20.625 1.99061 20.625 2.75V13.75C20.625 14.5094 20.0094 15.125 19.25 15.125H6.06954C5.3402 15.125 4.64072 15.4147 4.125 15.9305L1.375
                     18.6805V2.75C1.375 1.99061 1.99061 1.375 2.75 1.375H19.25ZM2.75 0C1.23122 0 0 1.23122 0 2.75V20.3402C0 20.9527 0.740534 21.2595 1.17363 20.8264L5.09727 16.9027C5.35513 16.6449 5.70487 16.5 6.06954 16.5H19.25C20.7688 16.5 22 15.2688 22 13.75V2.75C22 1.23122 20.7688 0 19.25 0H2.75Z"
                fill="white"
              />
            </svg>
            <div className="overflow-hidden">
              <span className="text-xs font-normal text-white">
                {getCalendarTime(item?.createdAt)}
              </span>
              <p className="text-base font-semibold text-white whitespace-nowrap text-ellipsis overflow-hidden max-w-[257px]">
                {item.content ? item.content : item.attachment?.name}
              </p>
            </div>
          </div>
          <div className="dropdown dropdown-end">
            <div tabIndex={0}>
              <svg
                onClick={(e) => handleShowThreadClick(e)}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.99935 10.8334C10.4596 10.8334 10.8327 10.4603 10.8327 10C10.8327 9.5398 10.4596 9.16671 9.99935 9.16671C9.53911 9.16671 9.16602 9.5398 9.16602 10C9.16602 10.4603 9.53911 10.8334 9.99935 10.8334Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.99935 5.00004C10.4596 5.00004 10.8327 4.62694 10.8327 4.16671C10.8327 3.70647 10.4596 3.33337 9.99935 3.33337C9.53911 3.33337 9.16602 3.70647 9.16602 4.16671C9.16602 4.62694 9.53911 5.00004 9.99935 5.00004Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.99935 16.6667C10.4596 16.6667 10.8327 16.2936 10.8327 15.8334C10.8327 15.3731 10.4596 15 9.99935 15C9.53911 15 9.16602 15.3731 9.16602 15.8334C9.16602 16.2936 9.53911 16.6667 9.99935 16.6667Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2  bg-base-100 rounded-md mr-2 w-[199px]"
            >
              <li className="px-4 py-2">
                <div className="text-sm flex flex-row p-0 justify-between">
                  Show Chat Thread
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15.1875 1.78125C15.757 1.78125 16.2187 2.24296 16.2187 2.8125V11.0625C16.2187 11.632 15.757 12.0937 15.1875 12.0937H5.30216C4.75515 12.0937 4.23054 12.311 3.84375 12.6978L1.78125 14.7603V2.8125C1.78125 2.24296 2.24296
                       1.78125 2.8125 1.78125H15.1875ZM2.8125 0.75C1.67341 0.75 0.75 1.67341 0.75 2.8125V16.0052C0.75 16.4645 1.3054 16.6946 1.63023 16.3698L4.57295 13.427C4.76635 13.2336 5.02865 13.125 5.30216 13.125H15.1875C16.3266 13.125 17.25 12.2016 17.25 11.0625V2.8125C17.25 1.67341 16.3266 0.75 15.1875 0.75H2.8125Z"
                      fill="#040815"
                    />
                  </svg>
                </div>
              </li>
              <hr />
              <li
                className=" px-4 py-2"
                onClick={(e) => openModal(e, item?.conversation, item?.createdAt)}
              >
                <div className="text-sm flex flex-row justify-between p-0 hover:bg-transparent text-danger active:bg-none">
                  Delete
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M18 6V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6M4 6H20M10 10V16M14 10V16"
                      stroke="#D92D20"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ))}
      <dialog className="modal max-w-[327px] m-auto" ref={deleteChatModalRef}>
        <div className="modal-box flex flex-col items-center">
          <img src="images/modal-danger-icon.svg" />
          <h3 className="font-bold text-base">Delete this chat?</h3>
          <p className="py-4 text-sm text-center">
            This will permanently delete this session.
          </p>
          <p className="text-sm pb-8">Do you want to proceed?</p>
          <div className="flex flex-col w-full gap-3">
            <AppButton
              variant="danger"
              type="button"
              onClick={deleteThread}
              className="focus-visible:outline-0"
            >
              Delete Chat
            </AppButton>
            <AppButton
              type="button"
              onClick={closeModal}
              className="focus-visible:outline-0"
            >
              Cancel
            </AppButton>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default ChatHistories;
