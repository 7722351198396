import React from "react";

const NotificationsEmpty: React.FC = () => {
    return(
        <div className="h-full flex justify-center">
         <div className="flex flex-col items-center justify-center">
            <svg width="95" height="96" viewBox="0 0 95 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="7.5" y="8" width="80" height="80" rx="40" fill="#EAEAF3"/>
                <rect x="7.5" y="8" width="80" height="80" rx="40" stroke="#F9FAFB" strokeWidth="14.2857"/>
                <g clipPath="url(#clip0_3756_6687)">
                    <path d="M35.7809 44.6498C35.7789 43.1673 36.0702 41.6991 36.6382 40.3297C37.2063 38.9604 38.0397 37.717 39.0905 36.6712C40.1413 35.6255 41.3887 34.7981 42.7607 34.2366C44.1328 33.6752 45.6024 33.3909 47.0849 33.4001C53.2708 33.4461 58.2187 38.5878 58.2187 44.791V45.8998C58.2187 51.496 59.3895 54.7434 60.4207 56.5183C60.5318 56.7079 60.5909 56.9235 60.5921 57.1433C60.5933 57.3631 60.5365 57.5793 60.4274 57.7701C60.3184 57.9609 60.1609 58.1196 59.971 58.2302C59.781 58.3407 59.5653 58.3992 59.3455 58.3998H34.6529C34.4331 58.3992 34.2173 58.3407 34.0273 58.2301C33.8374 58.1195 33.6799 57.9608 33.5709 57.77C33.4618 57.5791 33.4051 57.3629 33.4063 57.1431C33.4075 56.9232 33.4666 56.7076 33.5778 56.518C34.6096 54.7431 35.7809 51.4957 35.7809 45.8998L35.7809 44.6498Z" stroke="#2F3082" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M42 58.3999V59.6499C42 60.976 42.5268 62.2478 43.4645 63.1854C44.4021 64.1231 45.6739 64.6499 47 64.6499C48.3261 64.6499 49.5979 64.1231 50.5355 63.1854C51.4732 62.2478 52 60.976 52 59.6499V58.3999" stroke="#2F3082" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_3756_6687">
                        <rect width="40" height="40" fill="white" transform="translate(27 28.3999)"/>
                    </clipPath>
                </defs>
            </svg>
            <label className="text-[#2F3082] font-bold text-lg">No Notifications</label>
            <label className="text-[#2F3082] font-normal text-sm">You haven't received any notifications yet.</label>
          </div>
        </div>
    )
}

export default NotificationsEmpty;