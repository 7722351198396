import { Messaging, getToken, onMessage  } from "firebase/messaging";

  export const registerFirebaseMessaging = (messaging: Messaging) => {

    if ("serviceWorker" in navigator)  {
      //window.addEventListener('load', function () {
        navigator.serviceWorker
          .register(
            '../firebase-messaging-sw.js',
            { scope: "./" }
          )
          .then((registration) => {

            getToken(messaging, {
              vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
              serviceWorkerRegistration: registration
            }).then(resolve => console.log(resolve)).catch(err => console.log(err));
   
            // onMessage(messaging, (payload) => {
            //   console.log("firebaseMessage", messaging);
            //   console.log("payload", payload);

            //   if (!(self.Notification && self.Notification.permission === 'granted')) {
            //     console.log("Has permission");
            //     return;
            //   }
  
            //   Notification.requestPermission()
            //     .then((permission) => {
            //       if (permission === "granted") {
  
            //         registration.showNotification(payload!.notification!.title!, {
            //           body: payload!.notification!.body!,
            //           image: payload!.notification!.image!,
            //           tag: payload.collapseKey
            //         });
 
            //       }
            //     });

            // });
           
          });
      //});

    }
  }

