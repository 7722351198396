import React from "react";

const HistoryIconHeader: React.FC = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="3" width="40" height="40" rx="20" fill="#7F87B5"/>
            <rect x="3" y="3" width="40" height="40" rx="20" stroke="#4E557F" strokeWidth="5"/>
            <path d="M29.9375 15.7813C30.507 15.7813 30.9688 16.243 30.9688 16.8125V25.0625C30.9688 25.632 30.507 26.0938 29.9375 26.0938H20.0522C19.5051 26.0938 18.9805 26.3111 18.5938 26.6978L16.5313 28.7603V16.8125C16.5313 16.243 16.993 15.7813 17.5625 15.7813H29.9375ZM17.5625 14.75C16.4234 14.75 15.5 15.6734 15.5 16.8125V30.0052C15.5 30.4645 16.0554 30.6946 16.3802 30.3698L19.323 27.427C19.5164 27.2337 19.7787 27.125 20.0522 27.125H29.9375C31.0766 27.125 32 26.2016 32 25.0625V16.8125C32 15.6734 31.0766 14.75 29.9375 14.75H17.5625Z" fill="white"/>
            <path d="M18.5938 18.3594C18.5938 18.0746 18.8246 17.8438 19.1094 17.8438H28.3906C28.6754 17.8438 28.9063 18.0746 28.9063 18.3594C28.9063 18.6441 28.6754 18.875 28.3906 18.875H19.1094C18.8246 18.875 18.5938 18.6441 18.5938 18.3594ZM18.5938 20.9375C18.5938 20.6527 18.8246 20.4219 19.1094 20.4219H28.3906C28.6754 20.4219 28.9063 20.6527 28.9063 20.9375C28.9063 21.2223 28.6754 21.4531 28.3906 21.4531H19.1094C18.8246 21.4531 18.5938 21.2223 18.5938 20.9375ZM18.5938 23.5156C18.5938 23.2309 18.8246 23 19.1094 23H24.2656C24.5504 23 24.7813 23.2309 24.7813 23.5156C24.7813 23.8004 24.5504 24.0313 24.2656 24.0313H19.1094C18.8246 24.0313 18.5938 23.8004 18.5938 23.5156Z" fill="white"/>
        </svg>
    )
}

export default HistoryIconHeader;