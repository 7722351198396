export const trackEvent = (event: string) => {
    window.analytics.track(event);
}

export const trackEventPayload = (event: string, payload: Record<string, string | null>) => {
    window.analytics.track(event, payload);
}

export const resetTrackingEvent = () => {
    window.analytics.reset();
}

export const trackIdentity = (identityType: string, payload: Record<string, string | null>) => {
    
    window.analytics.user().traits({});
    
    if(identityType === "signup"){
        window.analytics.identify({
            firstName : payload.firstname,
            lastName  : payload.lastname,
            birthdate : payload.birthdate,
            gender    : payload.gender,
            email     : payload.email,
            promotionConsent : payload.promotionConsent,
            userID    : '',
            phone     : payload.username
        });

        return;
    }

    window.analytics.identify(payload.userID, payload);
}