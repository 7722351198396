import React from "react";
import useUserStore from "../store/useUserStore";
import { useNavigate } from "react-router-dom";
import AppButton from "../components/AppButton";

const UpdatedPin: React.FC = () => {
  const { userAction } = useUserStore();
  const navigate = useNavigate();

  return (
    <div
       style={{
        backgroundImage: "url(images/login-vector-bg.png)",
        backgroundSize: "cover",
        backgroundBlendMode: "color-burn"
       }} 
       className="w-full sm:max-w-[375px] m-auto bg-[#2C2E83] flex flex-col items-center h-full justify-between">
      <div className="flex justify-center h-full items-center">
        <img
          className="w-[219px] h-[174px]"
          src="images/okay-bubble.svg"
          alt="image"
        />
      </div>
      <div className="bg-white rounded-tr-[20px] rounded-tl-[20px] px-4 pb-4 text-center pt-4 w-full">
        <div className="flex flex-row gap-[10px] items-center justify-center">
          <span className="text-lg font-bold">
            You have successfully{" "}
            {userAction?.action === "resetPIN" ? "reset" : "updated"} your pin!
          </span>
        </div>
        <div className="text-sm text-center">
          PIN{" "}
          {userAction?.action === "resetPIN"
            ? "reset complete"
            : "updated successfully"}
          !
        </div>
        <div className="text-sm text-center font-normal text-[#040815]">
          You can now access your account with your new PIN.
        </div>
        <div className="pt-6">
          <AppButton type="button" variant="primary" onClick={() => navigate("/signin")}>
            {userAction?.action === "resetPIN" ? "Login" : "Okay!"}
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default UpdatedPin;
