import { useEffect, useState } from "react";
import Logo from "../../Logo";
import CloseButton from "../../../assets/icons/cross-small.png";
import useUserStore from "../../../store/useUserStore";
// import { useNavigate } from 'react-router-dom';


const styleDiV = {
    boxShadow: "0 24px 48px -12px rgba(16, 24, 40, 0.18)",
}

const logoStyle = {
    width: "40px",
    height: "40px",
    boxShadow: "0 1px 3px 0px rgba(10, 18, 28, 0.1), 0 1px 2px 0px rgba(16, 24, 40, 0.06)",
}

const AddPrompt: React.FC = () => {
    // const {
    //     token,
    // } = useUserStore();
    // const navigate = useNavigate();
    const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
    const [showPrompt, setShowPrompt] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: Event) => {
            event.preventDefault();
            setDeferredPrompt(event);
            if(!touched){
                setShowPrompt(true);
            }
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    // useEffect(() => {
    //     if (token && deferredPrompt ) {
            
    //     }
    // }, [token,deferredPrompt]);



    const handleInstallClick = () => {
        setTouched(true)
        setShowPrompt(false);
        if (deferredPrompt) {
            setShowPrompt(false);
            const promptEvent = deferredPrompt as BeforeInstallPromptEvent;
            promptEvent.prompt();
            promptEvent.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    setShowPrompt(false);
                } else {
                    console.log('');
                }
            });

            setDeferredPrompt(null);
        }
    };


    return (
        <div className={`w-full sm:max-w-[375px] m-auto justify-center items-center relative ${showPrompt ? '':'overflow-y-hidden'}`}>
            <div className={`shadow-xl py-6 px-3 bg-white rounded-lg absolute z-[999] ${showPrompt ? 'bottom-[102px]' : 'top-[100%]'} w-[95%] left-[2.5%] flex flex-col`} style={styleDiV}>
                <div className="absolute right-[5px] top-[5px]" onClick={() => setShowPrompt(false)}>
                    <img src={CloseButton} />
                </div>
                <div className="flex ">
                    <div className="w-1/5">
                        <Logo className="rounded-[5px]" style={logoStyle} alt="MedSnap | Empower your health conversations with MedSnap" />
                    </div>
                    <div className="flex-1">
                        <p className="font-bold text-[14px] m-0 p-0 leading-[16px]">Install MedSnap</p>
                        <p className=" text-[10px] m-0 p-0 leading-[14px]">Empower your health conversations with MedSnap on your home screen.</p>

                    </div>
                </div>
                <button className="btn mt-[10px] min-h-[30px] h-[30px] text-[12px] btn-primary rounded-full text-white bg-[#2F3082] hover:bg-[#2F3082]" onClick={handleInstallClick}>
                    Install MedSnap
                </button>
            </div>
            </div>)
}

export default AddPrompt