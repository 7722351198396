export enum ChatMessage {
    CHAT_DELETE_SUCCESS = 'Chat successfully deleted.',
    ATTACHMENT_DELETE_SUCCESS = 'File successfully deleted.',
    ATTACHMENT_INVALID_FILE_TYPE = 'The file you uploaded is not supported. Please upload a PNG, JPG, or PDF file.',
    ATTACHMET_FILE_LIMIT_EXCEED= 'File size exceeds limit. Please reduce the file size and try again.'
}

export enum ErrorMessage {
    NETWORK_ERROR = 'Network Error. Please try again.'
}

export enum ProfileMesage {
    PROFILE_UPDATE_SUCCESS = 'Profile successfully updated.'
}