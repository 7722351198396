import { create, StateCreator } from "zustand";
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

type User = {
    id: number | null;
    firstname: string;
    lastname: string;
    gender: string;
    email: string;
    privacyPolicy: boolean;
    promotionConsent: boolean | null | undefined;
    birthdate: string;
    username: string;
} | null

type UserPin = {
    pin: string;
}

type UserAction = {
    action: string | null;
}

type Store = {
    user: User | null;
    token: string | null;
    otpVerified: boolean;
    userMobileNumber: string | null;
    userPin: UserPin | null;
    userAction: UserAction | null;
    newUser: User | null;
    OTPToken: string | null;
    setUser: (user: User) => void;
    setToken: (token: string | null) => void;
    setOtpVerified: (otpVerified: boolean) => void;
    setUserMobileNumber: (userMobileNumber: string) => void;
    setUserPin: (userPin: UserPin) => void;
    setUserAction: (userAction: UserAction) => void;
    setNewUser: (newUser: User) => void;
    setOTPToken: (OTPToken: string | null) => void;
};

type Persist = (
    config: StateCreator<Store>,
    options: PersistOptions<Store>
) => StateCreator<Store>

const userStore = create<Store, []>(
    (persist as Persist)(
        (set): Store => ({
            user: null,
            token: null,
            otpVerified: false,
            userMobileNumber: null,
            userPin: null,
            userAction: null,
            newUser: null,
            OTPToken: null,
            setUser: (user) => set(() => ({ user })),
            setToken: (token: string | null) => set(() => ({ token })),
            setOtpVerified: (otpVerified) => set(() => ({ otpVerified })),
            setUserMobileNumber: (userMobileNumber) => set(() => ({ userMobileNumber })),
            setUserPin: (userPin) => set(() => ({ userPin })),
            setUserAction: (userAction) => set(() => ({ userAction })),
            setNewUser: (newUser) => set(() => ({ newUser })),
            setOTPToken: (OTPToken) => set(() => ({ OTPToken })),
        }),
        {
            name: 'userStore',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

export default userStore;