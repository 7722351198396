import React from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../components/AppButton";

type ConfirmationProps = {
    accountType?: string;
    registerAccount?: () => void;
}


const Confirmation: React.FC<ConfirmationProps> = ({accountType, registerAccount}) => {

    const navigate = useNavigate();

    return (
    <>    
     <div
         style={{
          backgroundImage: "url(images/login-vector-bg.png)",
          backgroundSize: "cover",
          backgroundBlendMode: "color-burn"
        }} 
        className="w-full sm:max-w-[375px] m-auto bg-[#2C2E83] flex flex-col items-center h-full justify-between">
       <div className="flex justify-center h-full items-center">
         <img
           className="w-[219px] h-[174px]"
           src="images/okay-bubble.svg"
           alt="image"
         />
       </div>
       <div className="bg-white rounded-tr-[20px] rounded-tl-[20px] px-4 pb-4 text-center pt-4 w-full">

        {
            accountType === "guest" ? (
              <div className="flex flex-row gap-[10px] items-center justify-center">
                <img src="images/green-pill.png" alt="image" width={30} />
                <span className="text-lg font-bold">Welcome to MedSnap</span>
                <img src="images/blue-pill.png" alt="image" width={30} />
              </div>
            ) : (
              <div className="flex flex-row gap-[10px] items-center justify-center">
                <span className="text-lg font-bold">
                 You have successfully created your account!
                </span>
              </div>
            )
        }
         <div className="font-normal text-[#040815] text-sm text-center py-4">
            Welcome to a health journey with a companion who's always by your side. 
         </div>
         <div className="text-sm text-center font-normal text-[#040815]">
           { accountType === "guest" ? 
           "Proceed to chat now!" :
           "Login to your account now to start your journey!"
           }
         </div>
         <div className="pt-6">
           <AppButton type="button" variant="primary" onClick={accountType === "guest" ? registerAccount : () => navigate("/signin")}>
             { accountType === "guest" ? "Go to Chat" : "Login" }
           </AppButton>
         </div>
       </div>
     </div> 
    </>
    )
}

export default Confirmation;