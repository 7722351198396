import React from "react";
import toast from "react-hot-toast";

type ToastWrapperProps = {
    message: string;
    toastId: string;
}

const ToastWrapper: React.FC<ToastWrapperProps> = ({message, toastId}) => {
    return (
        <div className="flex flex-row justify-between w-full">
            { message }
            <button onClick={() => toast.dismiss(toastId)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M5.80806 5.80806C6.05214 5.56398 6.44786 5.56398 6.69194 5.80806L10 9.11612L13.3081 5.80806C13.5521 5.56398 13.9479 5.56398 14.1919 5.80806C14.436 6.05214 14.436 6.44786 14.1919 6.69194L10.8839 10L14.1919 13.3081C14.436 13.5521 14.436 13.9479 14.1919 14.1919C13.9479 14.436 13.5521 14.436 13.3081 14.1919L10 10.8839L6.69194 14.1919C6.44786 14.436 6.05214 14.436 5.80806 14.1919C5.56398 13.9479 5.56398 13.5521 5.80806 13.3081L9.11612 10L5.80806 6.69194C5.56398 6.44786 5.56398 6.05214 5.80806 5.80806Z" fill="#8E8E93"/>
            </svg>
            </button>
        </div>
    )
}

export default ToastWrapper;