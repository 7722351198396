import { create, StateCreator } from "zustand";
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';
import { Message, Conversation, MessageAttachment, Attachment, MessageAttachmentPreview } from '../helpers/message';
import client from "../utils/axios-client";

type Store = {
  webSocketURL: string | null,
  webSocketConnection: WebSocket | null,
  messages: Message[];
  attachment: Attachment | null;
  attachments: MessageAttachment[],
  chunk: string,
  channel: string | null;
  conversations: Conversation[];
  activeConversation: string | null;
  searchTerm: string | null;
  searchResults: string[];
  selectedSearchResult: string | null;
  responds: boolean,
  sending: boolean,
  typeable: boolean,
  pingInterval: any,
  responseTimeout: number,
  attachmentPreview: MessageAttachmentPreview | null,
  attachmentUploading: boolean,
  generatingResponse: boolean,
  setWebSocketURL: (url: string | null) => void;
  setWebSocketConnection: (connection: WebSocket | null) => void;
  setMessages: (newMessages: Message[]) => void;
  addMessage: (messages: Message[]) => void;
  updateMessageContent: (index: number, newContent: string) => void;
  updateLastBotMessage: () => void;
  setAttachment: (attachment: Attachment | null) => void;
  setAttachments: (attachments: MessageAttachment[]) => void;
  setChunk: (chunk: string) => void;
  setChannel: (channel: string) => void;
  setConversations: (conversations: Conversation[]) => void;
  setActiveConversation: (conversation: string | null) => void;
  setSearchTerm: (term: string) => void;
  setSearchResults: (results: string[]) => void;
  setSelectedSearchResult: (result: string) => void;
  setResponds: (status: boolean) => void;
  setSending: (status: boolean) => void;
  setTypeable: (status: boolean) => void;
  setPingInterval: (interval: number) => void;
  setResponseTimeout: (interval: number) => void;
  fetchFileAttachments: (token: string) => void;
  setAttachmentPreview: (attachmentInfo: MessageAttachmentPreview | null) => void;
  setGeneratingResponse: (status: boolean) => void;
};

type Persist = (
  config: StateCreator<Store>,
  options: PersistOptions<Store>
) => StateCreator<Store>

const messageStore = create<Store, []>(
  (persist as Persist)(
    (set): Store => ({
      webSocketURL: null,
      webSocketConnection: null,
      messages: [],
      attachment: null,
      attachments: [],
      chunk: "",
      channel: null,
      conversations: [],
      activeConversation: null,
      searchTerm: "",
      searchResults: [],
      selectedSearchResult: null,
      responds: true,
      sending: false,
      typeable: true,
      pingInterval: 0,
      responseTimeout: 0,
      attachmentPreview: null,
      attachmentUploading: false,
      generatingResponse: false,
      setWebSocketURL: (webSocketURL) => set(() => ({ webSocketURL })),
      setWebSocketConnection: (webSocketConnection: any | null) => set(() => ({ webSocketConnection })),
      setMessages: (newMessages) => set(() => ({ messages: newMessages })),
      addMessage: (newMessages) => set((state) => ({
        messages: [...state.messages, ...newMessages],
      })),
      updateMessageContent: (index, newContent) => set((state) => {
        const updatedMessages = [...state.messages];
        if (index >= 0 && index < updatedMessages.length) {
          updatedMessages[index] = {
            ...updatedMessages[index],
            content: newContent,
          };
          return { messages: updatedMessages };
        }
        return state;
      }),
      updateLastBotMessage: () => set((state) => {
        const updatedMessages = [...state.messages];

        for (let i = updatedMessages.length - 1; i >= 0; i--) {
          if (updatedMessages[i].role === 'Bot') {
            const newContent = `${updatedMessages[i].content}${state.chunk}`;
            updatedMessages[i] = {
              ...updatedMessages[i],
              content: newContent,
            };
            return { messages: updatedMessages };
          }
        }
        return state;
      }),
      setAttachment: (attachment) => set(() => ({ attachment })),
      setAttachments: (attachments) => set(() => ({ attachments })),
      setChunk: (chunk) => set(() => ({ chunk })),
      setChannel: (channel) => set(() => ({ channel })),
      setConversations: (conversations) => set(() => ({ conversations })),
      setActiveConversation: (activeConversation) => set(() => {
        return { activeConversation };
      }),
      setSearchTerm: (searchTerm) => set(() => ({ searchTerm })),
      setSearchResults: (searchResults) => set(() => ({ searchResults })),
      setSelectedSearchResult: (selectedSearchResult) => set(() => ({ selectedSearchResult })),
      setResponds: (responds) => set(() => ({ responds })),
      setSending: (sending) => set(() => ({ sending })),
      setTypeable: (typeable) => set(() => ({ typeable })),
      setPingInterval: (pingInterval) => set(() => ({ pingInterval })),
      setResponseTimeout: (responseTimeout) => set(() => ({ responseTimeout })),
      setAttachmentPreview: (attachmentPreview) => set(() => ({ attachmentPreview })),
      fetchFileAttachments: async (token) => {
        try {
          const response = await client.get(
            "/messages/attachments",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const { messages } = response.data;
          set((state) => ({
            ...state,
            attachments: messages
          }));

        } catch (error) {
          console.log(error);
        }
      },
      setGeneratingResponse: (generatingResponse) => set(() => ({ generatingResponse }))
    }),
    {
      name: 'messageStore',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default messageStore;