import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ScreenHeader from "../components/ScreenHeader";
import useNotificationStore from "../store/useNotificationsStore";
import NotificationCard from "../components/NotificationCard";
import NotificationsEmpty from "../components/NotificationsEmpty";
import DividerBagde from "../components/DividerBadge";
import useNotificationsAPI  from "../hooks/useNotificationsAPI";
import { AppNotification, } from "../helpers/notifications";
import ReadCampaigns from "../components/ReadCampaigns";
import { firebaseMessage } from "../utils/firebaseInit";
import { onMessage } from "firebase/messaging";

const campaigns: Set<AppNotification> = new Set();

const NotificationsPage: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { postNotifications, fetchNotifications, postCampaign } = useNotificationsAPI();
    const { readNotifications , setReadNotifications } = useNotificationStore();
    const [pushNotifications, setPushNotifications] = useState<AppNotification[]>([]);
    const [previousNotifications, setPreviousNotifications] = useState<AppNotification[] | null>([]);
 
    const notificationVisible = (notif: any) => {

        const exists = [...campaigns].some(existingNotif => existingNotif.campaignId === notif.campaignId);
       
        if(!exists){
            campaigns.add(notif);
        }
    };

    const fetchCampaigns = async () => {
        const response = await fetchNotifications();
        const unreadCampaigns = response.filter(campaign => !campaign.is_read);
        setPushNotifications(unreadCampaigns);
    }

    const goBack = () => {
       location.key === "default" ? navigate('/chat') : navigate(-1);
    }

    useEffect(() => {
        setPreviousNotifications(readNotifications);
    }, [readNotifications])

    useEffect(() => {   

        campaigns.clear();
        setPushNotifications([]);
        fetchCampaigns();

        (async() => {

            if ("serviceWorker" in navigator){

                const registration = await navigator.serviceWorker.ready;

                onMessage(firebaseMessage, async(payload) => {

                    console.log(payload);
                    if (!(self.Notification && self.Notification.permission === 'granted')) {
                      return;
                    }
        
                    const permission = await Notification.requestPermission();

                    if(permission === "granted"){
                        try {

                            registration.showNotification(payload!.notification!.title!, {
                                body: payload!.notification!.body!,
                                image: payload!.notification!.image!,
                                tag: payload.collapseKey,
                                data: payload.data
                              } as NotificationOptions);
    
                                await postCampaign({
                                        userId: 0,
                                        title: payload?.notification?.title,
                                        body: payload?.notification?.body,
                                        image: payload?.notification?.image,
                                        campaignId: payload?.collapseKey,
                                        click_action:  payload?.data?.click_action,
                                        category:  payload?.data?.category
                                    });
                            
                                fetchCampaigns();

                        }catch(error){
                            console.log(error);
                        }
                     } 
                  });
            }else{
              console.log("Service Worker Not Supported")
            }
        })();

        return () => {

            const getNotifications = async() => {
               
                const campaignSet = Array.from(campaigns);
                if (campaignSet.length) {
                    await postNotifications(campaignSet);
                    //campaignSet.map((campaign) => campaign.close());
                }

                const response = await fetchNotifications();
                setReadNotifications(response);
            }
            getNotifications();
      }
    }, [])

    return (
        <>
        <ScreenHeader type="primary">
            <div className="flex flex-row items-center gap-[10px] h-[32px]">
                <button className="btn-ghost cursor-pointer" onClick={() => goBack()}>
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 7L17 7M1 7L7 1M1 7L7 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </button>
                <div className="w-full text-white font-semibold text-base text-center mr-[32px]">
                    Notifications
                </div>
            </div>
        </ScreenHeader>
         <div className="h-full px-6 py-6 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 bg-[#F5F3FB]">
            {
                (!pushNotifications || pushNotifications.length === 0) &&
                (!previousNotifications || previousNotifications.length === 0) ? (
                    <NotificationsEmpty />
                ) : (
                    <div className="flex flex-col gap-4">
                        {(!pushNotifications || pushNotifications.length > 0)  && <DividerBagde variant="NEW" />}
                        {pushNotifications &&
                            pushNotifications.map((notif: AppNotification, index) => (
                                <NotificationCard 
                                  props={notif}
                                  key={index} 
                                  isNew={true}
                                  isViewed={notificationVisible}
                                />
                            ))}
                        {previousNotifications && <DividerBagde variant="PREVIOUS" />}
                        {previousNotifications &&
                            previousNotifications.map((notif, index) => (
                                notif.is_read && <ReadCampaigns campaign={notif} key={index} />
                            ))}
                    </div>
                )
            }
          </div>
        </>
    )
}

export default NotificationsPage;
