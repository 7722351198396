import React from "react";
import { useState, useRef, useEffect } from "react";
import useUserStore from "../store/useUserStore";
import { useNavigate } from "react-router-dom";
import useToast from "../hooks/useToast";
import client from "../utils/axios-client";
import AppButton from "../components/AppButton";
import Notification from "../components/Notifications";
import { trackEvent } from "../helpers/analytics";

let currentIndex: number = 0;
const Verification: React.FC = () => {

  const navigate = useNavigate();
  const { notify, showToast } = useToast();
  const { userMobileNumber, userAction, setUserAction, setOTPToken } =
    useUserStore();
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const OTPInterval = import.meta.env.VITE_OTP_INTERVAL as number;
  const [seconds, setSeconds] = useState(OTPInterval);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [verifying, setVerifying] = useState<boolean>(false);
  const [nextButtonStatus, setNextButtonStatus] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const resendOTP = async () => {

    try{

      await client.post(`/otp/send`, {
        mobileNumber: userMobileNumber,
      });
      setSeconds(OTPInterval);

      showToast({
        visible: true,
        variant: 'success',
        message: "Code has been sent!"
      });

      setError("");

    }catch(error){

      showToast({
        visible: true,
        variant: "danger",
        message: "Network Error."
      });
    }

  };

  const maskMobileNumber = (mobileNumber: string): string => {
    if (mobileNumber.length < 11) {
      return mobileNumber;
    }

    const startCharacter = mobileNumber[0];
    const formattedMobileNumber = mobileNumber.replace(startCharacter, "+63");
    const maskedPart = formattedMobileNumber
      .substring(4, 10)
      .replace(/\d/g, "*");
    return (
      formattedMobileNumber.substring(0, 4) +
      maskedPart +
      formattedMobileNumber.substring(10)
    );
  };

  const handleOnChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newPins: string[] = [...otp];

    if (/^\d+$/.test(value)) {
      newPins[currentIndex] = value.substring(value.length - 1);

      if (!value) {
        setActiveIndex(currentIndex - 1);
      } else {
        setActiveIndex(currentIndex + 1);
      }

      setOtp(newPins);
    }

    setNextButtonStatus(currentIndex === 5);
  };

  const handleKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {

    currentIndex = index;

    if (key === "Backspace") {
      setActiveIndex(currentIndex - 1);

      setOtp((latestState) => {
        const previousPin = [...latestState];

        if(previousPin[currentIndex] === '' && index < 6){
          previousPin[currentIndex - 1] = '';
        }else{
          previousPin[currentIndex] = '';
        }
  
        return previousPin;
      });
    }
  };

  const verify = async () => {

    try{

      setVerifying(true);
      const response = await client.post(`/otp-verification`, {
        mobileNumber: userMobileNumber,
        otp: otp.join(""),
      });
      setVerifying(false);
      if (response.data.code === 200) {

        trackEvent("Mobile Verified");

        if (
          userAction?.action === "signUp" ||
          userAction?.action === "guestSignUp" ||
          userAction?.action === "changePIN" ||
          userAction?.action === "resetPIN"
        ) {
          setOTPToken(response.data.token);
          navigate("/set-pin", { replace: true });
        } else {
          setUserAction({ action: "signIn" });
          navigate("/pin", { replace: true });
        }
      } else {
        setShowError(true);
        if (response.data.message === "Didn’t Match. Try Again") {
          setError("Incorrect OTP. Please try again.");
        } else {
          setError("Expired OTP. Please request a new one.");
        }
      }
    }catch(error){

        showToast({
          visible: true,
          variant: "danger",
          message: "Network Error. Something went wrong"
        });

    }finally{
      setVerifying(false);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeIndex]);

  return (
    <>
      <div className="w-full sm:max-w-[375px] m-auto p-6 h-full flex flex-col justify-between">
        <div>
          <div className="flex flex-row gap-4">
            <button onClick={() => navigate(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4 12L20 12M4 12L10 6M4 12L10 18" stroke="#2F3082" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            </button>
            <label className="font-bold">Verify OTP</label>
          </div>
          <div className="mt-6">
             <Notification notificationOptions={notify} />
          </div>  
        </div>
        <>
          {verifying ? (
            <div className="flex flex-col justify-center items-center pt-6 text-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : (
            <div>
              <div className="text-center pb-4">
                <span className="pb-4 font-normal text-sm">
                  A code will be sent to{" "}
                  {maskMobileNumber(userMobileNumber ?? "")} for verification.
                </span>
              </div>
              <div className="flex flex-row gap-2 justify-center">
                {/* for opt input */}
                {otp.map((_, index) => {
                  return (
                    <div key={index}>
                      <input
                        ref={index === activeIndex ? inputRef : null}
                        inputMode="numeric"
                        type="text"
                        className={`input input-bordered w-[47px] h-[67px] text-[20px] font-bold text-center ${error ? "input-error" : "input-ghost"}`}
                        onChange={handleOnChange}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        value={otp[index]}
                      />
                    </div>
                  );
                })}
              </div>
              <>
                {showError && (
                  <div className="flex flex-col justify-center items-center pt-3 text-center">
                    <label className="text-[#D92D20] font-normal text-sm">
                      {error}
                    </label>
                  </div>
                )}
              </>
              <div className="flex flex-col justify-center items-center pt-6 text-center">
                <label className="font-semibold text-sm">
                  Didn't receive code?
                </label>
                <>
                  {seconds === 0 ? (
                    <a
                      href="#"
                      className="text-[#FE9693] font-bold text-sm"
                      onClick={resendOTP}
                    >
                      Resend Code
                    </a>
                  ) : (
                    <label className="text-[#FE9693] font-bold text-sm">
                      Resend Code in {minutes < 10 ? `0${minutes}` : minutes}:
                      {remainingSeconds < 10
                        ? `0${remainingSeconds}`
                        : remainingSeconds}
                    </label>
                  )}
                </>
              </div>
            </div>
          )}
        </>
        <AppButton
          type="button"
          variant="primary"
          onClick={verify}
          disabled={!nextButtonStatus}
        >
          Next
        </AppButton>
      </div>
    </>
  );
};

export default Verification;
