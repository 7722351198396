import React from "react";

type ResultsIconOutlineProps = {
    isActive?: boolean
}

const ResultsIconOutline: React.FC<ResultsIconOutlineProps> = ({isActive}) => {
    return (
        <>
            {
              isActive ? <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5625 12.625C10.1828 12.625 9.875 12.9328 9.875 13.3125C9.875 13.6922 10.1828 14 10.5625 14H17.4375C17.8172 14 18.125 13.6922 18.125 13.3125C18.125 12.9328 17.8172 12.625 17.4375 12.625H10.5625Z" fill="white"/>
              <path d="M9.875 16.0625C9.875 15.6828 10.1828 15.375 10.5625 15.375H17.4375C17.8172 15.375 18.125 15.6828 18.125 16.0625C18.125 16.4422 17.8172 16.75 17.4375 16.75H10.5625C10.1828 16.75 9.875 16.4422 9.875 16.0625Z" fill="white"/>
              <path d="M9.875 18.8125C9.875 18.4328 10.1828 18.125 10.5625 18.125H13.3125C13.6922 18.125 14 18.4328 14 18.8125C14 19.1922 13.6922 19.5 13.3125 19.5H10.5625C10.1828 19.5 9.875 19.1922 9.875 18.8125Z" fill="white"/>
              <path d="M16.0625 3H8.5C6.98122 3 5.75 4.23122 5.75 5.75V22.25C5.75 23.7688 6.98122 25 8.5 25H19.5C21.0188 25 22.25 23.7688 22.25 22.25V9.1875L16.0625 3ZM16.0625 4.375V7.125C16.0625 8.26409 16.9859 9.1875 18.125 9.1875H20.875V22.25C20.875 23.0094 20.2594 23.625 19.5 23.625H8.5C7.74061 23.625 7.125 23.0094 7.125 22.25V5.75C7.125 4.99061 7.74061 4.375 8.5 4.375H16.0625Z" fill="white"/>
              </svg>
              : 
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.7777 3H8.5C6.98122 3 5.75 4.23122 5.75 5.75V22.25C5.75 23.7688 6.98122 25 8.5 25H19.5C21.0188 25 22.25 23.7688 22.25 22.25V9.47227C22.25 9.1076 22.1051 8.75786 21.8473 8.5L16.75 3.40273C16.4921 3.14487 16.1424 3 15.7777 3ZM16.0625 7.8125V5.0625L20.1875 9.1875H17.4375C16.6781 9.1875 16.0625 8.57189 16.0625 7.8125ZM9.1875 15.375C8.8078 15.375 8.5 15.0672 8.5 14.6875C8.5 14.3078 8.8078 14 9.1875 14H18.8125C19.1922 14 19.5 14.3078 19.5 14.6875C19.5 15.0672 19.1922 15.375 18.8125 15.375H9.1875ZM8.5 17.4375C8.5 17.0578 8.8078 16.75 9.1875 16.75H18.8125C19.1922 16.75 19.5 17.0578 19.5 17.4375C19.5 17.8172 19.1922 18.125 18.8125 18.125H9.1875C8.8078 18.125 8.5 17.8172 8.5 17.4375ZM9.1875 20.875C8.8078 20.875 8.5 20.5672 8.5 20.1875C8.5 19.8078 8.8078 19.5 9.1875 19.5H14.6875C15.0672 19.5 15.375 19.8078 15.375 20.1875C15.375 20.5672 15.0672 20.875 14.6875 20.875H9.1875Z" fill="white"/>
              </svg>              
            }
        </>
    )
}

ResultsIconOutline.defaultProps = {
    isActive: true
}

export default ResultsIconOutline;