import React from "react";
import { ReactNode } from "react";

type ScreenHeaderProps = {
    custom?: string;
    children: ReactNode;
    withBackButton?: boolean;
    type: 'primary' | 'secondary';
}

const headerPadding = (type: string): string => {
    return type === "primary" ? 'p-4' : 'py-3 px-4';
}

const bgColor = (type:string): string => {
    return type === "primary" ? 'bg-[#293056]' : 'bg-[##FFF]'
}

const ScreenHeader: React.FC<ScreenHeaderProps> = ({ custom, children, type }) => {
    return (
        <div className={`
          ${custom} 
          ${bgColor(type)}
          ${headerPadding(type)}
        `}>
            { children }
        </div>
    )
}

ScreenHeader.defaultProps = {
    withBackButton: false
}
export default ScreenHeader;