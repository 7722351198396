import React from "react";

type DividerBagdeProps = {
    variant: "NEW" | "PREVIOUS"
}

const DividerBagde: React.FC<DividerBagdeProps> = ({variant}) => {
    return (
        <div className="flex items-center">
            <span className="flex-1 border border-solid border-[#D2D6E4]"></span>
            <span className={`inline-block ${variant === "NEW" ? 'bg-[#9984D4]' : 'bg-[#2F3082]' } rounded-full px-6 py-1 text-[10px] font-bold text-white mx-2`}>
                { variant }
            </span>
            <span className="flex-1 border border-solid border-[#D2D6E4]"></span>
        </div>
    )
}

export default DividerBagde;