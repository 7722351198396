import React, { useState } from "react";
// import { Document, Page } from "react-pdf";

const ChatFileView: React.FC = () => {
  //   const [numPages, setNumPages] = useState<number>();
  //   const [pageNumber, setPageNumber] = useState<number>(1);

  //   const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
  //     setNumPages(numPages);
  //   };

  //   const renderPages = () => {
  //     const pages = [];
  //     for (let i = 1; i <= numPages; i++) {
  //       pages.push(
  //         <Page
  //          key={i}
  //          pageNumber={i}
  //          scale={1}
  //          renderAnnotationLayer={false}
  //         renderTextLayer={false} width={375}
  //         className="!bg-black"
  //         />
  //       );
  //     }
  //     return pages;
  //   };
  //     const renderPages = () => {
  //     const pages = [];
  //     for (let i = 1; i <= numPages; i++) {
  //       pages.push(
  //         <Page
  //          key={i}
  //          pageNumber={i}
  //          scale={1}
  //          renderAnnotationLayer={false}
  //         renderTextLayer={false} width={375}
  //         className="!bg-black"
  //         />
  //       );
  //     }
  //     return pages;
  //   };

  //   const goToPrevPage = () => setPageNumber((prevPageNumber) => prevPageNumber - 1);
  //   const goToNextPage = () => setPageNumber((prevPageNumber) => prevPageNumber + 1);

  //   const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
  //     setNumPages(numPages);
  //   }

  return (
    <div>
      {/* <Document file="/docs/livina_brochure.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        { renderPages()}
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  );
};

export default ChatFileView;
